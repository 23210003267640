import { motion } from "framer-motion";
import styled from "@xstyled/styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useMemo } from "react";

import { GetTheApp } from "./GetTheApp";

import { Heading, Text, Spacing, Button } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { NAV_HEIGHT } from "@otta/search/globalConstants";
import { Heart } from "@otta/search/pages/Jobs/icons/Heart";
import { Pencil } from "@otta/search/pages/Jobs/icons/Pencil";
import { useQuery } from "@otta/search/apollo";
import { UserRegionDocument } from "@otta/search/schema";
import { SUPPORTED_REGIONS } from "@otta/search/constants/supportedRegions";

const VERTICAL_END_STATE = -30;

const Background = styled(motion.div)<{ showControl?: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  justify-content: center;
  background: ${({ showControl }) =>
    showControl ? palette.brand.yellow : palette.brand.white};
`;

const Content = styled(motion.div)`
  padding-top: 3xl;
`;

const LoadingBar = styled(motion.div)`
  height: ${pxToRem(8)};
  width: 100%;
  background: ${palette.brand.white};
  border-radius: ${pxToRem(12)};
`;
const ProgressIndicator = styled(motion.div)`
  height: ${pxToRem(8)};
  width: 0;
  border-radius: ${pxToRem(12)};
  background: ${palette.extended.green.shade400};
`;

const TextWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: ${pxToRem(350)};
`;

const CTAWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const Badge = styled.div<{ isSave?: boolean }>`
  display: flex;
  padding: sm lg;
  align-items: center;
  border-radius: ${pxToRem(100)};
  border: ${({ isSave }) => (isSave ? `${pxToRem(1)} solid black` : "none")};
  background-color: ${({ isSave }) =>
    isSave ? palette.brand.yellow : palette.brand.white};
  margin-right: md;
`;

const GreyBadge = styled.div`
  display: flex;
  padding: sm lg;
  border-radius: ${pxToRem(100)};
  border: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  background-color: ${palette.grayscale.shade50};
  margin-right: md;
  width: ${pxToRem(142)};
  justify-content: center;
`;

const IconWrapper = styled.div<{ isSave?: boolean }>`
  display: flex;
  align-items: center;
  margin-right: sm;
  svg {
    width: ${({ isSave }) => (isSave ? modularScale(1.5) : modularScale(2))};
    height: ${({ isSave }) => (isSave ? modularScale(1.5) : modularScale(2))};
  }
`;
const ButtonWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const ButtonsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export function OnboardingQuizFinishScreen(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: regionData } = useQuery(UserRegionDocument);

  const isSupportedRegion = useMemo(() => {
    if (regionData?.currentUser?.region) {
      return SUPPORTED_REGIONS.includes(regionData?.currentUser?.region);
    }
    return false;
  }, [regionData?.currentUser?.region]);

  const showControl = !isSupportedRegion;

  const handleClick = () => {
    navigate(location.pathname, {
      state: { onboard: false },
      replace: true,
    });
  };

  const content = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: showControl ? 2 : 1.5,
        staggerChildren: showControl ? 1.27 : 0.75,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: showControl ? 1.5 : 0.75,
      },
    },
  };

  const subText = showControl ? (
    <>
      <Text size={1}>
        We&apos;re finding the <strong>ten best</strong>
      </Text>
      <Text size={1}>matches for your preferences...</Text>
    </>
  ) : (
    <>
      <Text bold size={1}>
        We&apos;re finding the best matches
      </Text>
      <Text bold size={1}>
        for your search preferences.
      </Text>
    </>
  );

  return (
    <Background
      showControl={showControl}
      initial={{ height: "2vh" }}
      animate={{ height: `calc(99.9vh - ${NAV_HEIGHT}px)` }}
      transition={{ duration: 0.5 }}
      exit={{ height: "0vh" }}
    >
      <Content
        variants={content}
        initial="hidden"
        animate="show"
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Spacing size={1}>
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: VERTICAL_END_STATE, opacity: 1 }}
            transition={{ duration: showControl ? 1 : 0.75, delay: 1 }}
          >
            <Heading size={4} bold align="center">
              You&apos;re all set!
            </Heading>
          </motion.div>
          <TextWrapper
            variants={item}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: VERTICAL_END_STATE, opacity: 1 }}
            transition={{ duration: showControl ? 1.5 : 0.75 }}
          >
            {subText}
          </TextWrapper>
          <ButtonsSection>
            <Spacing size={1}>
              <ButtonWrapper
                variants={item}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: VERTICAL_END_STATE, opacity: 1 }}
                transition={{ duration: showControl ? 1.5 : 0.75 }}
              >
                {showControl ? (
                  <Badge isSave>
                    <IconWrapper isSave>
                      <Heart />
                    </IconWrapper>
                    <Text size={1}>Save</Text>
                  </Badge>
                ) : (
                  <GreyBadge>
                    <IconWrapper>
                      <Heart />
                    </IconWrapper>
                    <Text bold size={1}>
                      Save
                    </Text>
                  </GreyBadge>
                )}

                <Text size={1}>jobs you like</Text>
              </ButtonWrapper>

              <ButtonWrapper
                variants={item}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: VERTICAL_END_STATE, opacity: 1 }}
                transition={{ duration: showControl ? 1.5 : 0.75 }}
              >
                {showControl ? (
                  <Badge>
                    <IconWrapper>
                      <Pencil />
                    </IconWrapper>
                    <Text size={1}>Apply</Text>
                  </Badge>
                ) : (
                  <GreyBadge>
                    <IconWrapper>
                      <Pencil />
                    </IconWrapper>
                    <Text bold size={1}>
                      Apply
                    </Text>
                  </GreyBadge>
                )}
                <Text size={1}>to jobs you love</Text>
              </ButtonWrapper>
            </Spacing>
          </ButtonsSection>
          {showControl && (
            <>
              <TextWrapper
                variants={item}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: VERTICAL_END_STATE, opacity: 1 }}
                transition={{ duration: 1.5 }}
              >
                <Text size={1}>
                  Applying to jobs helps us improve your recommendations.
                </Text>
              </TextWrapper>
              <LoadingBar
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{
                  delay: 1,
                  duration: 1,
                  type: "spring",
                  bounce: 0.15,
                }}
              >
                <ProgressIndicator
                  initial={{ width: 0 }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 7 }}
                />
              </LoadingBar>
            </>
          )}

          <CTAWrapper
            variants={item}
            initial={{ opacity: 0, y: VERTICAL_END_STATE }}
            animate={{ opacity: 1, y: VERTICAL_END_STATE }}
            transition={{ duration: 1 }}
          >
            <Button
              level={showControl ? "secondary" : "primary"}
              onClick={() => handleClick()}
            >
              Show me my matches
            </Button>
          </CTAWrapper>
        </Spacing>
      </Content>
      {isSupportedRegion && <GetTheApp />}
    </Background>
  );
}
