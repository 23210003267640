import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Clickable, Logo, Middle, Spacing, Text } from "@otta/design";

interface ConfirmProps {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    externalId: string;
  };
  onUseOther(): void;
}

export function Confirm({
  user,
  onUseOther,
}: ConfirmProps): React.ReactElement {
  const [searchParams] = useSearchParams();

  const handleConfirm = useCallback(() => {
    const nextQueryParams = new URLSearchParams(searchParams);

    nextQueryParams.set("uid", user.externalId);

    const url = `${
      import.meta.env.VITE_API_HOST
    }/auth/oauth2/authorize?${nextQueryParams.toString()}`;

    window.location.assign(url);
  }, [searchParams, user.externalId]);

  return (
    <Middle>
      <Spacing size={5}>
        <Spacing size={-2}>
          <Logo style={{ maxWidth: 100 }} />
          <Text size={3}>Choose an account</Text>
        </Spacing>
        <Clickable
          onClick={handleConfirm}
          style={{ textAlign: "left", width: "100%" }}
        >
          <Text as="span" bold data-cs-mask>
            {user.firstName} {user.lastName}
          </Text>
          <br />
          <Text as="span" size={-1} data-cs-mask>
            {user.email}
          </Text>
        </Clickable>
        <Clickable
          onClick={onUseOther}
          style={{ textAlign: "left", width: "100%" }}
        >
          <Text as="span" bold>
            Use another account
          </Text>
        </Clickable>
      </Spacing>
    </Middle>
  );
}
