import styled from "@xstyled/styled-components";

import { palette } from "@otta/design-tokens";

const Label = styled.label`
  position: absolute;
  top: -4px;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  text-align: left;
  color: ${palette.brand.black};
`;

export const Error = styled.span`
  width: 100%;
  font-size: 14px;
  color: ${palette.brand.red};
  text-align: right;
  position: absolute;
  top: -4px;
  left: 0;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const WhiteLabel = styled(Label)`
  color: ${palette.grayscale.shade400};
`;
