import styled from "@xstyled/styled-components";

import { Section } from ".";

import { Button, Text } from "@otta/design";
import { borderRadius, palette } from "@otta/design-tokens";
import { ClipboardPlus } from "@otta/search/components/Icons/ClipboardPlus";
import { Funnel } from "@otta/search/components/Icons/Funnel";
import { Money } from "@otta/search/components/Icons/Money";
import { PencilFilled } from "@otta/search/components/Icons/PencilFilled";
import { Sash } from "@otta/search/components/Icons/Sash";
import { Link } from "@otta/search/components/Link";

const TipCardContainer = styled.div<{ hasButton: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 151px;
  border: 2px dashed ${palette.grayscale.shade400};
  padding: 14px 16px;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasButton }) =>
    hasButton ? "space-between" : "center"};
  border-radius: ${borderRadius}px;
`;

const IconWrapper = styled.div`
  flex: 0 0 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  svg {
    height: 100%;
    display: block;
    fill: ${palette.brand.black};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

interface ITipContent {
  text: string;
  icon: React.ReactNode;
  button?: {
    text: string;
    link: string;
  };
}

type TipType = "EMPTY" | "TIP" | "FILTER_NO_RESULTS";

const tipContent: Record<Section, Partial<Record<TipType, ITipContent>>> = {
  SAVED: {
    EMPTY: {
      text: "Your saved jobs will appear here so you can choose which to apply for",
      icon: <ClipboardPlus />,
    },
    TIP: undefined,
    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },
  APPLICATION_STARTED: {
    EMPTY: {
      text: "Your started applications will appear here to let you know what's still pending",
      icon: <PencilFilled />,
    },
    TIP: undefined,
    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },
  APPLIED: {
    EMPTY: {
      text: "Your submitted applications will appear here so you can track your progress",
      icon: <PencilFilled />,
    },
    TIP: undefined,
    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },
  INTERVIEWING: {
    EMPTY: {
      text: "Track jobs you're interviewing for here",
      icon: <PencilFilled />,
    },
    TIP: {
      text: "We've written a guide on standing out at interviews",
      icon: <Sash />,
      button: {
        text: "Read guide",
        link: "https://blog.otta.com/6-areas-to-research-before-interviewing-at-a-startup?utm_source=shortlist_tip",
      },
    },
    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },
  OFFER_RECEIVED: {
    EMPTY: {
      text: "Track jobs you've received an offer here",
      icon: <PencilFilled />,
    },
    TIP: {
      text: "We've written a guide on how to think about your salary offer",
      icon: <Money />,
      button: {
        text: "Read guide",
        link: "https://blog.otta.com/evaluating-a-salary-offer?utm_source=shortlist_tip",
      },
    },
    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },
  OFFER_ACCEPTED: {
    EMPTY: {
      text: "Track jobs you've accepted an offer here",
      icon: <PencilFilled />,
    },

    FILTER_NO_RESULTS: {
      text: "No jobs match the filters you selected",
      icon: <Funnel />,
    },
  },

  DISCARDED: {
    EMPTY: {
      text: "View jobs you've previously seen here",
      icon: <Funnel />,
    },
  },
};

interface ITipCardProps {
  sectionType: Section;
  tipType: TipType;
}

export function TipCard({
  sectionType,
  tipType,
}: ITipCardProps): React.ReactElement | null {
  const content = tipContent[sectionType][tipType];

  if (!content) {
    return null;
  }
  const { text, icon, button } = content;

  return (
    <TipCardContainer data-testid="tip-card" hasButton={!!button}>
      <ContentContainer>
        <IconWrapper>{icon}</IconWrapper>
        <Text>{text}</Text>
      </ContentContainer>
      {button && (
        <Link
          to={button.link}
          underline={false}
          data-testid="tipcard-button-link"
          target="_blank"
        >
          <Button type="button" level="primary" data-testid="tipcard-button">
            {button.text}
          </Button>
        </Link>
      )}
    </TipCardContainer>
  );
}
