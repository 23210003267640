import { useOutletContext } from "react-router-dom";
import { useUp } from "@xstyled/styled-components";
import { useEffect } from "react";

import { DesktopContent, DesktopContentProps } from "./DesktopContent";
import { MobileContent } from "./MobileContent";

import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  BrandAssetType,
  ProfileType,
  TeamMemberRole,
} from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";
import { peopleBreakdownTracking } from "@otta/search/contexts/EBTracking";

export function JobContent(): React.ReactElement | null {
  const data = useOutletContext<DesktopContentProps | undefined>();
  const isTablet = useUp("tablet");

  const job = data?.job;
  const user = data?.user;

  useEffect(() => {
    if (job && job.company.profileTypeName === ProfileType.Enhanced) {
      const teamManager = job.teamMembers?.find(
        teamMember => teamMember.role === TeamMemberRole.Manager
      );

      const videos = job.team?.brandAssetLinks
        .flatMap(link => link.companyBrandAsset)
        .filter(asset => asset.type === BrandAssetType.Vimeo)
        .map(asset => asset.category);

      pushAnalyticsEvent({
        eventName: "Candidate Viewed Job With EB Content",
        companyId: job.company.id,
        jobId: job.id,
        jobFunction: job.function,
        jobSubFunction: job.subFunction,
        jobValueClassification: jobValueClassification(job.function?.id),
        minYearsExperienceRequired: job.minYearsExperienceRequired,
        maxYearsExperienceRequired: job.maxYearsExperienceRequired,
        applyViaOtta: !!job.acceptsInternalApplications,
        loginStatus: user ? "logged-in" : "logged-out",
        videos,
        teamMission: !!job.team?.mission,
        teamSize: !!job.team?.size,
        yourTeamManager: !!teamManager,
        yourTeamReports: job.teamMembers?.some(
          teamMember => teamMember.role === TeamMemberRole.Report
        ),
        managerPrompts: !!teamManager?.jobCompanyTeamMemberPrompts?.length,
        peopleBreakdown: peopleBreakdownTracking(job.company?.ebStatistics),
      });
    }
  }, [job, user]);

  if (!data || !job) {
    return <Loading />;
  }

  const { expandOtherJobs, otherJobsExpanded, toggleKeyboardShortcuts } = data;

  if (isTablet) {
    return (
      <DesktopContent
        job={job}
        user={user}
        expandOtherJobs={expandOtherJobs}
        otherJobsExpanded={otherJobsExpanded}
        toggleKeyboardShortcuts={toggleKeyboardShortcuts}
      />
    );
  }
  return (
    <MobileContent
      job={job}
      user={user}
      expandOtherJobs={expandOtherJobs}
      otherJobsExpanded={otherJobsExpanded}
    />
  );
}
