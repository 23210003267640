import { NumericFormat } from "react-number-format";
import styled, { css } from "@xstyled/styled-components";

import { Salary } from "../../icons/Salary";

import { Text, Tooltip } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Company, Job } from "@otta/search/schema";
import { currencyPrefix } from "@otta/search/utils/currencies";
import { OttaCertified as OttaCertifiedIcon } from "@otta/search/components/Icons/OttaCertified";
import { Icon } from "@otta/icons";
import { Tag } from "@otta/search/components/Tag";

const Wrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;

  padding: 5 sm;
  width: fit-content;

  background-color: ${({ color }) => (color ? color : "none")};
  border-radius: ${pxToRem(8)};
`;

const ExplainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: sm 0 0 xxl;
`;

const TextLeftMargin = styled(Text)`
  margin-left: sm;
`;

const SalaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SalaryWrapper = styled.div<{
  showDivider?: boolean;
}>`
  display: flex;
  gap: lg;
  justify-content: space-between;
  align-items: center;

  ${({ showDivider }) =>
    showDivider &&
    css`
      border-bottom: 1px solid ${palette.grayscale.shade400};
      padding-bottom: sm;
      margin-bottom: sm;
    `}
`;

const SectionWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: sm;

  > svg {
    min-height: 24px;
    min-width: 24px;
  }
`;

const SalaryTag = styled.div<{ invert?: boolean }>`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: ${pxToRem(8)};
  background: ${({ invert }) =>
    invert ? palette.brand.white : palette.extended.green.shade600};
  color: ${({ invert }) =>
    invert ? palette.extended.green.shade600 : palette.brand.white};
  padding: xxs xs;
`;

const StyledTag = styled(Tag)`
  border-radius: ${pxToRem(8)};
  margin: 0 !important;
  padding: xs sm;
`;

function SalaryData({
  amount,
  currencyPrefix,
  suffix,
}: {
  amount: number;
  suffix?: string;
  currencyPrefix?: string;
}): React.ReactElement {
  return (
    <NumericFormat
      value={amount}
      prefix={currencyPrefix}
      suffix={suffix}
      allowNegative={false}
      displayType="text"
    />
  );
}

const roundSalary = (salaryAmount: string) => {
  return parseFloat((Number(salaryAmount) / 1000).toFixed(1));
};

const getSalary = ({ min, max }: { min: string; max: string }) => {
  if (!min || !max) {
    return { minAmount: null, maxAmount: null };
  }

  const minAmount = roundSalary(min);
  const maxAmount = roundSalary(max);
  return { minAmount: minAmount, maxAmount: maxAmount };
};

function SalarySectionComponent({
  job: { salaryRange, oteSalaryRange, meetsMinSalaryPreference, hideSalary },
  company: { ottaCertified },
}: SalarySectionProps): React.ReactElement | null {
  if (hideSalary && meetsMinSalaryPreference) {
    return (
      <SectionWrapper>
        <Icon icon="salary" size={2} />
        <Wrapper color={palette.brand.green}>
          <Icon icon="circle-up" size={1} />
          <TextLeftMargin bold>Salary above your minimum</TextLeftMargin>
        </Wrapper>
      </SectionWrapper>
    );
  }

  if (!salaryRange && !oteSalaryRange && ottaCertified) {
    return (
      <Tooltip
        placement="bottom"
        content="This company will lose Otta Certification if we don't receive a salary within 7 days"
      >
        <Wrapper color={palette.extended.orange.shade100}>
          <OttaCertifiedIcon width={16} height={16} />
          <TextLeftMargin bold>Awaiting salary</TextLeftMargin>
        </Wrapper>
      </Tooltip>
    );
  }

  const baseSalary = getSalary({
    min: salaryRange?.minAmount ?? "",
    max: salaryRange?.maxAmount ?? "",
  });
  const oteSalary = getSalary({
    min: oteSalaryRange?.minAmount ?? "",
    max: oteSalaryRange?.maxAmount ?? "",
  });

  const { minAmount: minBaseAmount, maxAmount: maxBaseAmount } = baseSalary;
  const { minAmount: minOTEAmount, maxAmount: maxOTEAmount } = oteSalary;
  const currency = salaryRange?.currency ?? oteSalaryRange?.currency;
  const excludeBaseSalary = !minBaseAmount || !maxBaseAmount;
  const excludeOTESalary = !minOTEAmount || !maxOTEAmount;

  if (!salaryRange && !oteSalaryRange) {
    return (
      <SectionWrapper>
        <Salary />
        <StyledTag
          data-testid="salary-not-provided-tag"
          disabled
          bold
          size={0.2}
          backgroundColor={palette.extended.red.shade200}
        >
          Salary not provided
        </StyledTag>
      </SectionWrapper>
    );
  }

  if (
    (excludeBaseSalary && excludeOTESalary) ||
    (minBaseAmount && maxBaseAmount && minBaseAmount > maxBaseAmount) ||
    (minOTEAmount && maxOTEAmount && minOTEAmount > maxOTEAmount) ||
    !currency
  ) {
    return null;
  }

  return (
    <SectionWrapper>
      <Salary />
      <Wrapper color={palette.brand.green}>
        <SalaryContainer>
          {!excludeOTESalary && (
            <SalaryWrapper showDivider={!excludeBaseSalary}>
              <Text bold>
                {minOTEAmount === maxOTEAmount ? (
                  <SalaryData
                    amount={minOTEAmount}
                    currencyPrefix={currencyPrefix(currency)}
                    suffix="k"
                  />
                ) : (
                  <>
                    <SalaryData
                      amount={minOTEAmount}
                      currencyPrefix={currencyPrefix(currency)}
                    />
                    -
                    <SalaryData amount={maxOTEAmount} suffix="k" />
                  </>
                )}
              </Text>
              <SalaryTag data-testid="ote-salary-tag">
                <Text size={-1} bold>
                  OTE
                </Text>
              </SalaryTag>
            </SalaryWrapper>
          )}
          {!excludeBaseSalary && (
            <SalaryWrapper>
              <Text bold={excludeOTESalary}>
                {minBaseAmount === maxBaseAmount ? (
                  <SalaryData
                    amount={minBaseAmount}
                    currencyPrefix={currencyPrefix(currency)}
                    suffix="k"
                  />
                ) : (
                  <>
                    <SalaryData
                      amount={minBaseAmount}
                      currencyPrefix={currencyPrefix(currency)}
                    />
                    -
                    <SalaryData amount={maxBaseAmount} suffix="k" />
                  </>
                )}
              </Text>
              {minOTEAmount && (
                <SalaryTag invert data-testid="base-salary-tag">
                  <Text size={-1} bold>
                    BASE
                  </Text>
                </SalaryTag>
              )}
            </SalaryWrapper>
          )}
        </SalaryContainer>
      </Wrapper>
    </SectionWrapper>
  );
}

interface SalarySectionProps {
  job: Pick<
    Job.Fragment,
    | "meetsMinSalaryPreference"
    | "salaryRange"
    | "oteSalaryRange"
    | "otherCompensationInformation"
    | "hideSalary"
  >;
  company: Pick<Company.Fragment, "ottaCertified">;
}

export function SalarySection(props: SalarySectionProps): React.ReactElement {
  return (
    <div data-testid="salary-section">
      <SalarySectionComponent {...props} />
      {props.job.otherCompensationInformation && (
        <ExplainerWrapper>
          <Text align="left" color={palette.grayscale.shade600} size={0}>
            {props.job.otherCompensationInformation}
          </Text>
        </ExplainerWrapper>
      )}
    </div>
  );
}
