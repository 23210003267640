import { motion, useScroll } from "framer-motion";
import styled, { css, up } from "@xstyled/styled-components";
import { useState, useEffect } from "react";

import { pxToRem, palette } from "@otta/design-tokens";
import { ThemeId } from "@otta/search/schema";
import { ThemeIcon } from "@otta/search/components/ThemeIcon";
import { ThemeDescription } from "@otta/search/components/ThemeDescription";

interface ThemeBatchHeadingProps {
  themeId: ThemeId;
  themeName: string;
  version2?: boolean;
}

const ThemeBatchWrapper = styled(motion.div)<{
  version2?: boolean;
  scrolled: boolean;
}>(
  ({ version2, scrolled }) => css`
    display: flex;
    flex-direction: ${scrolled ? "row" : "column"};
    align-items: ${scrolled ? "auto" : "center"};
    justify-content: center;

    ${version2
      ? css`
          ${up(
            "tablet",
            css`
              background: ${palette.brand.yellow};
            `
          )}}
          padding: lg 0;
          margin: 0;
        `
      : css`
          margin-bottom: lg;
        `}
  `
);

const ThemeIconContainer = styled(motion.div)<{ scrolled?: boolean }>(
  ({ scrolled }) => css`
    i {
      font-size: ${scrolled ? "19px" : "25px"};
    }

    margin-right: ${scrolled ? "5px" : "0px"};
  `
);

const ThemeTitleH1 = styled(motion.h1)<{
  scrolled?: boolean;
}>(
  ({ scrolled }) => css`
    line-height: 1;
    font-family: "Fann Grotesque";
    font-weight: 700;
    white-space: nowrap;
    font-size: ${pxToRem(19)};

    ${up(
      "tablet",
      css`
        ${!scrolled &&
        css`
          font-size: ${pxToRem(28)};
        `}
      `
    )}
  `
);

const DescriptionWrapper = styled.div<{ version2?: boolean }>(
  ({ version2 }) => css`
    ${version2
      ? css`
          ${up(
            "tablet",
            css`
              background: ${palette.brand.yellow};
            `
          )}}
        `
      : css`
          margin-bottom: lg;
        `}

    text-align: center;
  `
);

export function ThemeBatchHeading({
  themeId,
  themeName,
  version2 = false,
}: ThemeBatchHeadingProps): React.ReactElement {
  const { scrollY } = useScroll();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    return scrollY.onChange(y => {
      y > 20 && setScrolled(true);
    });
  }, [scrollY]);

  return (
    <motion.div layout>
      <ThemeBatchWrapper version2={version2} scrolled={scrolled} layout>
        <ThemeIconContainer layout scrolled={scrolled}>
          <ThemeIcon themeId={themeId} />
        </ThemeIconContainer>

        <ThemeTitleH1 layout scrolled={scrolled}>
          {themeName}
        </ThemeTitleH1>
      </ThemeBatchWrapper>

      {!scrolled && (
        <DescriptionWrapper version2={version2}>
          <ThemeDescription themeId={themeId} />
        </DescriptionWrapper>
      )}
    </motion.div>
  );
}
