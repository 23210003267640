import styled, { css, down } from "@xstyled/styled-components";

import { CompanyLogo } from "../components/CompanyLogo";
import { CompanyShortDescription } from "../components/CompanyShortDescription";
import { StatusTags } from "../components/StatusTags";
import { DesktopContentProps } from "../tabs/DesktopContent";
import { CompanyMission } from "../../../../components/CompanyMission";
import { FollowCompany } from "../components/FollowCompany";

import { SizeAndSector } from "./SizeAndSector";
import { SalaryTechExperienceLocation } from "./SalaryTechExperienceLocation";
import { MobileOnly } from "./JobSection";

import { VerticalSpacing } from "@otta/design";

const TopCardSectionRight = styled(VerticalSpacing)`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${down(
    "tablet",
    css`
      display: none;
    `
  )}
`;

export const CompanySection = ({
  job,
  user,
}: Pick<DesktopContentProps, "job" | "user">): React.ReactElement => {
  return (
    <>
      <TopCardSectionRight data-testid="company-section">
        <CompanyLogo company={job.company} />
        <CompanyShortDescription company={job.company} />
        <SizeAndSector company={job.company} user={user} />
        <StatusTags company={job.company} job={job} />
        <FollowCompany company={job.company} job={job} user={user} />
      </TopCardSectionRight>
      <MobileOnly>
        <SalaryTechExperienceLocation job={job} user={user} />
      </MobileOnly>
      <MobileOnly>
        <SizeAndSector company={job.company} user={user} />
      </MobileOnly>
      <MobileOnly>
        <CompanyMission company={job.company} />
      </MobileOnly>
      <MobileOnly>
        <FollowCompany company={job.company} job={job} user={user} />
      </MobileOnly>
    </>
  );
};
