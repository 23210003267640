import styled from "@xstyled/styled-components";

import { SpotlightCard } from "./components";

import { palette } from "@otta/design-tokens";
import { Heading, Text } from "@otta/design";
import { OttaIcons } from "@otta/icons";

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
`;

export function TeamMission({
  name,
  mission,
}: {
  name: string;
  mission: string;
}) {
  const spotlightTag = {
    icon: OttaIcons["HandWave"],
    text: "Meet the team",
    color: palette.brand.pink,
  };

  return (
    <SpotlightCard name="team-mission" tag={spotlightTag}>
      <Info>
        <Text bold>{name} team mission this quarter</Text>
        <Heading size={3}>{mission}</Heading>
      </Info>
    </SpotlightCard>
  );
}
