import styled from "@xstyled/styled-components";
import { useState } from "react";

import { Office } from "../../icons/Office";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  Job,
  Location,
  LocationPreference,
  UserJobPreferences,
} from "@otta/search/schema";
import {
  locationName,
  locationsToString,
  REMOTE_EU_LOCATIONS,
  REMOTE_LOCATIONS,
} from "@otta/search/utils/locations";
import { Tag, TagsContainer } from "@otta/search/components/Tag";

const OfficeLocationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: sm;

  > svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

const StyledTag = styled(Tag)`
  border-radius: ${pxToRem(8)};
  margin: 0 !important;
  padding: xs sm;
`;

const StyledTagsContainer = styled(TagsContainer)`
  gap: sm;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  gap: sm;

  > svg {
    min-height: 24px;
    min-width: 24px;
  }
`;

const ExpanderText = styled(Text)`
  text-decoration: underline;
  text-decoration-thickness: ${pxToRem(1.5)};
  cursor: pointer;
`;

const ExpanderWrapper = styled.div`
  display: inline-flex;
  gap: 9;
`;

const LocationInfoText = styled(Text)`
  font-size: ${pxToRem(13)};
`;
const RestrictionsInfoText = styled(Text)`
  font-size: ${pxToRem(13)};
`;

const RestrictionsInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5;
  margin-top: sm;
`;

const InfoWrapper = styled.div`
  margin: 0 0 0 xxl;
`;

const jobLocationTags = (
  jobLocationPreferences: LocationPreference[],
  userLocationPreferences: LocationPreference[]
): { text: string; preference: boolean }[] => {
  const jobLocations = jobLocationPreferences.map(jlp => jlp.location);
  const remoteJobLocations: Location[] = [];
  const restJobLocations: Location[] = [];

  for (const l of jobLocations) {
    if (REMOTE_LOCATIONS.some(rl => rl === l)) {
      remoteJobLocations.push(l);
    } else {
      restJobLocations.push(l);
    }
  }

  const hasMatchingRemote = remoteJobLocations.some(
    l =>
      userLocationPreferences.some(ulp => ulp.location === l) ||
      (l === Location.RemoteEurope &&
        userLocationPreferences.some(ulp =>
          REMOTE_EU_LOCATIONS.includes(ulp.location)
        ))
  );

  const matchedLocations: Location[] = [];
  const unmatchedLocations: Location[] = [];

  for (const l of restJobLocations) {
    if (userLocationPreferences.some(ulp => ulp.location === l)) {
      matchedLocations.push(l);
    } else {
      unmatchedLocations.push(l);
    }
  }

  const result: { text: string; preference: boolean }[] = [];

  const remoteLocationsString = locationsToString(remoteJobLocations);

  if (hasMatchingRemote) {
    result.push({
      text: remoteLocationsString,
      preference: true,
    });
  }

  for (const name of matchedLocations
    .map(l => locationName(l))
    .filter(l => l)
    .sort()) {
    result.push({
      text: name as string,
      preference: true,
    });
  }

  if (!hasMatchingRemote && remoteJobLocations.length > 0) {
    result.push({
      text: remoteLocationsString,
      preference: false,
    });
  }

  for (const name of unmatchedLocations
    .map(l => locationName(l))
    .filter(l => l)
    .sort()) {
    result.push({
      text: name as string,
      preference: false,
    });
  }

  return result;
};

const formatOfficeDayRequirements = ({
  minDaysInOffice,
  maxDaysInOffice,
}: {
  minDaysInOffice: number;
  maxDaysInOffice: number | null;
}) => {
  const formattedDayString = minDaysInOffice === 1 ? "day" : "days";

  if (maxDaysInOffice) {
    if (minDaysInOffice === maxDaysInOffice) {
      return `${minDaysInOffice} ${formattedDayString}`;
    }

    if (minDaysInOffice < maxDaysInOffice) {
      return `${minDaysInOffice}-${maxDaysInOffice} days`;
    }
  }
  return `${minDaysInOffice}+ ${formattedDayString}`;
};

interface JobLocationsSectionProps {
  job: Pick<
    Job.Fragment,
    | "locationPreferences"
    | "locationInformation"
    | "officeLocation"
    | "minDaysInOffice"
    | "maxDaysInOffice"
    | "locationRestrictions"
    | "remoteOnly"
  >;
  user?: Pick<UserJobPreferences.CurrentUser, "locationPreferences">;
}

export function JobLocationsSection({
  job,
  user,
}: JobLocationsSectionProps): React.ReactElement | null {
  if (job.locationPreferences.length === 0) {
    return null;
  }
  return <JobLocationsSectionComponent job={job} user={user} />;
}

function JobLocationsSectionComponent({
  job: {
    locationPreferences: jobLocationPreferences,
    locationRestrictions: jobLocationRestrictions,
    locationInformation: jobLocationInformation,
    remoteOnly,
    officeLocation,
    minDaysInOffice,
    maxDaysInOffice,
  },
  user,
}: JobLocationsSectionProps): React.ReactElement | null {
  const locationTags = jobLocationTags(
    jobLocationPreferences,
    user?.locationPreferences ?? []
  );

  const formattedOfficeDayRequirements =
    minDaysInOffice !== null
      ? formatOfficeDayRequirements({
          minDaysInOffice,
          maxDaysInOffice,
        })
      : undefined;

  const hasRemoteLocations = jobLocationPreferences.some(({ location }) =>
    REMOTE_LOCATIONS.includes(location)
  );

  return (
    <>
      <Wrapper>
        <Icon icon="location" size={2} />
        <StyledTagsContainer data-testid="job-locations">
          {locationTags.map(tag => (
            <StyledTag
              data-testid="job-location-tag"
              disabled
              key={tag.text}
              bold={tag.preference}
              size={0.2}
              backgroundColor={
                tag.preference ? palette.brand.green : palette.beige.shade200
              }
            >
              {tag.text}
            </StyledTag>
          ))}
        </StyledTagsContainer>
      </Wrapper>
      {(jobLocationInformation || jobLocationRestrictions) && (
        <MoreInfo
          locationInfo={jobLocationInformation}
          locationRestrictions={jobLocationRestrictions}
        />
      )}
      {!hasRemoteLocations &&
        minDaysInOffice !== null &&
        minDaysInOffice > 0 &&
        formattedOfficeDayRequirements &&
        !remoteOnly && (
          <OfficeLocationContainer>
            {formattedOfficeDayRequirements && (
              <>
                <Icon icon={"company"} size={2} />
                <Text>
                  {formattedOfficeDayRequirements} a week in office{" "}
                  {officeLocation &&
                    officeLocation.length < 90 &&
                    `(${officeLocation})`}
                </Text>
              </>
            )}
          </OfficeLocationContainer>
        )}
      {!hasRemoteLocations &&
        !minDaysInOffice &&
        !formattedOfficeDayRequirements &&
        officeLocation &&
        officeLocation.length < 90 &&
        !remoteOnly && (
          <OfficeLocationContainer>
            <Office width={24} height={24} />
            <Text>Office located in {officeLocation}</Text>
          </OfficeLocationContainer>
        )}
    </>
  );

  function MoreInfo({
    locationInfo,
    locationRestrictions,
  }: {
    locationInfo: string | null;
    locationRestrictions: string | null;
  }): React.ReactElement {
    const [expand, setExpand] = useState(false);
    if (
      typeof locationInfo === "string" &&
      locationInfo.length <= 30 &&
      !locationRestrictions
    ) {
      return (
        <InfoWrapper>
          <>
            <LocationInfoText>{locationInfo}</LocationInfoText>
          </>
        </InfoWrapper>
      );
    }

    const handleExpand = () => {
      setExpand(!expand);
      pushAnalyticsEvent({
        eventName: "Candidate Clicked",
        name: "job-card-more-information-about-location",
        pathname: location.pathname,
      });
    };
    return (
      <InfoWrapper>
        {!expand && (
          <ExpanderWrapper onClick={handleExpand}>
            <ExpanderText data-testid="other-location-info">
              More information about location
            </ExpanderText>
            <Icon icon="chevron-down" />
          </ExpanderWrapper>
        )}
        {expand && (
          <>
            {locationInfo && (
              <LocationInfoText>{locationInfo}</LocationInfoText>
            )}
            {locationRestrictions && (
              <RestrictionsInfoWrapper>
                <Icon icon="circle-exclamation" />
                <RestrictionsInfoText>
                  <strong>Restrictions: </strong>
                  {locationRestrictions}
                </RestrictionsInfoText>
              </RestrictionsInfoWrapper>
            )}
          </>
        )}
      </InfoWrapper>
    );
  }
}
