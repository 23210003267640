import { useApolloClient } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css, down, up } from "@xstyled/styled-components";

import { palette, pxToRem } from "@otta/design-tokens";
import {
  Button,
  Card,
  Heading,
  Spacing,
  Text,
  VerticalSpacing,
} from "@otta/design";
import { BatchCard } from "@otta/search/components/BatchCard";
import { ThemeDescription } from "@otta/search/components/ThemeDescription";
import { ThemeIcon } from "@otta/search/components/ThemeIcon";
import { toQueryKey } from "@otta/search/constants/themedBatches";
import { NAV_HEIGHT } from "@otta/search/globalConstants";
import { findData } from "@otta/search/pages/Dashboard/ThemedBatches";
import {
  BatchCardData,
  BatchCounts,
  toList,
} from "@otta/search/pages/Dashboard/ThemedBatches/BatchCards";
import {
  ThemeId,
  ThemedBatchesDocument,
  ThemedBatchesQuery,
} from "@otta/search/schema";

interface OnboardingThemedBatchProps {
  batchData: BatchCardData | "loading" | undefined;
}

const ThemeBatchTitles: Record<ThemeId | "ALL_MATCHES", string> = {
  [ThemeId.ApplyViaOtta]: "Apply with your profile",
  [ThemeId.FemaleFounders]: "Led by women",
  [ThemeId.HasSalaries]: "Jobs with salaries",
  [ThemeId.NewlyAdded]: "Jobs added this week",
  [ThemeId.PreferredSector]: "preferredSector",
  [ThemeId.RecentlyFunded]: "Recently funded",
  [ThemeId.TechForGood]: "Tech for good",
  [ThemeId.FullyRemote]: "Fully remote",
  [ThemeId.FavouriteTechnologies]: "Favourite technologies",
  [ThemeId.TakeAnotherLook]: "Take another look",
  ALL_MATCHES: "allMatches",
};

const ThemeBatchOrder: (ThemeId | "ALL_MATCHES")[] = [
  ThemeId.NewlyAdded,
  ThemeId.TechForGood,
  ThemeId.FullyRemote,
  ThemeId.FemaleFounders,
  ThemeId.HasSalaries,
  ThemeId.PreferredSector,
  ThemeId.FavouriteTechnologies,
  ThemeId.RecentlyFunded,
];

const Background = styled(motion.div)`
  display: flex;
  position: absolute;
  padding-bottom: xxl;
  width: 100%;
  z-index: 5;
  background: ${palette.brand.yellow};

  ${down(
    "desktop",
    css`
      justify-content: center;
    `
  )}

  ${up(
    "desktop",
    css`
      padding-left: calc(99.99vw / 6);
      gap: 15vw;
      display: flex;
      align-items: flex-start;
    `
  )}
`;
const Content = styled(motion.div)`
  padding-top: 3xl;
`;

const LoadingBar = styled(motion.div)`
  height: ${pxToRem(8)};
  width: 100%;
  background: ${palette.brand.white};
  border-radius: ${pxToRem(12)};
`;

const MoreDot = styled(motion.div)`
  background: ${palette.brand.white};
  border-radius: 50%;
  height: ${pxToRem(75)};
  width: ${pxToRem(75)};
  display: flex;
  align-items: center;
  position: relative;
  top: ${pxToRem(-95)};
  left: ${pxToRem(366)};
  justify-content: center;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
`;

const LogoImage = styled(motion.img)`
  height: ${pxToRem(75)};
`;

const CompanyDot = styled(motion.div)<{ top: number; left: number }>`
  background: ${palette.brand.white};
  border-radius: 50%;
  height: ${pxToRem(75)};
  width: ${pxToRem(75)};
  display: flex;
  align-items: center;
  position: relative;
  top: ${p => pxToRem(p.top)};
  left: ${p => pxToRem(p.left)};
  justify-content: center;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));
  overflow: hidden;
`;

const ProgressIndicator = styled(motion.div)`
  height: ${pxToRem(8)};
  width: 0;
  border-radius: ${pxToRem(12)};
  background: ${palette.extended.green.shade400};
`;

const TextWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: ${pxToRem(350)};
`;

const CTAWrapper = styled(motion.div)`
  padding-top: 36;
  display: flex;
  justify-content: center;
`;

const ThemeBatchInfo = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const HideWhenLoading = styled.div<{ $loading: boolean }>`
  opacity: ${p => (p.$loading ? "0" : "100")};
  transition: opacity 50ms ease-in;
`;

const ThemeCard = styled(Card)`
  min-width: ${pxToRem(496)};
  max-width: ${pxToRem(497)};
  height: ${pxToRem(272)};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MobileBatchCard = styled(BatchCard)`
  ${down(
    "tablet",
    css`
      margin-top: 20;
    `
  )}
`;

const ContentParent = styled.div`
  display: flex;
  gap: inherit;
  ${down(
    "desktop",
    css`
      align-items: flex-start;
    `
  )}
  ${up(
    "desktop",
    css`
      align-items: center;
    `
  )}
`;

const MobileContainer = styled(motion.div)`
  ${down(
    "tablet",
    css`
      max-width: ${pxToRem(350)};
    `
  )}
`;

function Cards({
  counts,
  sector,
  setTheme,
}: {
  counts: [keyof BatchCounts, number][];
  sector: BatchCardData["sector"];
  setTheme: (val: ThemeId | null) => void;
}): React.ReactElement | null {
  return (
    <MobileContainer>
      {counts.map(([id, count], index) => {
        return (
          <MobileBatchCard
            key={index}
            index={index}
            theme={{ id, sector }}
            count={count}
            hoverTheme={setTheme}
          />
        );
      })}
    </MobileContainer>
  );
}

function ThemeBatchCards({
  data,
  setTheme,
}: {
  data: BatchCardData;
  setTheme: (val: ThemeId | null) => void;
}): React.ReactElement {
  const counts = toList(data.counts)
    .filter(
      ([theme, count]) =>
        count > 1 &&
        theme !== "ALL_MATCHES" &&
        theme !== ThemeId.TakeAnotherLook
    )
    .sort(
      ([themeA], [themeB]) =>
        ThemeBatchOrder.indexOf(themeA) - ThemeBatchOrder.indexOf(themeB)
    );

  return (
    <Cards
      setTheme={setTheme}
      counts={counts.slice(0, 4)}
      sector={data.sector}
    />
  );
}

export function OnboardingQuizThemedBatchScreen({
  batchData,
}: OnboardingThemedBatchProps): React.ReactElement {
  const [theme, setTheme] = useState<ThemeId | null>();
  const [cache, setCache] = useState<ThemedBatchesQuery | null>();
  const [logoBubbles, setLogos] = useState<string[]>([]);

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/jobs/");
  };

  const client = useApolloClient();

  useEffect(() => {
    if (batchData != "loading" && batchData) {
      setCache(
        client.readQuery({
          query: ThemedBatchesDocument,
          variables: { preferredSectorId: batchData?.sector?.id },
        })
      );
    }
  }, [batchData, client]);

  useEffect(() => {
    if (theme != null && cache?.currentUser) {
      setLogos([
        cache?.currentUser[`${toQueryKey(theme)}`][0].job.company.faviconPath ??
          "",
        cache?.currentUser[`${toQueryKey(theme)}`][1].job.company.faviconPath ??
          "",
        cache?.currentUser[`${toQueryKey(theme)}`][2].job.company.faviconPath ??
          "",
      ]);
    }
  }, [theme]);

  const preferredSectorTitle = (theme: BatchCardData | "loading"): string => {
    if (theme != "loading") {
      const words = theme.sector.value.split(" ");
      return theme.sector.value.length >= 20 && words.length > 1
        ? `${words[0]} ${[...words].slice(1).join(" ")}`
        : `${theme.sector.value} companies`;
    }
    return "";
  };

  const content = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delayChildren: 2,
        staggerChildren: 1.27,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1.5,
      },
    },
  };

  return (
    <Background
      initial={{ minHeight: "2vh" }}
      animate={{ minHeight: `calc(99.9vh - ${NAV_HEIGHT}px)` }}
      transition={{ duration: 0.5 }}
      exit={{ height: "0vh" }}
    >
      <ContentParent>
        <Content
          variants={content}
          initial="hidden"
          animate="show"
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Spacing size={1}>
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
            >
              <Heading size={4} bold align="center">
                You&apos;re all set!
              </Heading>
            </motion.div>
            <AnimatePresence>
              <TextWrapper
                variants={item}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1.5 }}
              >
                <Text size={0}>
                  We&apos;re finding the best matches for your preferences...
                </Text>
              </TextWrapper>
            </AnimatePresence>
            <AnimatePresence>
              <LoadingBar
                initial={{ x: "-100vw" }}
                animate={{ x: 0 }}
                transition={{
                  delay: 1,
                  duration: 1,
                  type: "spring",
                  bounce: 0.15,
                }}
              >
                <ProgressIndicator
                  initial={{ width: 0 }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 7 }}
                />
              </LoadingBar>
            </AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 200 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 7, type: "spring", stiffness: 50 }}
            >
              <HideWhenLoading $loading={batchData === "loading"}>
                <ThemeBatchCards
                  data={findData(batchData)}
                  setTheme={setTheme}
                />
              </HideWhenLoading>
              <CTAWrapper>
                <Button level="secondary" onClick={() => handleClick()}>
                  View all your matches
                </Button>
              </CTAWrapper>
            </motion.div>
          </Spacing>
        </Content>
        <ThemeBatchInfo>
          <AnimatePresence>
            {theme && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
              >
                <CompanyDot top={165} left={450}>
                  <LogoImage src={logoBubbles[0]} />
                </CompanyDot>
                <CompanyDot top={36} left={50}>
                  <LogoImage src={logoBubbles[1]} />
                </CompanyDot>
                <HideWhenLoading $loading={batchData === "loading"}>
                  <ThemeCard>
                    <VerticalSpacing>
                      <ThemeIcon height={50} width={50} themeId={theme} />
                      <Heading size={2} bold>
                        {theme &&
                          (theme == ThemeId.PreferredSector && batchData
                            ? preferredSectorTitle(batchData)
                            : ThemeBatchTitles[theme])}
                      </Heading>
                      {theme && <ThemeDescription themeId={theme} />}
                    </VerticalSpacing>
                  </ThemeCard>
                </HideWhenLoading>
                <CompanyDot top={-92} left={-46}>
                  <LogoImage src={logoBubbles[2]} />
                </CompanyDot>

                <MoreDot>
                  <Text>
                    <strong>+more</strong>
                  </Text>
                </MoreDot>
              </motion.div>
            )}
          </AnimatePresence>
        </ThemeBatchInfo>
      </ContentParent>
    </Background>
  );
}
