import { useState } from "react";
import styled, { css } from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import {
  Button as DefaultButton,
  Card,
  Heading,
  Middle,
  Text,
  Toggle,
  VerticalSpacing,
} from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Icon } from "@otta/icons";
import { Link } from "@otta/search/components/Link";
import {
  UpdateCurrentCandidateDocument,
  InboxDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: lg;
`;

const MessageTypeCard = styled(Card)<{ highlighted?: boolean }>`
  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${palette.brand.yellow};
    `}

  > i {
    font-size: ${pxToRem(40)};
  }

  svg {
    min-height: ${pxToRem(40)};
    min-width: ${pxToRem(40)};
  }

  align-items: center;
  border-radius: ${pxToRem(8)};
  display: flex;
  flex: 1 1 ${pxToRem(250)};
  gap: lg;
  padding: lg;
`;

const Container = styled(Middle)`
  margin-top: xl;
  max-width: ${pxToRem(628)};
  padding: 0 lg lg;
`;

const Title = styled(Heading)`
  font-size: ${pxToRem(23)};
  line-height: ${pxToRem(30)};
`;

const LargeIcon = styled(Icon)`
  font-size: ${pxToRem(40)};
  color: ${palette.brand.black};
`;

const Button = styled(DefaultButton)`
  margin-top: xl;
`;

const ToggleContainer = styled.div`
  align-items: center;
  display: flex;
  gap: lg;
`;

interface INoMessagesProps {
  profileVisible: boolean;
}

export function NoMessages({
  profileVisible,
}: INoMessagesProps): React.ReactElement {
  const [initialVisibility] = useState(profileVisible);

  const companyName = useWelcomeToTheJungle();

  const [updateCurrentCandidateMutation] = useMutation(
    UpdateCurrentCandidateDocument,
    {
      onError: handleMutationError,
    }
  );

  const handleProfileVisbilityToggle = (showProfileToCompanies: boolean) => {
    updateCurrentCandidateMutation({
      variables: { input: { showProfileToCompanies } },
      refetchQueries: [{ query: InboxDocument }],
    });
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: `${showProfileToCompanies ? "ShowProfile" : "HideProfile"}`,
      page: "inbox",
    });
  };

  return (
    <Container>
      <VerticalSpacing size={2} style={{ textAlign: "left" }}>
        <Title size={2} bold>
          No messages yet
        </Title>
        <Text>Companies can message you for two reasons:</Text>

        <CardContainer>
          <MessageTypeCard highlighted>
            <LargeIcon icon="apply" />
            <p>To reply to your job application</p>
          </MessageTypeCard>
          <MessageTypeCard highlighted={profileVisible}>
            {profileVisible ? (
              <LargeIcon icon="circle-user-filled" />
            ) : (
              <LargeIcon icon="circle-user" />
            )}
            <p>When your profile matches a job they&apos;re hiring for</p>
          </MessageTypeCard>
        </CardContainer>

        <VerticalSpacing>
          {initialVisibility ? (
            <>
              <Text> Check your profile is up to date. </Text>
              <Text>
                Companies can see your profile when you apply for jobs. If they
                are looking for candidates on {companyName}, they may also find
                your profile.
              </Text>
            </>
          ) : (
            <>
              <Text>You choose if companies can see your profile.</Text>
              <Text>
                We&apos;ll only show your profile to company recruiters who are
                hiring for a job that matches your search preferences. It
                won&apos;t be shown to your current employer.
              </Text>
            </>
          )}
        </VerticalSpacing>

        {!initialVisibility && (
          <ToggleContainer data-testid="profile-visibility-toggle">
            <Text bold> Show my profile to companies </Text>
            <Toggle
              onChange={handleProfileVisbilityToggle}
              checked={profileVisible}
            />
          </ToggleContainer>
        )}

        <Link to="/profile" underline={false} data-analytics-id="ViewProfile">
          <Button level="primary" type="submit">
            View profile
          </Button>
        </Link>
      </VerticalSpacing>
    </Container>
  );
}
