import styled from "@xstyled/styled-components";

import { CardItem } from "./CardTypes";

import { Button, Card, Heading, Spacing, Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { pushAnalyticsEvent } from "@otta/analytics";

const Pill = styled.div`
  position: absolute;
  top: ${pxToRem(16)};
  right: ${pxToRem(16)};
  background-color: ${palette.brand.yellow};
  justify-content: center;
  padding: xs sm;
  border-radius: ${pxToRem(8)};
`;

const StyledBrandCard = styled(Card)<{ colour: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ colour }) => colour};
  position: relative;
  width: ${pxToRem(310)};
  height: ${pxToRem(340)};
`;

type CardProps = {
  item: CardItem;
  jobCount?: number;
};

const sendClickAnalytics = (buttonName: string) => {
  pushAnalyticsEvent({
    eventName: "Candidate Clicked",
    name: "empty-dashboard-button",
    page: "empty dashboard",
    buttonName,
  });
};

export function BrandCard({ item, jobCount }: CardProps): React.ReactElement {
  return (
    <StyledBrandCard colour={item.backgroundColor}>
      <Spacing size={2}>
        <img
          style={{
            width: 120,
            height: 120,
            pointerEvents: "none",
          }}
          src={item.image}
        />
        <Spacing>
          <Heading style={{ textAlign: "center" }} as="h3" bold size={2}>
            {item.title}
          </Heading>
          <Text style={{ textAlign: "center" }} size={0}>
            {item.subtitle}
          </Text>
        </Spacing>
        <Button
          as={Link}
          to={item.link}
          underline={false}
          style={{ alignSelf: "center" }}
          level="secondary"
          onClick={() => {
            if (item.id === "saved-jobs") {
              const element = document.getElementById("saved-section-id");
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
                element.click();
              }
            }
            sendClickAnalytics(item.buttonText);
          }}
        >
          {item.buttonText}
        </Button>
      </Spacing>
      {jobCount && <CountPill count={jobCount} />}
    </StyledBrandCard>
  );
}

function CountPill({ count }: { count: number }) {
  return (
    <Pill>
      <Text size={-1} align="center" color={palette.brand.black} bold>
        {count >= 10 ? "10+" : `${count}`} {count == 1 ? "job" : "jobs"}
      </Text>
    </Pill>
  );
}
