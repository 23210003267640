import styled from "@xstyled/styled-components";

import { CheckCircle } from "./icons/CheckCircle";
import { Circle } from "./icons/Circle";

import { modularScale, palette } from "@otta/design-tokens";

const Table = styled.table`
  color: ${palette.brand.black};
  width: 100%;
  svg {
    display: block;
    height: ${modularScale()};
    margin: auto 0;
  }

  td {
    padding: 4px 0;
    vertical-align: middle;
  }

  td:last-child {
    color: ${palette.grayscale.shade400};
    text-align: right;
  }

  td.completed {
    color: ${palette.extended.green.shade600};
  }
`;

const DetailedTable = styled(Table)`
  td {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: md;
    padding-right: xs;
  }

  td:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  td.tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    margin-left: xs;
    padding: 0;
  }

  td.completed {
    text-decoration-line: line-through;
  }

  td.all-true {
    color: ${palette.extended.green.shade800};
  }
`;

interface DetailedCompletionTableProps {
  rows: Array<{
    title: string;
    completed: boolean;
    id: string;
  }>;
  allRecommendedComplete: boolean;
}

export function DetailedCompletionTable({
  rows,
  allRecommendedComplete,
}: DetailedCompletionTableProps): React.ReactElement {
  const handleRowClick = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <DetailedTable>
      <tbody>
        {rows.map(({ title, completed, id }) => (
          <tr key={title} onClick={() => handleRowClick(id)}>
            <td
              className={
                completed
                  ? `completed all-${allRecommendedComplete}`
                  : undefined
              }
            >
              {title}
            </td>

            <td
              className={
                completed
                  ? `completed all-${allRecommendedComplete}`
                  : undefined
              }
            >
              {completed ? <CheckCircle /> : <Circle />}
            </td>
          </tr>
        ))}
      </tbody>
    </DetailedTable>
  );
}
