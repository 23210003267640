import { List, Tooltip, Text, Spacing } from "@otta/design";

export interface Bullet {
  id: string;
  text: string;
  tooltip?: {
    label: string;
    content: string;
  };
}

interface BulletPointSectionProps {
  title: string;
  bullets: Bullet[];
  subtitle?: string;
  subBullets?: Bullet[];
  bulletTestId: string;
  showVideo?: boolean;
}

export function BulletPointSection({
  title,
  bullets,
  subtitle,
  subBullets,
  bulletTestId,
  showVideo = false,
}: BulletPointSectionProps): React.ReactElement | null {
  if (bullets.length === 0) {
    return null;
  }

  return (
    <Spacing>
      <Text bold as="h3" size={1}>
        {title}
      </Text>
      {showVideo && (
        <div
          style={{
            width: "100%",
            padding: "56.25% 0 0 0",
            position: "relative",
          }}
        >
          <iframe
            src="https://player.vimeo.com/video/792191283?h=a2f3cbd39c&color=9FC873&playsinline=0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      )}
      <List type="bullet" size={0}>
        {bullets.map(({ id, text, tooltip }) => (
          <li data-testid={bulletTestId} key={id}>
            {text}
            {tooltip && (
              <Tooltip content={tooltip.content}>
                <>{" " + tooltip.label}</>
              </Tooltip>
            )}
          </li>
        ))}
      </List>
      {subtitle && (subBullets ?? []).length > 0 && (
        <Spacing>
          <Text bold>{subtitle}</Text>
          <List type="bullet" size={0}>
            {(subBullets ?? []).map(({ id, text }) => (
              <li data-testid={bulletTestId} key={id}>
                {text}
              </li>
            ))}
          </List>
        </Spacing>
      )}
    </Spacing>
  );
}
