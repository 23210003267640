import { useState } from "react";
import styled from "@xstyled/styled-components";

import { IPreferencesContentProps } from "./Preferences";

import { Text, InputField, Spacing } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  Location,
  CompanySizesDocument,
  TopInvestorsDocument,
  TopSectorTagsDocument,
} from "@otta/search/schema";
import { SmallCheckbox } from "@otta/search/components/Input/SmallCheckbox";
import { Select } from "@otta/search/components/Input/Select";
import { TagsContainer, Tag } from "@otta/search/components/Tag";
import {
  AVAILABLE_CITIES,
  REMOTE_LOCATIONS_WITH_LABELS,
} from "@otta/search/utils/locations";

const ShowMore = styled.button`
  color: ${palette.brand.black};
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  font-weight: 600;
  text-decoration: underline;
  padding: 6 0;

  &:hover {
    color: ${palette.grayscale.shade600};
  }
`;

function CompanySize({
  preferences,
  handleClick,
}: {
  preferences: string[];
  handleClick: (id: string) => React.ChangeEventHandler;
}) {
  const { data } = useQuery(CompanySizesDocument);

  const companySizes = data && data.companySizes ? data.companySizes : [];

  return (
    <Spacing size={-2}>
      {companySizes.map(({ id, value }) => {
        const preference = preferences.find(sizeId => sizeId === id);
        const checked = preference ? true : false;
        return (
          <SmallCheckbox
            key={id}
            label={`${value} employees`}
            id={id}
            onChange={handleClick(id)}
            checked={checked}
          />
        );
      })}
    </Spacing>
  );
}

function Investors({
  preferences,
  handleClick,
}: {
  preferences: Array<{
    id: string;
    preference: boolean | null;
  }>;
  handleClick: (id: string) => React.MouseEventHandler<HTMLElement>;
}) {
  const positivePreferencesSet = new Set(
    preferences.filter(pref => pref.preference).map(pref => pref.id)
  );
  const [fetchedAll, setFetchedAll] = useState(false);
  const chunkSize = 10;

  const { data, fetchMore } = useQuery(TopInvestorsDocument, {
    variables: { limit: chunkSize, offset: 0 },
  });

  const topInvestors =
    data && data.topInvestors && data.topInvestors !== null
      ? data.topInvestors
      : [];
  return (
    <>
      <TagsContainer alignLeft>
        {topInvestors.map(({ id, value }) => (
          <Tag
            key={id}
            backgroundColor={
              positivePreferencesSet.has(id)
                ? palette.brand.yellow
                : palette.brand.white
            }
            onClick={handleClick(id)}
            data-testid="investors-tag"
            small
          >
            {value}
          </Tag>
        ))}
      </TagsContainer>
      {!fetchedAll && (
        <ShowMore
          onClick={async () => {
            const result = await fetchMore({
              variables: {
                offset: topInvestors.length,
              },
            });
            if (
              result &&
              result.data &&
              result.data.topInvestors.length < chunkSize
            ) {
              setFetchedAll(true);
            }
          }}
        >
          Show more
        </ShowMore>
      )}
    </>
  );
}

function SectorTags({
  preferences,
  handleClick,
}: {
  preferences: Array<{ id: string; preference: boolean | null }>;
  handleClick: (id: string) => React.MouseEventHandler<HTMLElement>;
}) {
  const positivePreferencesSet = new Set(
    preferences.filter(pref => pref.preference).map(pref => pref.id)
  );
  const [fetchedAll, setFetchedAll] = useState(false);
  const chunkSize = 10;
  const { data, fetchMore } = useQuery(TopSectorTagsDocument, {
    variables: { limit: chunkSize, offset: 0 },
  });

  const topSectorTags = data && data.topSectorTags ? data.topSectorTags : [];
  return (
    <>
      <TagsContainer alignLeft>
        {topSectorTags.map(({ id, value }) => (
          <Tag
            key={id}
            backgroundColor={
              positivePreferencesSet.has(id)
                ? palette.brand.yellow
                : palette.brand.white
            }
            onClick={handleClick(id)}
            data-testid="sector-tag"
            small
          >
            {value}
          </Tag>
        ))}
      </TagsContainer>
      {!fetchedAll && (
        <ShowMore
          onClick={async () => {
            const result = await fetchMore({
              variables: {
                offset: topSectorTags.length,
              },
            });
            if (
              result &&
              result.data &&
              result.data.topSectorTags.length < chunkSize
            ) {
              setFetchedAll(true);
            }
          }}
        >
          Show more
        </ShowMore>
      )}
    </>
  );
}
export interface LocationOptions {
  selectedLocations: Location[];
}

function LocationSelect({
  handleSelect,
}: {
  handleSelect: (options: LocationOptions) => void;
}) {
  return (
    <Select
      isMulti
      placeholder="Select a location..."
      options={[...AVAILABLE_CITIES, ...REMOTE_LOCATIONS_WITH_LABELS]}
      onChange={options => {
        handleSelect({
          selectedLocations: options?.map(({ value }) => value) ?? [],
        });
      }}
    />
  );
}

export const PreferencesContent = (
  props: IPreferencesContentProps
): React.ReactElement => (
  <Spacing size={3}>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Search
      </Text>
      <InputField
        type="text"
        name="companyName"
        onChange={props.handleChangeSearchBar}
        placeholder="Enter company name..."
        value={props.companyName}
      />
    </Spacing>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Location
      </Text>
      <LocationSelect
        data-testid="company-location-preferences"
        handleSelect={props.handleSelectLocation}
      />
    </Spacing>
    <Spacing size={-2}>
      <Text as="h6" bold>
        Company size
      </Text>
      <CompanySize
        preferences={props.companySizes}
        handleClick={props.handleClickCompanySize}
      />
    </Spacing>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Industries
      </Text>
      <SectorTags
        preferences={props.sectorTagPreferences}
        handleClick={props.handleClickSectorTag}
      />
    </Spacing>
    <Spacing size={-5}>
      <Text as="h6" bold>
        Investors
      </Text>
      <Investors
        preferences={props.investorPreferences}
        handleClick={props.handleClickInvestor}
      />
    </Spacing>
  </Spacing>
);
