import styled, { css, down, up } from "@xstyled/styled-components";

import { SpotlightCard } from "./components";

import {
  CompanyOffice,
  SpotlightBrandAssetGroupFragment,
} from "@otta/search/schema";
import { modularScale, palette, pxToRem } from "@otta/design-tokens";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";
import { Link } from "@otta/search/components/Link";
import { OttaIcons } from "@otta/icons";
import { Text, theme } from "@otta/design";

const AssetContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: sm;

  ${up(
    "desktop",
    css`
      grid-template-columns: ${pxToRem(216)} auto;
      grid-template-rows: ${pxToRem(144)} 1fr;
      gap: lg;
    `
  )}
`;

const RoundedAsset = styled(Asset)`
  border-radius: ${pxToRem(8)};
  overflow: hidden;
`;

const Asset1 = styled(RoundedAsset)`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
`;

const Asset2 = styled(RoundedAsset)`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;

  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const Asset3 = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;

  border: 1px solid ${palette.brand.grey};
  border-radius: ${pxToRem(12)};

  height: fit-content;
  position: relative;

  ${down(
    "desktop",
    css`
      grid-row-start: 1;
    `
  )}
`;

const MapImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;

  border-radius: ${pxToRem(8)};
  overflow: hidden;
`;

const MapSource = styled.source(MapImg);

const MapHQIcon = styled.div`
  position: absolute;
  top: ${pxToRem(8)};
  right: ${pxToRem(8)};
  width: ${pxToRem(28)};
  height: ${pxToRem(25)};
  border-radius: ${pxToRem(4)};
  font-size: ${modularScale(-1)};
  font-weight: bold;
  padding: xs;
  background-color: ${palette.brand.black};
  color: ${palette.brand.white};
`;

const MapAddress = styled(Text)`
  padding: lg;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-top: 1px solid ${palette.brand.grey};
`;

const DesktopAsset = styled.div`
  display: block;
  ${down(
    "desktop",
    css`
      display: none;
    `
  )}
`;

const MobileAsset = styled.div`
  display: block;
  ${up(
    "desktop",
    css`
      display: none;
    `
  )}
`;

export function Headquarters({
  assets,
  office,
}: {
  assets: SpotlightBrandAssetGroupFragment[];
  office?: Pick<
    CompanyOffice,
    "mapImageUrls" | "address" | "parsedAddress"
  > | null;
}) {
  const spotlightTag = {
    icon: OttaIcons["Headquarters"],
    text: "Headquarters",
    color: palette.brand.blue,
  };

  return (
    <SpotlightCard name="office" tag={spotlightTag} transparent>
      <AssetContainer>
        {assets[0]?.links[0] && <Asset1 asset={assets[0]?.links[0]} />}
        {assets[0]?.links[1] && <Asset2 asset={assets[0]?.links[1]} />}
        {office && (
          <Asset3>
            <Link
              to={`https://www.google.com/maps/search/${office.address}`}
              underline={false}
              data-analytics-id="enhanced-saved-job-card-office-map"
            >
              <DesktopAsset>
                <picture>
                  <MapSource
                    type="image/webp"
                    media={`(min-width: ${pxToRem(theme.screens.tablet)})`}
                    srcSet={office.mapImageUrls.large}
                  />
                  <MapImg
                    srcSet={office.mapImageUrls.medium}
                    alt={`${
                      office.parsedAddress ?? office.address
                    } Google Maps office image`}
                    loading="lazy"
                  />
                  <MapHQIcon>HQ</MapHQIcon>
                  <MapAddress>{office.address}</MapAddress>
                </picture>
              </DesktopAsset>
              <MobileAsset>
                <MapImg
                  srcSet={office.mapImageUrls.small}
                  alt={`${
                    office.parsedAddress ?? office.address
                  } Google Maps office image`}
                  loading="lazy"
                />
              </MobileAsset>
            </Link>
          </Asset3>
        )}
      </AssetContainer>
    </SpotlightCard>
  );
}
