import { Trans } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled from "@xstyled/styled-components";

import { FooterContent } from "./styles";

import { Button, Middle, Spacing, Text } from "@otta/design";
import { CreateCandidateCvPdfDocument } from "@otta/search/schema";
import { Link } from "@otta/search/components/Link";
import { Separator } from "@otta/search/components/Separator";
import { Person } from "@otta/search/components/Person";

const StyledPerson = styled(Person)`
  justify-content: center;
`;

function CVButton({
  hasCompletedProfile,
}: {
  hasCompletedProfile: boolean;
}): React.ReactElement {
  const [mutation, { loading }] = useMutation(CreateCandidateCvPdfDocument);

  const handleGenerateCV = useCallback(async () => {
    const result = await mutation();
    const url = result?.data?.createCandidateCvPdf?.url;
    if (url) {
      window.open(url);
    }
  }, [mutation]);

  return hasCompletedProfile ? (
    <Spacing>
      <Separator />
      <Text bold align="center">
        Use the profile you built when sending your application
      </Text>
      <Spacing>
        <Button
          type="button"
          level="secondary"
          disabled={loading}
          onClick={handleGenerateCV}
          style={{ margin: "0 auto" }}
        >
          {loading ? "Exporting..." : "Export profile PDF"}
        </Button>

        <Link to="/profile" underline>
          Edit profile
        </Link>
      </Spacing>
    </Spacing>
  ) : (
    <>
      <Separator />
      <StyledPerson text="Use our tailored guidance &amp; advice to build the best profile before you apply. You can export the profile as a PDF to be used anywhere else." />
      <Link to="/profile" underline={false}>
        <Button type="button" level="secondary" style={{ margin: "0 auto" }}>
          <Trans>Build your CV before you apply</Trans>
        </Button>
      </Link>
    </>
  );
}

interface ExternalApplyProps {
  originalUrl: string;
  companyName: string;
  hasCompletedProfile: boolean;
  onApply(): void;
  submitting?: boolean;
}

export function ExternalApply({
  originalUrl,
  companyName,
  hasCompletedProfile,
  onApply,
  submitting,
}: ExternalApplyProps): React.ReactElement {
  return (
    <FooterContent>
      <Spacing>
        <Middle>
          <Link to={originalUrl} underline={false}>
            <Button
              type="button"
              level="primary"
              data-testid="apply-modal-external-button"
              onClick={onApply}
              style={{ margin: "0 auto" }}
              disabled={submitting}
            >
              Apply on {companyName}&apos;s website
            </Button>
          </Link>
        </Middle>
        <Middle>
          <CVButton hasCompletedProfile={hasCompletedProfile} />
        </Middle>
      </Spacing>
    </FooterContent>
  );
}
