import styled, { css, up } from "@xstyled/styled-components";

import { pxToEm, palette } from "@otta/design-tokens";
import { Company } from "@otta/search/schema";
import { imgProxy } from "@otta/search/utils/image";
import { shouldDisplayCompanyGallery } from "@otta/search/pages/Companies/components/CompanyGallery";
import { theme } from "@otta/design";

const Container = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${palette.brand.white};
  padding: lg 20;

  ${up(
    "tablet",
    css`
      overflow-x: auto;
      flex-direction: row;
    `
  )}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100vw;
    `}
`;

const Img = styled.img`
  width: 100%;

  ${up(
    "tablet",
    css`
      height: 300px;
      width: auto;
    `
  )}
`;

const ScrollWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: lg;
  justify-content: center;
  flex-direction: column;

  ${up(
    "tablet",
    css`
      flex-direction: row !important;
    `
  )}
`;

export const shouldDisplayCompanyPhotos = (
  company: CompanyPhotosProps["company"]
) => {
  return !shouldDisplayCompanyGallery(company) && company.photos.length > 0;
};

interface CompanyPhotosProps {
  company: Pick<Company.Fragment, "name" | "photos" | "brandAssetGroups">;
  fullWidth?: boolean;
}

export function CompanyPhotos({
  company,
  fullWidth = true,
}: CompanyPhotosProps): React.ReactElement | null {
  if (!shouldDisplayCompanyPhotos(company)) {
    return null;
  }

  return (
    <Container fullWidth={fullWidth}>
      <ScrollWrapper>
        {company.photos.map(({ id, path }, index) => {
          if (path.startsWith("https://")) {
            return (
              <Img
                key={id}
                data-testid="company-photo"
                src={path}
                alt={`${company.name} ${index + 1}`}
                loading="lazy"
              />
            );
          }

          return (
            <picture key={id}>
              <source
                type="image/webp"
                srcSet={imgProxy(path, { height: 600 }, "webp")}
                media={`(min-width: ${pxToEm(theme.screens.tablet)})`}
              />
              <source
                type="image/webp"
                srcSet={imgProxy(path, { width: 1000 }, "webp")}
              />
              <source
                type="image/jpeg"
                media={`(min-width: ${pxToEm(theme.screens.tablet)})`}
                srcSet={imgProxy(path, { height: 600 }, "jpg")}
              />
              <Img
                data-testid="company-photo"
                src={imgProxy(path, { width: 1000 }, "jpg")}
                alt={`${company.name} ${index + 1}`}
                loading="lazy"
              />
            </picture>
          );
        })}
      </ScrollWrapper>
    </Container>
  );
}
