import { useEffect } from "react";

import {
  BrandAssetGroup,
  BrandAssetType,
  Company,
  ProfileType,
} from "@otta/search/schema";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  useEBTracking,
  usePeopleBreakdownTracking,
} from "@otta/search/contexts/EBTracking";

function videoCategories(company: Company.Company) {
  return Array.from(
    new Set(
      company.teams
        .flatMap(t => t.brandAssetLinks)
        .concat(company.brandAssetGroups.flatMap(bg => bg.links))
        .concat(company.regionalBenefits.flatMap(r => r.brandAssetLinks))
        .concat(company.offices?.flatMap(o => o?.brandAssetLinks ?? []) ?? [])
        .filter(t => t.companyBrandAsset.type === BrandAssetType.Vimeo)
        .flatMap(bal => bal.companyBrandAsset.category ?? [])
    )
  );
}

function galleryPhotosCount(company: Company.Company) {
  return (
    company.brandAssetGroups.find(
      bg => bg.group.name === BrandAssetGroup.CompanyProfileGallery
    )?.links.length ?? 0
  );
}

function managerPrompts(company: Company.Company) {
  return company.teams.some(team => team.members.some(m => m.prompts?.length));
}

export function useViewedProfileEvent(company: Company.Company) {
  const peopleBreakdown = usePeopleBreakdownTracking(company.ebStatistics);
  const trackingData = useEBTracking();

  useEffect(() => {
    if (company.profileTypeName === ProfileType.Enhanced) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Company Profile With EB Content",
        companyId: company.id,
        jobId: trackingData.jobId,
        jobFunction: trackingData.jobFunction,
        jobSubFunction: trackingData.jobSubFunction,
        jobValueClassification: trackingData.jobValueClassification,
        minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
        maxYearsExperienceRequired: trackingData.maxYearsExperienceRequired,
        applyViaOtta: trackingData.applyViaOtta,
        loginStatus: trackingData.loginStatus,
        videos: videoCategories(company),
        officesCount: company.offices?.length ?? 0,
        teamCount: company.teams.length ?? 0,
        managerPrompts: managerPrompts(company),
        galleryPhotosCount: galleryPhotosCount(company),
        peopleBreakdown,
      });
    }
  }, [company, trackingData]);
}
