import styled, { css, down, up, useUp } from "@xstyled/styled-components";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import { Card, Text } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";

const Wrapper = styled(Card)`
  position: sticky;
  top: ${pxToRem(24)};
  ${down(
    "tablet",
    css`
      top: 0;
    `
  )}

  border-bottom: 1px solid ${palette.grayscale.shade200};
  z-index: 1;

  ${down(
    "tablet",
    css`
      /* Reset Card style only for mobile */
      padding: 5 md 1px;
      border-radius: 0;
    `
  )};

  ${up(
    "tablet",
    css`
      min-width: ${pxToRem(250)};
      width: ${pxToRem(320)};
    `
  )}
`;

const Title = styled(Text).attrs({ size: 1 })`
  display: none;

  ${up(
    "tablet",
    css`
      display: block;
      margin-bottom: lg;
    `
  )}
`;

const Nav = styled.ul`
  display: flex;
  gap: sm;
  list-style: none;
  overflow: auto;

  ${up(
    "tablet",
    css`
      flex-direction: column;
      gap: lg;
      overflow: visible;
    `
  )}
`;

const indicatorStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${pxToRem(4)};

  ${up(
    "tablet",
    css`
      top: 0;
      width: ${pxToRem(5)};
      height: 100%;
    `
  )}
`;

const CurrentIndicator = styled(motion.span)`
  ${indicatorStyle}
  background-color: ${palette.brand.yellow};
`;

const NavItem = styled.a`
  position: relative;
  padding: md 0;
  text-decoration: none;
  white-space: nowrap;
  color: inherit;

  /* Trick to prevent the tabs to jump when we set the font-weight to bold, see https://github.com/WTTJ/otta/pull/858 */
  display: inline-block;
  &::after {
    display: block;
    content: attr(data-label);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &::before {
    content: "";
    ${indicatorStyle};
    background-color: ${palette.beige.shade200};
    opacity: 0;
    transition: default;
  }

  &:hover::before {
    opacity: 1;
  }

  &[aria-current="true"] {
    font-weight: bold;
  }

  ${up(
    "tablet",
    css`
      padding: sm lg;
    `
  )}
`;

export const Navigation = ({
  sections,
  onClickItem,
}: {
  sections: { id: string; label: string }[];
  onClickItem: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  const location = useLocation();
  const isTablet = useUp("tablet");

  useEffect(() => {
    if (!location.hash || isTablet) return;

    const element = document.querySelector(`[href="${location.hash}"]`);
    if (!element) return;

    // Can't use scrollend event because Safari doesn't support it
    const timeout = setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        // Prevents vertical scroll
        block: "nearest",
      });
    }, 200);

    return () => clearTimeout(timeout);
  }, [location.hash, isTablet]);

  // Prevent mismatch between server & client as we don't have access to location.hash on the server
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);

  return (
    <Wrapper>
      <Title>Jump to section</Title>
      <nav>
        <Nav>
          {sections.map(({ id, label }) => {
            const isActive = hasRendered && id === location.hash.slice(1);
            return (
              <li key={id}>
                <NavItem
                  href={`#${id}`}
                  aria-current={isActive}
                  data-label={label}
                  onClick={onClickItem}
                >
                  {label}
                  {isActive && (
                    <CurrentIndicator layoutId="company-profile-nav" />
                  )}
                </NavItem>
              </li>
            );
          })}
        </Nav>
      </nav>
    </Wrapper>
  );
};
