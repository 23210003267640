import styled from "@xstyled/styled-components";
import { ToastContainer, ToastContainerProps } from "react-toastify";

import { responsive } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import "react-toastify/dist/ReactToastify.min.css";

const WrappedToastContainer = ({
  className,
  ...rest
}: ToastContainerProps & { className?: string }) => {
  return (
    <div className={className} style={{ width: "100%" }}>
      <ToastContainer
        position="bottom-center"
        autoClose={4000}
        hideProgressBar={true}
        {...rest}
      />
    </div>
  );
};

// https://fkhadra.github.io/react-toastify/how-to-style
export const Toast = styled(WrappedToastContainer)`
  .Toastify__toast-container {
    width: fit-content;
    max-width: 500px;
    text-align: center;
    padding: md;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .Toastify__toast {
    min-height: unset;
    font-family: unset;
    color: ${palette.brand.white};
    border-radius: ${pxToRem(5)};
    ${responsive`
      font-size: ${responsive.modularScale({ mobile: -1, desktop: 0 })};
    `}
  }
  .Toastify__toast--error {
    background-color: ${palette.brand.red};
  }
  .Toastify__toast--warning {
    background-color: ${palette.extended.orange.shade500};
  }
  .Toastify__toast--success {
    background-color: ${palette.grayscale.shade600};
  }
  .Toastify__toast--info {
    background-color: ${palette.extended.blue.shade400};
  }
  .Toastify__close-button {
    display: none;
  }
`;
