import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";
import { useEffect } from "react";

import { EmployeeSurveySignUpForm } from "./EmployeeSurveySignUpForm";

import { Heading, Middle, Spacing, List } from "@otta/design";
import {
  pxToRem,
  borderRadius,
  modularScale,
  palette,
} from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { EmployeeSurveyCompanyDocument } from "@otta/search/schema";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";

export const LeftContent = styled.div`
  background-color: ${palette.brand.yellow};
  ${up(
    "tablet",
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 40%;
    `
  )}
  padding: xl lg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RightContent = styled.div`
  ${up(
    "tablet",
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 60%;
      right: 0;
    `
  )}
  padding: xl lg;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: ${modularScale(11)};
  width: ${modularScale(11)};
  object-fit: contain;
  padding: md;
  background-color: ${palette.brand.white};
  border-radius: ${pxToRem(borderRadius)};
`;

export default function EmployeeSurveySignUpPage(): React.ReactElement | null {
  const location = useLocation();

  const { companyUrlSafeName: urlSafeName } = useParams();

  const { data: companyData } = useQuery(EmployeeSurveyCompanyDocument, {
    variables: { id: urlSafeName as string },
  });

  const company = companyData?.company;

  useEffect(() => {
    if (company) {
      pushAnalyticsEvent({
        eventName: "Employee Viewed",
        pathname: location.pathname,
        companyId: company.id,
      });
    }
  }, [company, location.pathname]);

  if (!company) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>Your experience at {company.name}</title>
      </Helmet>
      <LeftContent>
        <Middle maxWidth={490}>
          <Spacing size={2}>
            <Middle maxWidth={100}>
              {company.logoPath && (
                <Logo src={company.logoPath} alt={`${company.name} logo`} />
              )}
            </Middle>
            <Heading bold size={6} as="h2">
              Leave an endorsement for {company.name}
            </Heading>
            <List type="positive">
              <li>
                Endorsements are displayed on job descriptions to help job
                seekers understand what it&apos;s like to work at {company.name}
              </li>
              <li>
                <TooltipWithIcon content="We believe Glassdoor offers a good space to voice concerns about your company publicly. When doing our research, we heard that job seekers were missing deeper insight on what makes a company great. This is what endorsements are solving.">
                  Unlike Glassdoor, all questions are focused on what makes a
                  company great
                </TooltipWithIcon>
              </li>
              <li>
                Your response will be anonymous and only include the team you
                work in
              </li>
            </List>
          </Spacing>
        </Middle>
      </LeftContent>
      <RightContent>
        <EmployeeSurveySignUpForm company={company} />
      </RightContent>
    </div>
  );
}
