import styled, { css, keyframes } from "@xstyled/styled-components";

import { modularScale, palette } from "@otta/design-tokens";
import { fadeOut } from "@otta/search/components/Animations";

const BANNER_SPEED = 0.4;

const slideUp = keyframes`
  from {
    height: ${modularScale(4)};
    background-color: ${palette.extended.green.shade400};
    transition: height 8s, background-color 8s;
  }
  to {
    height: ${modularScale(-3)};
    background-color: ${palette.brand.white};
  }
`;

const Banner = styled.div<{ showBanner: boolean; progress: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: ${({ showBanner }) =>
    showBanner ? modularScale(4) : modularScale(-3)};
  height: ${({ showBanner }) =>
    showBanner ? "fit-content" : modularScale(-3)};
  background-color: ${({ showBanner }) =>
    showBanner ? palette.extended.green.shade400 : palette.brand.white};
  animation: ${({ showBanner }) =>
    showBanner
      ? "none"
      : css`
          ${slideUp} ${BANNER_SPEED * 2}s forwards
        `};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: default;
    width: ${({ progress }) => progress}%;
    background-color: ${palette.extended.green.shade400};
  }
`;

const BannerContent = styled.div<{ showBanner: boolean }>`
  opacity: 1;
  animation: ${({ showBanner }) =>
    showBanner
      ? "none"
      : css`
          ${fadeOut} ${BANNER_SPEED * 1.4}s forwards
        `};
`;

interface IProgressBannerProps {
  showBanner: boolean;
  progress: number;
  children: React.ReactNode;
}

export const ProgressBanner = (
  props: IProgressBannerProps
): React.ReactElement => (
  <Banner {...props}>
    <BannerContent {...props}>{props.children}</BannerContent>
  </Banner>
);
