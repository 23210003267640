import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import styled, { css, down, up } from "@xstyled/styled-components";

import { pushAnalyticsEvent } from "@otta/analytics";
import { Button, Card, Middle, Text, responsive } from "@otta/design";
import { palette, pxToRem } from "@otta/design-tokens";
import {
  DismissNotificationDocument,
  NotificationFrequency,
  UpdateUserNotificationPreferencesDocument,
} from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

const ResubContainer = styled.div`
  ${responsive`margin-top: ${{ mobile: 16, desktop: 40 }};`}
  padding: 0 lg;
`;

const ResubCard = styled(Card)`
  display: flex;
  justify-content: space-between;

  ${down(
    "tablet",
    css`
      width: 100%;
    `
  )}
`;

const LeftContainer = styled.div`
  width: 100%;
`;

const BetterMatchesText = styled(Text)`
  ${responsive`font-size: ${responsive.modularScale({
    mobile: 1,
    desktop: 2,
  })};`}
  margin-bottom: lg;
  font-weight: bold;
`;

const EnvelopeContainer = styled.div<{ showOnMobile: boolean }>(
  ({ showOnMobile }) => css`
    width: ${pxToRem(150)};
    height: ${pxToRem(150)};

    ${up(
      "tablet",
      css`
        display: ${showOnMobile ? "none" : "block"};
      `
    )}

    ${down(
      "tablet",
      css`
        display: ${showOnMobile ? "block" : "none"};
        margin: auto;
      `
    )}
  `
);

const EnvelopeImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${down(
    "tablet",
    css`
      justify-content: center;
    `
  )}

  ${up(
    "tablet",
    css`
      flex-direction: row;
      margin-left: -16;
    `
  )}
`;

const StyledButton = styled(Button)`
  margin: md lg;

  ${down(
    "tablet",
    css`
      margin: 0 0 md 0;
    `
  )}
`;

const Subnote = styled.p`
  color: ${palette.grayscale.shade600};
  margin-top: lg;
`;

interface ResubscribeProps {
  token?: string;
  refetch: () => void;
}

export function Resubscribe({
  token,
  refetch,
}: ResubscribeProps): React.ReactElement | null {
  const envelopeImg = (
    <EnvelopeImg
      src="https://storage.googleapis.com/otta-static/images/search-app/envelope.png"
      alt="Envelope filled with brand logos"
    />
  );

  const [mutation] = useMutation(UpdateUserNotificationPreferencesDocument);

  const [dismissMutation] = useMutation(DismissNotificationDocument, {
    onError: handleMutationError,
  });

  const dismiss = async () => {
    await dismissNotification();

    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      key: "Batch-end-email-frequency-dismiss",
    });
  };

  const changeFrequency = async (newFrequency: NotificationFrequency) => {
    mutation({
      variables: {
        frequency: newFrequency,
      },
      context: { emailToken: token },
    });

    pushAnalyticsEvent({
      eventName: "Candidate changed email frequency",
      source: "Web App Batch End Card",
      from: NotificationFrequency.Never,
      to: newFrequency,
    });

    await dismissNotification();
  };

  const dismissNotification = useCallback(async () => {
    await dismissMutation({
      variables: { notificationName: "BATCH_END_RESUBSCRIBE" },
      context: { emailToken: token },
    });
    refetch();
  }, [dismissMutation, refetch, token]);

  return (
    <ResubContainer>
      <Middle maxWidth={1024} textAlign="left">
        <ResubCard>
          <LeftContainer>
            <BetterMatchesText>
              Get job match emails from top companies
            </BetterMatchesText>
            <EnvelopeContainer showOnMobile={true}>
              {envelopeImg}
            </EnvelopeContainer>
            <ButtonsContainer>
              <StyledButton
                onClick={() => {
                  changeFrequency(NotificationFrequency.Daily);
                }}
                level="primary"
                type="button"
              >
                Daily
              </StyledButton>
              <StyledButton
                onClick={() => {
                  changeFrequency(NotificationFrequency.Weekly);
                }}
                level="secondary"
                type="button"
              >
                Weekly
              </StyledButton>
              <StyledButton
                onClick={() => {
                  dismiss();
                }}
                level="tertiary"
                type="button"
              >
                No thanks
              </StyledButton>
            </ButtonsContainer>
            <Subnote>Change this anytime in your account settings.</Subnote>
          </LeftContainer>
          <EnvelopeContainer showOnMobile={false}>
            {envelopeImg}
          </EnvelopeContainer>
        </ResubCard>
      </Middle>
    </ResubContainer>
  );
}
