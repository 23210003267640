import styled, { up, css } from "@xstyled/styled-components";
import {
  differenceInYears,
  differenceInMonths,
  addYears,
  parseISO,
} from "date-fns";

import {
  Dates,
  DatesToFrom,
  OrgName,
  OttaCompany,
  Technologies as DefaultTechnologies,
  Title,
} from "./ExperienceItem";

import { Text } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${up(
    "desktop",
    css`
      flex-direction: row;
    `
  )}
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const TechnologiesWrapper = styled.div`
  margin-top: sm;

  ${up(
    "desktop",
    css`
      margin-top: 0;
    `
  )}
`;

const Technologies = styled(DefaultTechnologies)`
  ${up(
    "desktop",
    css`
      justify-content: flex-end;
      max-width: ${pxToRem(350)};
    `
  )}
`;

const SecondaryTitle = styled(Text)`
  color: ${palette.grayscale.shade600};
`;

const yearsAndMonths = (
  startDate: string | null,
  endDate: string | null
): string => {
  const start = startDate ? parseISO(startDate) : new Date();
  const end = endDate ? parseISO(endDate) : new Date();

  const years = differenceInYears(end, start);
  const startMonths = addYears(start, years);
  const months = differenceInMonths(end, startMonths);

  if (years > 0 && months > 0) {
    return `${years}y ${months}m`;
  } else if (years > 0) {
    return `${years}y`;
  } else if (months > 0) {
    return `${months}m`;
  } else if (months === 0 && start <= end) {
    return `1m`;
  }
  return "";
};

interface ISmallExperienceItemProps {
  title: string | null;
  companyUrl?: string | null;
  orgName: string;
  startDate?: string | null;
  endDate?: string | null;
  ottaCompany?: boolean;
  technologiesUsed?: { id: string; value: string }[];
  experienceTechnologiesUsed?: { id: string; value: string }[];
}

export function SmallExperienceItem({
  title,
  orgName,
  ottaCompany = false,
  companyUrl,
  startDate = null,
  endDate = null,
  technologiesUsed = [],
  experienceTechnologiesUsed = [],
}: ISmallExperienceItemProps): React.ReactElement {
  return (
    <Wrapper>
      <Content>
        <div style={{ maxWidth: pxToRem(370) }}>
          {companyUrl && !companyUrl.includes("?keywords") ? (
            <Link to={companyUrl} targetBlank>
              <OrgName
                align="left"
                bold
                onClick={e => e.stopPropagation()}
                underline
              >
                {orgName}
              </OrgName>
            </Link>
          ) : (
            <OrgName align="left" bold>
              {orgName}
            </OrgName>
          )}
          {ottaCompany && <OttaCompany />}
          {title && (
            <Title align="left" as="span">
              {title}
            </Title>
          )}
          {startDate && (
            <div style={{ display: "flex" }}>
              <Dates align="left" size={-1} noTitle={!title}>
                <DatesToFrom
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="MMM yy"
                />
              </Dates>
              <SecondaryTitle
                as="span"
                size={-1}
                align="left"
                style={{ marginLeft: modularScale(-4) }}
              >
                ({yearsAndMonths(startDate, endDate)})
              </SecondaryTitle>
            </div>
          )}
        </div>
      </Content>
      {technologiesUsed.length > 0 && (
        <TechnologiesWrapper>
          <Technologies
            technologiesUsed={technologiesUsed}
            experienceTechnologiesUsed={experienceTechnologiesUsed}
          />
        </TechnologiesWrapper>
      )}
    </Wrapper>
  );
}
