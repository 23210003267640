import styled from "styled-components";

import { CompanyPodcastData } from "./types";

const AudioContainer = styled.audio`
  width: 100%;
`;

export function Audio({ audio }: Pick<CompanyPodcastData, "audio">) {
  return (
    <AudioContainer
      controls
      src={audio.src}
      data-testid="company-podcast-audio-player"
    />
  );
}
