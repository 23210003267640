import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { Company, Job } from "@otta/search/schema";

const Link = styled.a`
  color: black;
`;

const CompanyName = styled.span`
  text-decoration: underline;
`;

interface JobTitleProps {
  job: Pick<Job.Fragment, "title">;
  company?: Pick<Company.Fragment, "websiteUrl" | "name">;
}

export function JobTitle({ job, company }: JobTitleProps): React.ReactElement {
  return (
    <Text bold as="h2" size={2} data-testid="job-title">
      {company ? (
        <>
          {job.title},{" "}
          {company.websiteUrl ? (
            <Link
              href={`${company.websiteUrl}?utm_source=otta.com`}
              target="_blank"
            >
              {company.name}
            </Link>
          ) : (
            <CompanyName> {company.name} </CompanyName>
          )}
        </>
      ) : (
        <> {job.title}</>
      )}
    </Text>
  );
}
