import { motion } from "framer-motion";
import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";

import { WorkExperienceItem } from "./FormCardList";

import { Button, Spacing, Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  UpdateWorkExperienceSuggestionStatusDocument,
  WorkExperienceSuggestionDocument,
  WorkExperienceSuggestionStatus,
} from "@otta/search/schema";
import { List } from "@otta/search/pages/Profile/components/FormData";

const AnimationContainer = styled(motion.div)`
  position: relative;
  top: -0.5rem;
`;

const SuggestedChangesWrapper = styled.div`
  padding: 1rem;

  background-color: ${palette.extended.yellow.shade100};
  border-radius: 0 0 ${pxToRem(10)} ${pxToRem(10)};
`;

const ExperimentalBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 8rem;
  margin-bottom: 0.75rem;
  padding: 0.25rem 0.75rem;

  background-color: ${palette.extended.yellow.shade100};
  border-radius: ${pxToRem(8)};
`;

const SuggestedChangesContent = styled.ol`
  padding: 0.75rem 3rem 1rem 1rem;
  border: 1px solid ${palette.brand.grey};
  border-radius: ${pxToRem(10)};
  background-color: ${palette.brand.white};
`;

const SuggestedChangesControl = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  button {
    padding: 0.33rem 1.5rem;
  }
`;

interface SuggestedChangesProps {
  workExperience: WorkExperienceItem;
  isEditing: boolean;
  onAcceptSuggestion: (suggestion: string[]) => void;
}

export function SuggestedChanges({
  workExperience,
  isEditing,
  onAcceptSuggestion,
}: SuggestedChangesProps): React.ReactElement | null {
  const [isActioned, setIsActioned] = useState(false);

  const { data: workExperienceSuggestions, loading } = useQuery(
    WorkExperienceSuggestionDocument,
    {
      variables: {
        workExperienceId: workExperience.id,
      },
    }
  );

  const [updateSuggestionStatusMutation, { loading: updating }] = useMutation(
    UpdateWorkExperienceSuggestionStatusDocument
  );

  const suggestedChanges = workExperienceSuggestions
    ? workExperienceSuggestions.workExperienceSuggestion
    : null;

  const acceptSuggestion = () => {
    if (suggestedChanges) {
      updateSuggestionStatusMutation({
        variables: {
          suggestionId: suggestedChanges.id,
          status: WorkExperienceSuggestionStatus.Accepted,
        },
      });
      pushAnalyticsEvent({
        eventName: "Candidate Clicked Work experience suggestion",
        status: WorkExperienceSuggestionStatus.Accepted,
      });
      onAcceptSuggestion(suggestedChanges.value);
      setIsActioned(true);
    }
  };

  const dismissSuggestion = () => {
    if (suggestedChanges) {
      updateSuggestionStatusMutation({
        variables: {
          suggestionId: suggestedChanges.id,
          status: WorkExperienceSuggestionStatus.Dismissed,
        },
      });
      pushAnalyticsEvent({
        eventName: "Candidate Clicked Work experience suggestion",
        status: WorkExperienceSuggestionStatus.Dismissed,
      });
      setIsActioned(true);
    }
  };

  useEffect(() => {
    if (
      !isActioned &&
      suggestedChanges?.status == WorkExperienceSuggestionStatus.Active
    ) {
      pushAnalyticsEvent({
        eventName: "Candidate Viewed Work experience suggestion",
        status: WorkExperienceSuggestionStatus.Active,
      });
    }
  }, [suggestedChanges]);

  if (!workExperience || isEditing) {
    return null;
  }
  return (
    <>
      {suggestedChanges &&
        suggestedChanges.status == WorkExperienceSuggestionStatus.Active && (
          <AnimationContainer
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "fit-content" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              type: "spring",
              duration: 0.25,
              damping: 20,
              delay: 0,
            }}
          >
            <SuggestedChangesWrapper
              data-cs-mask
              data-testid="suggested-changes"
            >
              <Spacing>
                <div>
                  <ExperimentalBar>
                    <Icon size={1} icon="new-to-otta" />{" "}
                    <Text size={-2} bold>
                      Experimental
                    </Text>
                  </ExperimentalBar>
                  <Text size={-1} bold>
                    We&apos;ve suggested some changes to help you stand out. Our
                    AI doesn&apos;t know everything so check them for accuracy:
                  </Text>
                </div>
                <SuggestedChangesContent>
                  <List>
                    {suggestedChanges.value.map(change => (
                      <li key={change}>{change}</li>
                    ))}
                  </List>
                </SuggestedChangesContent>
                <SuggestedChangesControl>
                  {loading || updating ? (
                    <Loading />
                  ) : (
                    <>
                      <Button
                        onClick={acceptSuggestion}
                        disabled={loading || updating}
                        level="primary"
                        size="small"
                      >
                        Accept suggestions
                      </Button>
                      <Button
                        onClick={dismissSuggestion}
                        disabled={loading || updating}
                        level="tertiary"
                        size="small"
                      >
                        Hide suggestions
                      </Button>
                    </>
                  )}
                </SuggestedChangesControl>
              </Spacing>
            </SuggestedChangesWrapper>
          </AnimationContainer>
        )}
    </>
  );
}
