import styled, { css } from "@xstyled/styled-components";

import { Button, pulse } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";

export const SelectButton = styled(Button)<{ selected: boolean }>`
  width: 100%;
  border: none;

  border-radius: ${pxToRem(6)};
  background-color: ${({ selected }) =>
    selected ? palette.brand.yellow : palette.brand.white};
  font-weight: normal;
  &:active {
    transform: scale(0.93);
  }
  &:hover {
    animation: ${pulse} 0.2s ease-out;
    ${({ selected }) =>
      css`
        background-color: ${selected
          ? palette.brand.yellow
          : palette.brand.white};
      `}
  }
`;
