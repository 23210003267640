import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { useState } from "react";

import { Button, Card, Clickable, Modal, Text, Tipbox } from "@otta/design";
import { palette } from "@otta/design-tokens";
import {
  CreateGlobalUserSuppressionDocument,
  NotificationFrequency,
  UpdateUserNotificationPreferencesDocument,
  UserSubscriptionsDocument,
} from "@otta/search/schema";

const TurnOffAllButton = styled(Text)<{ disabled: boolean }>`
  color: ${palette.brand.red};
  color: ${({ disabled = true }) =>
    disabled ? palette.grayscale.shade200 : palette.brand.red};
  text-align: left;
  text-decoration: underline;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: xl;
`;

const Warning = styled(Tipbox)`
  margin: xl 0;
  border-color: ${palette.extended.red.shade800};
  background-color: ${palette.extended.orange.shade100};
`;

export const UnsubscribeFromAll = ({ disabled }: { disabled: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const [createGlobalSuppression, { loading }] = useMutation(
    CreateGlobalUserSuppressionDocument,
    {
      refetchQueries: [
        {
          query: UserSubscriptionsDocument,
        },
      ],
    }
  );

  const [updateUserNotificationPreferencesMutation] = useMutation(
    UpdateUserNotificationPreferencesDocument
  );

  const turnOffSubscriptions = () => {
    createGlobalSuppression({ variables: { source: "search_app_settings" } });
    updateUserNotificationPreferencesMutation({
      variables: { frequency: NotificationFrequency.Never },
    });
    setModalOpen(false);
  };

  return (
    <>
      <Clickable
        data-testid="turn-off-notifications-button"
        style={{
          width: "100%",
          cursor: `${disabled ? "not-allowed" : "pointer"}`,
        }}
        onClick={() => {
          if (disabled) {
            return undefined;
          }
          setModalOpen(true);
        }}
      >
        <TurnOffAllButton disabled={disabled}>
          {loading ? "Saving..." : "Turn off all notifications"}
        </TurnOffAllButton>
      </Clickable>
      <Modal open={modalOpen} onOpenChange={() => setModalOpen(false)}>
        <Card data-testid="turn-off-notifications-modal">
          <Text bold>Turn off all notifications</Text>
          <Warning level={"error"}>
            You will not be notified when a company messages you about a job or
            recent application.
          </Warning>
          <Text>
            We will still notify you of essential information, like password
            resets or changes to our terms and conditions.
          </Text>

          <ButtonContainer>
            <Button
              data-testid="modal-off-button"
              level="destructive"
              onClick={turnOffSubscriptions}
            >
              Turn off all notifications
            </Button>
            <Button
              data-testid="modal-cancel-button"
              level="primary"
              onClick={() => setModalOpen(false)}
            >
              Keep notifications on
            </Button>
          </ButtonContainer>
        </Card>
      </Modal>
    </>
  );
};
