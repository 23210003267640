import styled, { css, down } from "@xstyled/styled-components";

import { Button } from "@otta/design";
import { palette } from "@otta/design-tokens";

export const DeleteButton = styled(Button)`
  color: red;
  border-color: red;
  background: ${palette.grayscale.shade50};
  margin: 0;

  :hover {
    color: ${palette.brand.red};
    border-color: ${palette.brand.red};
    background: ${palette.brand.grey};
  }

  ${down(
    "tablet",
    css`
      margin-top: sm;
    `
  )}
`;
