import { useNavigate, useLocation } from "react-router-dom";
import styled, { css } from "@xstyled/styled-components";

import { Text, Spacing } from "@otta/design";
import { Icon } from "@otta/icons";
import { palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { QuizSection } from "@otta/search/containers/Quiz/SidebarQuiz";

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: md;
  color: ${palette.grayscale.shade600};
`;

const SectionTitle = styled(Text)`
  display: flex;
  font-weight: bold;
  margin-bottom: md;
`;

const SectionLink = styled(Text).attrs({ as: "a" })<{ active: boolean }>`
  width: fit-content;
  cursor: pointer;
  color: ${palette.brand.black};
  border-bottom: 4px solid transparent;

  ::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    overflow: hidden;
    visibility: hidden;
    height: 0;
  }

  ${({ active }) =>
    active &&
    css`
      font-weight: bold;
      border-bottom: 4px solid ${palette.brand.yellow};
    `}
`;

const SectionWrapper = styled.section`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export function Section({
  section,
}: {
  section: QuizSection;
}): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <SectionContainer>
      <Icon size={1} icon={section.icon} />
      <SectionWrapper>
        <SectionTitle>{section.title}</SectionTitle>
        <Spacing>
          {section.questions.map(question => (
            <SectionLink
              title={question.menuTitle}
              key={question.path}
              active={location.pathname.endsWith(`/${question.path}`)}
              onClick={() => {
                pushAnalyticsEvent({
                  eventName: "Candidate Clicked Quiz Sidebar",
                  section: question.menuTitle,
                });
                navigate(
                  {
                    pathname: `../${question.path}`,
                    search: location.search,
                  },
                  { replace: true }
                );
              }}
            >
              {question.menuTitle}
            </SectionLink>
          ))}
        </Spacing>
      </SectionWrapper>
    </SectionContainer>
  );
}
