import styled from "@xstyled/styled-components";
import { useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "react-use";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, Clickable, Text, VisuallyHidden } from "@otta/design";
import { Icon } from "@otta/icons";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useEBTracking } from "@otta/search/contexts/EBTracking";

const LOCAL_STORAGE_KEY = "-otta-eb-feedback";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: md;
  width: 100%;
  padding: md lg;
  border-radius: ${pxToRem(4)};
  background: ${palette.extended.blue.shade100};
  overflow: hidden;
`;

const FeedbackReceived = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
`;

const SurveyLink = styled(Text)`
  color: inherit;
`;

const CloseButton = styled(Clickable)`
  align-self: baseline;
`;

const Buttons = styled.div`
  display: flex;
  gap: md;
`;

const StyledButton = styled(Button)`
  height: ${pxToRem(32)};
  width: ${pxToRem(122)};
`;

const feedbackText = {
  apply: "Did this video help you write this job application?",
  job: "Did this video help you decide if you want to save, skip or apply to this job?",
  company:
    "Did this video help you decide if this is a company you might like to join?",
};

export const EBFeedback = ({
  videoId,
  videoCategory,
}: {
  videoId: string;
  videoCategory: string | null;
}) => {
  const { page, companyId, jobId, jobFunction, jobSubFunction } =
    useEBTracking();
  const key = useMemo(() => {
    if (!companyId) return "";

    return [
      `companyId:${companyId}`,
      `jobId:${jobId}`,
      `page:${page}`,
      videoId,
    ].join("-");
  }, [companyId, jobId, page, videoId]);

  const [dismissedFeedback, setDismissedFeedback] = useLocalStorage<string[]>(
    LOCAL_STORAGE_KEY,
    []
    // To fix this issue https://github.com/streamich/react-use/pull/2556
  ) as [string[], (value: string[]) => void, () => void];

  const [shouldShownComponent, setShouldShownComponent] = useState(false);
  const [hasSentFeedback, setHasSentFeedback] = useState(false);

  useEffect(() => {
    if (!key) return;

    if (dismissedFeedback.includes(key)) {
      return;
    }

    setShouldShownComponent(true);
  }, [dismissedFeedback, key]);

  if (!page) {
    // eslint-disable-next-line no-console
    console.error("EBFeedback component must be wrapped in EBTrackingProvider");
    return null;
  }

  if (!shouldShownComponent) {
    return null;
  }

  const handleClickClose = () => {
    setDismissedFeedback([...dismissedFeedback, key]);
    setShouldShownComponent(false);
  };

  const handleClickFeedback = (isHelpful: boolean) => () => {
    setDismissedFeedback([...dismissedFeedback, key]);
    setHasSentFeedback(true);

    pushAnalyticsEvent({
      eventName: "Candidate Clicked Employer Branding Video Feedback",
      response: isHelpful ? "yes" : "no",
      videoId,
      videoCategory,
      companyId,
      jobId,
      jobFunction,
      jobSubFunction,
    });
  };

  const handleClickSurvey = () => {
    pushAnalyticsEvent({
      eventName: "Candidate Clicked Employer Branding Video Survey",
      videoId,
      videoCategory,
      companyId,
      jobId,
      jobFunction,
      jobSubFunction,
    });
  };

  return (
    <Wrapper>
      <div>
        <Text bold>Tell us what you think</Text>
        {hasSentFeedback ? (
          <FeedbackReceived>
            <Text>
              We&apos;ll use your feedback to decide how and when to show videos
              like this.
            </Text>
            <SurveyLink
              as="a"
              href="https://ottauk.typeform.com/to/ryNO4fA8"
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleClickSurvey}
            >
              You can share more of your thoughts on our new content via this
              survey.
            </SurveyLink>
          </FeedbackReceived>
        ) : (
          <Text>{feedbackText[page]}</Text>
        )}
      </div>
      <CloseButton onClick={handleClickClose}>
        <VisuallyHidden>close feedback</VisuallyHidden>
        <Icon icon="close" />
      </CloseButton>
      {!hasSentFeedback && (
        <Buttons hidden={hasSentFeedback}>
          <StyledButton
            size="small"
            level="secondary"
            onClick={handleClickFeedback(true)}
          >
            Yes, helpful
          </StyledButton>
          <StyledButton
            size="small"
            level="secondary"
            onClick={handleClickFeedback(false)}
          >
            No, not helpful
          </StyledButton>
        </Buttons>
      )}
    </Wrapper>
  );
};
