import styled, { css, up } from "@xstyled/styled-components";

import { CompanyImagesSection } from "./CompanyImagesSection";

import { Text } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import {
  Company,
  UserJobPreferences,
  UserJobPreferencesDocument,
} from "@otta/search/schema";
import { Icon, OttaIconsId } from "@otta/icons";
import { useQuery } from "@otta/search/apollo";
import { useUserCompanySectorTags } from "@otta/search/utils/user";
import { FollowCompany } from "@otta/search/pages/Jobs/JobCard/components/FollowCompany";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const HeroWrapper = styled.div`
  padding: 0 14;
  background-color: ${palette.brand.white};
  border-top: 1px solid ${palette.grayscale.shade200};

  ${up(
    "tablet",
    css`
      border: none;
      background-color: ${palette.grayscale.shade50};
    `
  )}
`;

const Hero = styled.div`
  max-width: ${pxToRem(1136)};
  margin: 0 auto;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: sm;
  padding: 18 0;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const LogoWrapper = styled.div`
  &[data-hide-on-tablet="true"] {
    ${up(
      "tablet",
      css`
        display: none;
      `
    )}
  }
`;

const HeroContent = styled.section`
  padding: lg 0;

  ${up(
    "tablet",
    css`
      display: flex;
      gap: lg;
    `
  )}
`;

const Card = styled.div`
  ${up(
    "tablet",
    css`
      padding: lg;
      border: 1px solid ${palette.brand.grey};
      border-radius: ${pxToRem(16)};
      background-color: ${palette.brand.white};
    `
  )}
`;

const CardOfficeMap = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.brand.grey};
  border-radius: ${pxToRem(16)};
  background-color: ${palette.brand.white};
  overflow: hidden;
  width: ${pxToRem(152)};
`;

const CompanyInfoSectionWrapper = styled(Card)`
  ${up(
    "tablet",
    css`
      flex-grow: 1;
      padding: 40;
    `
  )}
`;

const CompanyInfoSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: xs;
`;

const Title = styled(Text)`
  margin-bottom: xs;
  font-size: ${modularScale(2)};

  ${up(
    "tablet",
    css`
      font-size: ${modularScale(5)};
    `
  )}
`;

const Description = styled(Text)`
  margin-bottom: lg;
`;

const InfoList = styled.dl`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: sm;
  row-gap: lg;

  ${up(
    "tablet",
    css`
      row-gap: md;
    `
  )}
`;

const TagsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: sm;
`;

const Tag = styled(Text).attrs({ as: "li" })`
  border-radius: 8px;
  padding: xs sm;
  background-color: ${palette.beige.shade200};
  list-style-type: none;

  &[data-is-user-preference="true"] {
    background-color: ${palette.brand.green};
    font-weight: 700;
  }
`;

const LogoImg = styled.img`
  object-fit: contain;
  height: ${pxToRem(55)};
  width: ${pxToRem(55)};

  ${up(
    "tablet",
    css`
      height: ${pxToRem(120)};
      width: ${pxToRem(120)};
    `
  )}
`;

const MapWrapper = styled.div`
  position: relative;
`;

const MapImg = styled.img`
  display: block;
  height: ${pxToRem(152)};
  width: ${pxToRem(152)};
`;

const MapHQIcon = styled.div`
  position: absolute;
  top: ${pxToRem(8)};
  right: ${pxToRem(8)};
  width: ${pxToRem(28)};
  height: ${pxToRem(25)};
  border-radius: ${pxToRem(4)};
  font-size: ${modularScale(-1)};
  font-weight: bold;
  padding: xs;
  background-color: ${palette.brand.black};
  color: ${palette.brand.white};
`;

const Sidebar = styled.aside`
  display: none;

  ${up(
    "tablet",
    css`
      display: flex;
      flex-direction: column;
      gap: lg;
    `
  )}
`;

const Back = () => {
  const companyName = useWelcomeToTheJungle();

  return (
    <BackLink to="/companies">
      <Icon icon="arrow-left" size={2} />
      <Text bold>See other companies on {companyName}</Text>
    </BackLink>
  );
};

const Logo = ({
  company,
  hideOnTablet,
}: {
  company: Company.Company;
  hideOnTablet?: boolean;
}) => {
  return (
    <LogoWrapper data-hide-on-tablet={hideOnTablet}>
      {company.websiteUrl && company.faviconPath && (
        <Link
          to={`${company.websiteUrl}?utm_source=otta.com`}
          underline={false}
          data-analytics-id="company-page-logo"
        >
          <LogoImg src={company.faviconPath} alt={`${company.name} logo`} />
        </Link>
      )}
    </LogoWrapper>
  );
};

const InfoListItem = ({
  icon,
  children,
}: {
  icon: OttaIconsId;
  children: React.ReactNode;
}) => {
  return (
    <>
      <dt>
        <Icon size={2} icon={icon} />
      </dt>
      <dd>{children}</dd>
    </>
  );
};

const CompanyTags = ({
  company,
  user,
}: {
  company: Company.Company;
  user: UserJobPreferences.CurrentUser | null | undefined;
}) => {
  const userCompanySectorTags = useUserCompanySectorTags({
    company,
    user,
  });
  if (!userCompanySectorTags) return null;

  const { orderedSectorTags, userPreferences } = userCompanySectorTags;

  return (
    <InfoListItem icon="industry">
      <TagsContainer>
        {orderedSectorTags.map(({ id, value }) => (
          <Tag key={id} data-is-user-preference={userPreferences.has(id)}>
            {value}
          </Tag>
        ))}
      </TagsContainer>
    </InfoListItem>
  );
};

const CompanyInfoSection = ({ company }: { company: Company.Company }) => {
  const { data: userData } = useQuery(UserJobPreferencesDocument);

  return (
    <CompanyInfoSectionWrapper>
      <CompanyInfoSectionHeader>
        <div>
          <Title bold>{company.name}</Title>
          <Description>{company.shortDescription}</Description>
        </div>
        <Logo company={company} hideOnTablet />
      </CompanyInfoSectionHeader>
      <InfoList>
        {company.size && company.size.value !== null && (
          <InfoListItem icon="users">
            {company.size.value} employees
          </InfoListItem>
        )}
        <CompanyTags company={company} user={userData?.currentUser} />
        <InfoListItem icon="location">
          {company.parsedHqAddress || company.hqAddress}
        </InfoListItem>
      </InfoList>
      <FollowCompany company={company} user={userData?.currentUser} />
    </CompanyInfoSectionWrapper>
  );
};

const CompanyOfficeMap = ({ company }: { company: Company.Company }) => {
  const mapUrl = `https://www.google.com/maps/search/${company.hqAddress}`;

  return (
    <CardOfficeMap>
      <Link
        to={mapUrl}
        underline={false}
        data-analytics-id="company-page-headquarter-map"
      >
        <MapWrapper>
          <MapImg
            src={company.offices?.at(0)?.mapImageUrls.small}
            alt={`${company.name} headquarter Google Maps location`}
          />
          <MapHQIcon>HQ</MapHQIcon>
        </MapWrapper>
      </Link>
    </CardOfficeMap>
  );
};

export function CompanyHero({ company }: { company: Company.Company }) {
  return (
    <HeroWrapper>
      <Hero>
        <Back />
        <HeroContent>
          <CompanyInfoSection company={company} />
          <CompanyImagesSection company={company} />
          <Sidebar>
            <Card>
              <Logo company={company} />
            </Card>
            {company.hqAddress && company.offices?.at(0) && (
              <CompanyOfficeMap company={company} />
            )}
          </Sidebar>
        </HeroContent>
      </Hero>
    </HeroWrapper>
  );
}
