import { useMemo, useState } from "react";
import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { getYear, parseISO } from "date-fns";

import { Error } from "./FormData";

import { palette, pxToRem } from "@otta/design-tokens";
import { Label, Spacing, Text } from "@otta/design";
import { composeValidators, required } from "@otta/search/utils/validators";
import { SmallCheckbox } from "@otta/search/components/Input/SmallCheckbox";
import { MonthPicker } from "@otta/search/components/Input/MonthPicker";

const DatesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
`;

const CheckboxWrapper = styled.div<{ showEndDate?: boolean }>`
  ${({ showEndDate }) => showEndDate && `padding-bottom: ${pxToRem(12)}`}
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const OptionalText = styled(Text)`
  padding-left: sm;
  color: ${palette.grayscale.shade400};
`;

interface IDatesProps {
  startDateName: string;
  endDateName: string;
  showEndDate?: boolean;
  checkboxLabel?: string;
  endDateEndYear?: number;
  optional?: boolean;
  clearEndDate?: () => void;
  setEndDateToOriginal?: () => void;
}

const validStartDate = (value?: string): string | undefined => {
  return !value || parseISO(value) <= new Date()
    ? undefined
    : "Start date must be in the past";
};

const validEndDate = (startDate: string) => (value?: string) =>
  !value || parseISO(value) >= parseISO(startDate)
    ? undefined
    : "End date must be after start date";

export function DatesInput({
  startDateName,
  endDateName,
  endDateEndYear,
  checkboxLabel = "Currently ongoing",
  optional,
  clearEndDate,
  setEndDateToOriginal,
  ...props
}: IDatesProps): React.ReactElement {
  const [showEndDate, setShowEndDate] = useState<boolean>(
    props.showEndDate ?? false
  );

  const optionalText = "(optional)";

  const currentYear = useMemo(() => getYear(new Date()), []);

  return (
    <DatesWrapper>
      <Field
        name={startDateName}
        validate={
          optional
            ? composeValidators(validStartDate)
            : composeValidators(required, validStartDate)
        }
      >
        {({ input, meta }) => {
          return (
            <Spacing>
              <div data-testid="start-date">
                <Spacing size={-3}>
                  <LabelWrapper>
                    <Label>Start date </Label>
                    {optional && <OptionalText> {optionalText}</OptionalText>}
                  </LabelWrapper>
                  <MonthPicker {...input} endYear={currentYear} />
                  {meta.error && meta.touched && <Error>{meta.error}</Error>}
                </Spacing>
              </div>
              <Spacing>
                <Field
                  name={endDateName}
                  validate={composeValidators(validEndDate(input.value))}
                  key={input.value}
                >
                  {({ input: endDateInput, meta: endDateMeta }) => {
                    return (
                      <>
                        <CheckboxWrapper showEndDate={showEndDate}>
                          <SmallCheckbox
                            label={checkboxLabel}
                            onChange={() => {
                              if (showEndDate && clearEndDate) {
                                // i.e. end date about to be hidden
                                clearEndDate();
                              } else if (!showEndDate && setEndDateToOriginal) {
                                setEndDateToOriginal();
                              }
                              setShowEndDate(prevState => !prevState);
                            }}
                            checked={!showEndDate}
                            isYellow
                          />
                        </CheckboxWrapper>
                        {showEndDate && (
                          <Spacing>
                            <LabelWrapper>
                              <Label>End date </Label>
                              {optional && (
                                <OptionalText> {optionalText}</OptionalText>
                              )}
                            </LabelWrapper>
                            <MonthPicker
                              {...endDateInput}
                              endYear={endDateEndYear ?? currentYear}
                              value={showEndDate ? endDateInput.value : null}
                            />
                            {endDateMeta.error && endDateMeta.touched && (
                              <Error>{endDateMeta.error}</Error>
                            )}
                          </Spacing>
                        )}
                      </>
                    );
                  }}
                </Field>
              </Spacing>
            </Spacing>
          );
        }}
      </Field>
    </DatesWrapper>
  );
}
