import deepEqual from "deep-equal";
import { FormApi } from "final-form";
import { useEffect, useRef, useState } from "react";
import { Form as FinalForm } from "react-final-form";
import styled, { css, up } from "@xstyled/styled-components";

import { DeleteButton } from "./DeleteButton";
import { CardTitle } from "./FormHeader";

import { palette, modularScale, pxToRem } from "@otta/design-tokens";
import { Button, Card as DefaultCard, Spacing, Text } from "@otta/design";
import { Delete as Cancel } from "@otta/search/components/Icons/Delete";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Card = styled(DefaultCard)<{
  clickable: boolean;
}>(
  ({ clickable }) => css`
    ${clickable ? "cursor: pointer;" : null};

    border: ${pxToRem(1)} solid ${palette.brand.white};

    ${up(
      "desktop",
      css`
        #edit-button {
          visibility: hidden;
        }
        &:hover {
          #edit-button {
            visibility: visible;
          }

          ${clickable &&
          `
        background: ${palette.beige.shade100};
        border: ${pxToRem(1)} solid ${palette.grayscale.shade400};
        cursor: pointer;
      `}
        }
      `
    )}
  `
);

export const CircleButton = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: none;
  background: none;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EditButton = styled(CircleButton)`
  margin: 0;
  padding: 0;

  &:hover {
    background: ${palette.brand.grey};
  }

  &:focus {
    background: ${palette.brand.grey};
  }

  svg {
    height: ${modularScale()};
    stroke: ${palette.brand.black};
  }
`;

const GreyText = styled(Text)`
  color: ${palette.grayscale.shade600};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  button:only-child {
    margin-left: auto;
  }
`;

const Optional = styled(Text)`
  color: ${palette.grayscale.shade600} !important;
  font-style: italic;
`;

const EditingComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
interface IFormCardProps<
  QueryData,
  QuestionData,
  InitialValues,
  EditedValues,
  SubmitValues
> {
  title?: string | React.ReactNode;
  dates?: React.ReactNode;
  tip?: string;
  index?: number;
  introText?: string;
  data: QueryData | null;
  formatIncomingData: (data: QueryData) => InitialValues;
  formatOutgoingData: (
    values: EditedValues
  ) => SubmitValues | Promise<SubmitValues>;
  onSubmit: (values: SubmitValues) => void;
  onDelete?: () => void;
  onCancel?: () => void;
  editingComponent: React.ComponentType<{
    form: FormApi<EditedValues>;
    data: QueryData | null;
    isCreating?: boolean;
    questionData?: QuestionData;
  }>;
  displayComponent: React.ComponentType<{
    data: QueryData;
    questionData?: QuestionData;
    index?: number;
  }>;
  isInitiallyEditing?: boolean;
  questionData?: QuestionData;
  required?: boolean;
  applicationView?: boolean;
  "data-testid"?: string;
  isCreating?: boolean;
}

export function FormCard<
  QueryData,
  QuestionData,
  InitialValues,
  EditedValues,
  SubmitValues
>({
  index,
  title,
  dates,
  introText,
  tip,
  data,
  formatIncomingData,
  formatOutgoingData,
  onSubmit,
  onDelete,
  onCancel,
  editingComponent: EditingComponent,
  displayComponent: DisplayComponent,
  isInitiallyEditing = false,
  questionData,
  required,
  applicationView = false,
  "data-testid": dataTestId,
  isCreating = false,
}: IFormCardProps<
  QueryData,
  QuestionData,
  InitialValues,
  EditedValues,
  SubmitValues
>): React.ReactElement {
  const [editing, setEditing] = useState(isInitiallyEditing || !data);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const clickable = !editing;

  const handleDelete = async () => {
    if (onDelete) {
      setDeleteLoading(true);
      await onDelete();
      if (isMounted.current) {
        setDeleteLoading(false);
        setEditing(false);
      }
    }
  };

  const handleEditClick = () => {
    if (editing) {
      onCancel && onCancel();
      setEditing(false);
    } else {
      setEditing(true);
    }
  };

  const handleFormSubmit = async (values: EditedValues) => {
    setSubmitLoading(true);

    const data = await formatOutgoingData(values);
    onSubmit(data);

    setEditing(false);
    setSubmitLoading(false);
  };

  return (
    <Spacing>
      <FinalForm
        initialValues={data ? formatIncomingData(data) : null}
        initialValuesEqual={(oldState, newState) =>
          deepEqual(oldState, newState)
        }
        onSubmit={handleFormSubmit}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} data-testid={dataTestId ?? "form-card"}>
            <Card
              clickable={clickable}
              onClick={clickable ? () => setEditing(true) : undefined}
            >
              <Spacing size={4}>
                <Spacing>
                  <TopSection>
                    {title && !editing && (
                      <div>
                        {typeof title === "string" ? (
                          <CardTitle bold>
                            {title}
                            {tip && <TooltipWithIcon content={tip} />}
                            {required && !applicationView && (
                              <Optional>Recommended</Optional>
                            )}
                            {!required && <Optional>Optional</Optional>}
                          </CardTitle>
                        ) : (
                          title
                        )}
                      </div>
                    )}
                    {editing ? (
                      <>
                        {introText ? (
                          <GreyText>{introText}</GreyText>
                        ) : (
                          <CardTitle bold>{title}</CardTitle>
                        )}
                        <EditButton
                          data-testid="edit-button"
                          type="button"
                          onClick={handleEditClick}
                        >
                          <Cancel />
                        </EditButton>
                      </>
                    ) : (
                      <ColumnWrapper>
                        {dates && dates}
                        <EditButton
                          data-testid="edit-button"
                          id="edit-button"
                          type="button"
                          onClick={handleEditClick}
                        >
                          <Pencil />
                        </EditButton>
                      </ColumnWrapper>
                    )}
                  </TopSection>

                  <ComponentWrapper>
                    {editing || !data ? (
                      <EditingComponentWrapper>
                        <EditingComponent
                          form={form}
                          data={data}
                          questionData={questionData}
                          isCreating={isCreating}
                        />
                      </EditingComponentWrapper>
                    ) : (
                      <DisplayComponent
                        data={data}
                        index={index}
                        questionData={questionData}
                      />
                    )}
                  </ComponentWrapper>
                </Spacing>
                {editing && (
                  <BottomSection>
                    {onDelete && (
                      <DeleteButton
                        level="secondary"
                        type="button"
                        disabled={deleteLoading}
                        onClick={handleDelete}
                      >
                        {deleteLoading ? "Deleting..." : "Delete"}
                      </DeleteButton>
                    )}
                    <Button
                      level="primary"
                      type="submit"
                      disabled={submitLoading}
                    >
                      {submitLoading ? "Saving..." : "Save"}
                    </Button>
                  </BottomSection>
                )}
              </Spacing>
            </Card>
          </form>
        )}
      />
    </Spacing>
  );
}
