import "./i18n";

import * as Sentry from "@sentry/browser";
import { hydrateRoot } from "react-dom/client";
import { StrictMode } from "react";

import { Client } from "./Client";
import { ExperimentProvider } from "./providers/Unleash";
import { App } from "./App";
import { PageStatusProvider } from "./providers/page";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://f05d9c288e152a14f409fc7919d9516c@o140309.ingest.sentry.io/4506631363493888",
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications.",
      "Non-Error exception captured with value: Object Not Found Matching Id:",
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      "Unauthenticated",
      "ApolloError: Response not successful: Received status code 429",
    ],
    sampleRate: 0.8,
    environment: import.meta.env.MODE,
    allowUrls: [/https?:\/\/((app)\.)?otta\.com/],
    denyUrls: [
      // Chrome extensions
      /^chrome:\/\//i,
    ],
  });
}

hydrateRoot(
  document.getElementById("root") as HTMLElement,
  <StrictMode>
    <PageStatusProvider locale={window.__LOCALE__}>
      <Client>
        <ExperimentProvider
          toggles={window.__TOGGLES__}
          definitions={window.__TOGGLE_DEFNS__}
        >
          <App />
        </ExperimentProvider>
      </Client>
    </PageStatusProvider>
  </StrictMode>
);
