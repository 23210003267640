import { InputHTMLAttributes } from "react";
import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const Wrapper = styled.label<{ isYellow: boolean }>`
  display: block;
  position: relative;
  padding-left: 40px;
  margin: 8px 0;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: ${palette.brand.black};

  input:checked ~ span {
    background-color: ${({ isYellow }) =>
      isYellow ? palette.brand.yellow : palette.brand.black};
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid
      ${({ isYellow }) =>
        isYellow ? palette.brand.black : palette.brand.white};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2px;
  height: 25px;
  width: 25px;
  border-radius: 4px;
  background-color: ${palette.grayscale.shade200};

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

interface SmallCheckboxProps {
  label: string;
  checked?: boolean;
  onChange: (event: any) => void;
  isYellow?: boolean;
}

export function SmallCheckbox({
  label,
  checked = false,
  onChange,
  isYellow = false,
  ...props
}: SmallCheckboxProps &
  InputHTMLAttributes<HTMLInputElement>): React.ReactElement {
  return (
    <Wrapper isYellow={isYellow}>
      <Text size={-1} align="left">
        {label}
      </Text>
      <Input type="checkbox" checked={checked} onChange={onChange} {...props} />
      <Checkmark />
    </Wrapper>
  );
}
