import {
  Bullet,
  BulletPointSection,
} from "../../pages/Jobs/JobCard/components/BulletPointSection";

import { Company } from "@otta/search/schema";

export const shouldDisplayCompanyValues = (
  company: CompanyValuesProps["company"]
) => {
  return Boolean(company.values.length);
};

interface CompanyValuesProps {
  company: Pick<Company.Fragment, "values">;
}

export function CompanyValues({
  company,
}: CompanyValuesProps): React.ReactElement {
  const bullets = company.values
    .filter(v => v.value)
    .map(v => ({ id: v.id, text: v.value } as Bullet));

  return (
    <BulletPointSection
      title="Company values"
      bullets={bullets}
      bulletTestId="company-value-bullet"
    />
  );
}
