import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  div {
    margin-left: 16px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
`;

const Icon = styled.img`
  width: 56px;
`;

interface IIconFeatureProps {
  text: string | React.ReactElement;
  icon: string;
  title?: string;
}

export const IconFeature = ({
  text,
  icon,
  title,
}: IIconFeatureProps): React.ReactElement => (
  <Wrapper data-testid="icon-feature">
    <Icon src={icon} alt="Icon" />
    <TextWrapper>
      {title && (
        <Text size={1} bold>
          {title}
        </Text>
      )}
      <Text size={-1} color={palette.grayscale.shade600}>
        {text}
      </Text>
    </TextWrapper>
  </Wrapper>
);
