import styled, { css, up } from "@xstyled/styled-components";

import { Spacing } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";

const DesktopOnly = styled.div`
  display: none;

  ${up(
    "desktop",
    css`
      display: block;
    `
  )}
`;

const ARROW_HEIGHT = 44;

const NavContainer = styled.div<{
  align: "left" | "right";
}>`
  height: 100%;
  display: flex;
  ${({ align }) =>
    align === "right" ? `padding-left: lg` : `padding-right: lg`};
  justify-content: ${({ align }) =>
    align === "right" ? "flex-end" : "flex-start"};
  align-items: center;
  z-index: 2;
  min-width: ${pxToRem(ARROW_HEIGHT + 16)};
`;

const NavButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(ARROW_HEIGHT)};
  min-width: ${pxToRem(ARROW_HEIGHT)};
  border-radius: 50%;
  background-color: ${palette.grayscale.shade50};
  border: ${pxToRem(1)} solid ${palette.grayscale.shade200};
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
`;

export const NavArrow = ({
  onClick: handleClick,
  direction,
  displayArrow,
  testId,
}: {
  onClick: () => void;
  direction: "right" | "left";
  displayArrow: boolean;
  testId?: string;
}): React.ReactElement => {
  return (
    <DesktopOnly>
      <NavContainer align={direction}>
        <Spacing>
          {displayArrow && (
            <NavButton onClick={handleClick} data-testid={testId}>
              <Icon icon={`chevron-${direction}`} size={1} />
            </NavButton>
          )}
        </Spacing>
      </NavContainer>
    </DesktopOnly>
  );
};
