import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import Sam from "@otta/search/Images/Headshots/Sam.jpg";
import { CircularImg } from "@otta/search/components/Img";

const PersonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 12px 0 24px;
  h5 {
    margin: 0 0 0 12px;
  }
`;

const HeadshotWrapper = styled.div`
  min-width: 60px;
  width: 60px;
  height: 60px;
  margin: 0 lg;
`;

interface IPersonProps {
  text: string;
  className?: string;
}

export function Person({ text, className }: IPersonProps): React.ReactElement {
  return (
    <PersonWrapper className={className}>
      <HeadshotWrapper>
        <CircularImg src={Sam} alt={`Sam headshot`} />
      </HeadshotWrapper>
      <Text align="left">{text}</Text>
    </PersonWrapper>
  );
}
