import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { SpotlightCard } from "./components";

import { palette, pxToRem } from "@otta/design-tokens";
import { OttaIcons } from "@otta/icons";
import {
  DashboardJobFragment,
  TeamMemberFragment,
  TeamMemberRole,
} from "@otta/search/schema";
import { Text } from "@otta/design";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;
  flex-grow: 1;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: lg;

  ${up(
    "tablet",
    css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    `
  )}
`;

const MemberContainer = styled.div`
  border: 1px solid ${palette.brand.grey};
  border-radius: ${pxToRem(8)};
  padding: sm;
  gap: sm;
  height: 100%;
  display: flex;

  &[data-role=${TeamMemberRole.Report}] {
    display: none;
    ${up(
      "tablet",
      css`
        display: flex;
      `
    )}
  }
`;

const avatarStyle = css`
  flex-shrink: 0;
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 50%;
`;

const Avatar = styled(Asset)`
  ${avatarStyle};
  overflow: hidden;
`;

const PlaceholderAvatar = styled.div`
  flex-shrink: 0;
  position: relative;
  width: ${pxToRem(32)};
  height: ${pxToRem(32)};
  border-radius: 50%;
  background-color: ${palette.brand.yellow};
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: ${palette.extended.yellow.shade100};
  }

  &::before {
    top: ${pxToRem(6)};
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
  }

  &::after {
    top: ${pxToRem(24)};
    width: ${pxToRem(38)};
    height: ${pxToRem(38)};
  }

  &[data-role=${TeamMemberRole.Manager}] {
    background-color: ${palette.brand.green};
    &::before,
    &::after {
      background-color: ${palette.extended.green.shade100};
    }
  }
  &[data-role=${TeamMemberRole.Report}] {
    background-color: ${palette.brand.orange};
    &::before,
    &::after {
      background-color: ${palette.extended.orange.shade100};
    }
  }
`;

const RoleTag = styled.div`
  border-radius: ${pxToRem(2)};
  padding: xxs 6;
  width: fit-content;
  &[data-role=${TeamMemberRole.Manager}] {
    background-color: ${palette.brand.green};
  }
  &[data-role=${TeamMemberRole.Report}] {
    background-color: ${palette.extended.orange.shade200};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: xs;
`;

const DesktopContent = styled.div`
  display: none;
  ${up(
    "tablet",
    css`
      display: unset;
    `
  )}
`;

const MobileContent = styled.div`
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const MoreText = styled(Text).attrs({ size: -1 })`
  flex-grow: 1;
  text-align: center;
  align-self: center;
`;

export function SimpleOrgChart({
  size,
  members,
}: {
  size?: number;
  members: DashboardJobFragment["teamMembers"];
}) {
  const spotlightTag = {
    icon: OttaIcons["HandWave"],
    text: "Meet the team",
    color: palette.brand.pink,
  };

  const { manager, reports } = useMemo(() => {
    const manager = members.find(
      member => member.role === TeamMemberRole.Manager
    );
    const reports = members.filter(
      member => member.role === TeamMemberRole.Report
    );
    return { manager, reports };
  }, [members]);

  const desktopMembersCardCount = useMemo(
    () => 1 + Math.min(reports.length, 2),
    [reports.length]
  );

  return (
    <SpotlightCard name="org-chart" tag={spotlightTag}>
      <Container>
        <Text bold>Your team</Text>
        <Grid>
          {manager && (
            <Member
              key="manager-card"
              role={TeamMemberRole.Manager}
              member={manager.member}
            />
          )}
          {reports?.slice(0, 2)?.map(({ member }, index) => (
            <Member
              key={`report-card-${index}`}
              role={TeamMemberRole.Report}
              member={member}
            />
          ))}
          <MobileContent>
            {size && size > 1 && (
              <MoreText>
                {`+ ${size - 1} more ${size > 2 ? "people" : "person"}`}
              </MoreText>
            )}
          </MobileContent>
          <DesktopContent>
            {size && size > 3 && (
              <MemberContainer
                style={{ background: palette.grayscale.shade50 }}
              >
                <MoreText bold>
                  {`+ ${size - desktopMembersCardCount} more ${
                    size - desktopMembersCardCount > 1 ? "people" : "person"
                  }`}
                </MoreText>
              </MemberContainer>
            )}
          </DesktopContent>
        </Grid>
      </Container>
    </SpotlightCard>
  );
}

function Member({
  role,
  member,
}: {
  role: TeamMemberRole;
  member: TeamMemberFragment;
}) {
  return (
    <MemberContainer data-role={role}>
      <Column>
        {member.avatarLinks?.[0] ? (
          <Avatar asset={member.avatarLinks[0]} />
        ) : (
          <PlaceholderAvatar data-role={role} />
        )}
      </Column>
      <Column>
        <RoleTag data-role={role}>
          <Text bold size={-1}>
            {role === TeamMemberRole.Manager ? "Manager" : "Direct report"}
          </Text>
        </RoleTag>
        <Text bold size={-1}>
          {member.name}
        </Text>
        <Text size={-1} color={palette.grayscale.shade600}>
          {member.jobTitle}
        </Text>
      </Column>
    </MemberContainer>
  );
}
