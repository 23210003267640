import { useOutletContext } from "react-router-dom";
import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import { DesktopContentProps } from "./DesktopContent";

import { CompanyContent } from "@otta/search/pages/Companies/components/CompanyContent";
import { Loading } from "@otta/shared-components";
import { pxToRem } from "@otta/design-tokens";

const CompanyContentContainer = styled.div`
  max-width: 100%;
  width: ${pxToRem(1024)};
`;

export function CompanyProfile() {
  const data = useOutletContext<DesktopContentProps | undefined>();

  const userVisaRequirements = useMemo(
    () => data?.user?.visaRequirementCountries?.map(l => l.location) ?? [],
    [data?.user?.visaRequirementCountries]
  );

  return data ? (
    <CompanyContentContainer>
      <CompanyContent
        company={data.job.company}
        userVisaRequirements={userVisaRequirements}
      />
    </CompanyContentContainer>
  ) : (
    <Loading />
  );
}
