import styled, { css, up } from "@xstyled/styled-components";

import { Text, Spacing, responsive } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { JobPreviewCard } from "@otta/search/components/JobPreviewCard";

const SectionContainer = styled.div`
  min-height: 900px;
  overflow-anchor: none;
  ${up(
    "tablet",
    css`
      min-height: unset;
    `
  )}
`;

export const SectionTitle = styled(Text)`
  ${responsive`
    font-size: ${responsive.modularScale({ mobile: 1, desktop: 2 })} !important;
  `}
`;

const DesktopOnly = styled.div`
  display: none;
  ${up(
    "tablet",
    css`
      display: block;
      position: sticky;
      top: 0;
      z-index: 2;
    `
  )}
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: lg;
  row-gap: md;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
    row-gap: lg;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SpotlightCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${palette.grayscale.shade400};
  width: 40px;
  display: none;
  ${up(
    "tablet",
    css`
      display: block;
    `
  )}
`;

interface ISectionProps {
  title: string;
}

export function Section({
  title,
  children,
}: React.PropsWithChildren<ISectionProps>): React.ReactElement {
  return (
    <SectionContainer data-testid="shortlist-section">
      <Spacing>
        <Spacing size={-2}>
          <DesktopOnly>
            <SectionTitle
              as="h3"
              id={`${title.toLowerCase()}-section-id`}
              bold
              data-testid="section-title"
            >
              {title}
            </SectionTitle>
          </DesktopOnly>
          <Separator />
        </Spacing>
        {children}
      </Spacing>
    </SectionContainer>
  );
}

export const JobLink = styled(Link)`
  -webkit-tap-highlight-color: transparent;
`;

export const ActionButtonWrapper = styled.div`
  padding: 0 4px;
  > div,
  button {
    width: 100%;
  }
  flex: 1 1 100%;
`;

interface ISectionCardProps {
  title: string;
  subtitle: string | null;
  company: {
    name: string;
    primaryColour: string | null;
    faviconPath: string | null;
    ottaCertified: boolean;
  };
}

export function SectionCard({
  title,
  subtitle,
  company,
  children,
}: React.PropsWithChildren<ISectionCardProps>): React.ReactElement {
  return (
    <JobPreviewCard accent title={title} subtitle={subtitle} company={company}>
      {children}
    </JobPreviewCard>
  );
}
