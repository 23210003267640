import { useState, useEffect, useRef } from "react";
import { Form as FinalForm } from "react-final-form";
import styled from "@xstyled/styled-components";
import deepEqual from "deep-equal";

import { Silhouette, CircleAddButton } from "./FormCardList";

import { palette, pxToRem } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Spacing, Button, Text } from "@otta/design";
import { Pencil } from "@otta/search/components/Icons/Pencil";
import { Delete as Cancel } from "@otta/search/components/Icons/Delete";
import {
  Card,
  BottomSection,
  EditButton,
} from "@otta/search/pages/Profile/components/FormCard";
import { SectionHeader } from "@otta/search/pages/Profile/components/FormHeader";
import { LoadingCard } from "@otta/search/pages/Profile/components/LoadingCard";

const RowWrapper = styled.div<{ centerAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: ${({ centerAlign }) => (centerAlign ? "center" : "flex-start")};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
`;

const CenteredButtonWrapper = styled.div`
  display: flex;
  justify-content; center;
  height: ${pxToRem(35)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const GreyText = styled(Text)`
  color: ${palette.grayscale.shade600};
`;

interface SingleInputFormCardProps<QueryData, InitialValues, EditedValues> {
  title: string;
  addMoreText: string;
  introText: string;
  data: QueryData | null;
  hasNoData: boolean;
  formattedData: InitialValues;
  displayComponent: React.ComponentType<{
    data: QueryData;
    onClick: () => void;
  }>;
  editingComponent: React.ComponentType;
  onSubmit: (values: EditedValues) => Promise<void>;
}

export function SingleInputFormCard<QueryData, InitialValues, EditedValues>({
  title,
  addMoreText,
  introText,
  data,
  hasNoData,
  formattedData,
  displayComponent: DisplayComponent,
  editingComponent: EditingComponent,
  onSubmit,
}: SingleInputFormCardProps<
  QueryData,
  InitialValues,
  EditedValues
>): React.ReactElement {
  const [editing, setEditing] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (!data) {
    return <LoadingCard />;
  }

  const clickable = !editing;

  const handleFormSubmit = async (values: EditedValues) => {
    setSubmitLoading(true);
    await onSubmit(values);

    setEditing(false);
    setSubmitLoading(false);
  };

  const toggleEditState = () => setEditing(prevState => !prevState);

  return (
    <Spacing>
      <HeaderWrapper>
        <SectionHeader>{title}</SectionHeader>
        <CircleAddButton
          data-testid="add-button"
          onClick={toggleEditState}
          hide={editing}
        >
          <Icon icon="plus" />
        </CircleAddButton>
      </HeaderWrapper>
      {!editing ? (
        hasNoData ? (
          <Silhouette onClick={toggleEditState}>
            <Spacing>
              <Text align="center" data-testid="empty-text">
                {introText}
              </Text>
              <CenteredButtonWrapper onClick={toggleEditState}>
                <Button level="secondary" onClick={toggleEditState}>
                  {`Add ${addMoreText}`}
                </Button>
              </CenteredButtonWrapper>
            </Spacing>
          </Silhouette>
        ) : (
          <Card
            clickable={clickable}
            onClick={clickable ? () => setEditing(true) : undefined}
          >
            <RowWrapper centerAlign={false}>
              <DisplayComponent data={data} onClick={toggleEditState} />
              <EditButton
                data-testid="edit-button"
                id="edit-button"
                type="button"
                onClick={toggleEditState}
              >
                <Pencil />
              </EditButton>
            </RowWrapper>
          </Card>
        )
      ) : (
        <FinalForm
          initialValues={formattedData}
          initialValuesEqual={(oldState, newState) =>
            deepEqual(oldState, newState)
          }
          onSubmit={handleFormSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} data-testid={`${title}-form-card`}>
              <Card
                clickable={clickable}
                onClick={clickable ? () => setEditing(true) : undefined}
              >
                <Spacing>
                  <RowWrapper centerAlign={true}>
                    <GreyText>{introText}</GreyText>
                    <ButtonWrapper>
                      <EditButton
                        data-testid="edit-button"
                        type="button"
                        onClick={toggleEditState}
                      >
                        <Cancel />
                      </EditButton>
                    </ButtonWrapper>
                  </RowWrapper>
                  <EditingComponent />
                  <BottomSection>
                    <Button
                      level="primary"
                      type="submit"
                      disabled={submitLoading}
                    >
                      {submitLoading ? "Saving..." : "Save"}
                    </Button>
                  </BottomSection>
                </Spacing>
              </Card>
            </form>
          )}
        />
      )}
    </Spacing>
  );
}
