import styled, { useUp } from "@xstyled/styled-components";
import Player from "@vimeo/player";
import { useEffect, useId, useMemo, useRef } from "react";

import { EBFeedback } from "../EBFeedback";

import {
  BrandAssetOrientation,
  BrandAssetType,
  CompanyProfileLinkFragment,
} from "@otta/search/schema";
import { theme } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useEBTracking } from "@otta/search/contexts/EBTracking";
import { borderRadiusSmall, pxToRem } from "@otta/design-tokens";

const Picture = styled.picture`
  display: flex;
`;

const Image = styled.img`
  object-fit: cover;
  max-width: 100%;
  flex: 1 1 0;
`;

const VideoWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;

  &[data-orientation=${BrandAssetOrientation.Portrait}] {
    @media (min-width: 431px) {
      display: flex;
      justify-content: center;
      height: ${pxToRem(540)};
    }

    @media (min-width: tablet) {
      height: ${pxToRem(390)};
    }
  }
`;

const VideoContainer = styled.div`
  border-radius: ${borderRadiusSmall};
  overflow: hidden;

  &[data-orientation=${BrandAssetOrientation.Portrait}] {
    aspect-ratio: 9/16;
  }
`;

function Video({
  source,
  className,
  "data-testid": testId,
  videoCategory,
  mobileOrientation,
  desktopOrientation,
}: {
  source: string;
  className?: string;
  "data-testid"?: string;
  videoCategory: string | null;
  mobileOrientation: BrandAssetOrientation;
  desktopOrientation: BrandAssetOrientation;
}): React.ReactElement {
  const id = useId();
  const trackingData = useEBTracking();
  const isTablet = useUp("tablet");

  const orientation = isTablet ? desktopOrientation : mobileOrientation;

  // @vimeo/player returns only promises
  // We can't use them sync in the useEffect
  const videoDurationRef = useRef<number>(0);
  const videoCurrentTimeRef = useRef<number>(0);

  const formattedTrackingData = useMemo(
    () => ({
      videoId: source,
      videoCategory: videoCategory,
      companyId: trackingData.companyId,
      jobId: trackingData.jobId,
      jobFunction: trackingData.jobFunction,
      jobSubFunction: trackingData.jobSubFunction,
      jobValueClassification: trackingData.jobValueClassification,
      minYearsExperienceRequired: trackingData.minYearsExperienceRequired,
      maxYearsExperience: trackingData.maxYearsExperienceRequired,
      applyViaOtta: trackingData.applyViaOtta,
      loginStatus: trackingData.loginStatus,
    }),
    [
      source,
      videoCategory,
      trackingData.applyViaOtta,
      trackingData.companyId,
      trackingData.jobFunction,
      trackingData.jobId,
      trackingData.jobSubFunction,
      trackingData.jobValueClassification,
      trackingData.loginStatus,
      trackingData.maxYearsExperienceRequired,
      trackingData.minYearsExperienceRequired,
    ]
  );

  useEffect(() => {
    const parsedSource = parseInt(source);
    const options = !isNaN(parsedSource)
      ? { id: parsedSource }
      : { url: source };

    const player = new Player(id, {
      responsive: true,
      ...options,
    });

    player.getTextTracks().then(tracks => {
      const firstLanguage = tracks[0]?.language;
      if (firstLanguage) {
        player.enableTextTrack(firstLanguage);
      }
    });

    player.on("timeupdate", eventData => {
      videoCurrentTimeRef.current = eventData.seconds;
    });

    player.on("play", eventData => {
      videoDurationRef.current = eventData.duration;

      pushAnalyticsEvent({
        eventName: "Candidate Played Video",
        videoDuration: eventData.duration,
        videoCurrentPercent: eventData.percent,
        videoCurrentTime: eventData.seconds,
        ...formattedTrackingData,
      });
    });

    player.on("pause", eventData => {
      pushAnalyticsEvent({
        eventName: "Candidate Paused Video",
        videoDuration: eventData.duration,
        videoCurrentPercent: eventData.percent,
        videoCurrentTime: eventData.seconds,
        ...formattedTrackingData,
      });
    });

    player.on("playbackratechange", eventData => {
      pushAnalyticsEvent({
        eventName: "Candidate Playback Rate Changed",
        playbackRate: eventData.playbackRate,
        ...formattedTrackingData,
      });
    });

    player.on("seeked", eventData => {
      pushAnalyticsEvent({
        eventName: "Candidate Seeked Video",
        videoDuration: eventData.duration,
        videoCurrentPercent: eventData.percent,
        videoCurrentTime: eventData.seconds,
        ...formattedTrackingData,
      });
    });

    player.on("ended", eventData => {
      pushAnalyticsEvent({
        eventName: "Candidate Ended Video",
        videoDuration: eventData.duration,
        videoCurrentPercent: eventData.percent,
        videoCurrentTime: eventData.seconds,
        ...formattedTrackingData,
      });
    });

    return () => {
      player.off("play");
      player.off("pause");
      player.off("playbackratechange");
      player.off("seeked");
      player.off("ended");

      // Destroy seems to disable event callbacks (?)
      // player.destroy();
    };
  }, [id, source, formattedTrackingData]);

  useEffect(() => {
    const trackEndedVideo = () => {
      window.removeEventListener("beforeunload", trackEndedVideo);

      const videoDuration = parseFloat(videoDurationRef.current.toFixed(3));
      const videoCurrentTime = parseFloat(
        videoCurrentTimeRef.current.toFixed(3)
      );

      if (!videoDuration) return;

      const videoCurrentPercent = parseFloat(
        (videoCurrentTime / videoDuration).toFixed(3)
      );

      pushAnalyticsEvent({
        eventName: "Candidate Ended Video",
        videoDuration,
        videoCurrentPercent,
        videoCurrentTime,
        ...formattedTrackingData,
      });
    };

    window.addEventListener("beforeunload", trackEndedVideo);

    return trackEndedVideo;
  }, [formattedTrackingData]);

  return (
    <VideoWrapper data-orientation={orientation}>
      <VideoContainer
        id={id}
        className={className}
        data-testid={testId}
        data-orientation={orientation}
      />
    </VideoWrapper>
  );
}

export function Asset({
  className,
  asset: ba,
  "data-testid": testId,
}: {
  className?: string;
  asset: CompanyProfileLinkFragment;
  "data-testid"?: string;
}): React.ReactElement | null {
  if (ba.companyBrandAsset.type === BrandAssetType.Vimeo) {
    return (
      <>
        <Video
          source={ba.companyBrandAsset.source}
          videoCategory={ba.companyBrandAsset.category}
          mobileOrientation={
            ba.mobilePlacement?.orientation ?? BrandAssetOrientation.Landscape
          }
          desktopOrientation={
            ba.desktopPlacement?.orientation ?? BrandAssetOrientation.Landscape
          }
          className={className}
          data-testid={testId}
        />
        <EBFeedback
          videoId={ba.companyBrandAsset.id}
          videoCategory={ba.companyBrandAsset.category}
        />
      </>
    );
  }

  return ba.mobilePlacement ? (
    <Picture
      key={ba.id}
      className={className}
      data-testid={testId}
      data-desktop-width={ba.desktopPlacement?.width} // used in meet cards
    >
      {!!ba.desktopPlacement && (
        <source
          media={`(min-width: ${theme.screens.tablet}px)`}
          srcSet={ba.desktopPlacement?.sourceSet ?? undefined}
          src={ba.desktopPlacement?.source ?? undefined}
        />
      )}

      <Image
        srcSet={ba.mobilePlacement.sourceSet ?? undefined}
        src={ba.mobilePlacement.source ?? undefined}
        alt={ba.companyBrandAsset.alt ?? undefined}
      />
    </Picture>
  ) : null;
}
