import styled from "styled-components";

import { Transcript } from "./Transcript";
import { Audio } from "./Audio";
import { CompanyPodcastData } from "./types";
import { Quote } from "./Quote";

import { pxToRem } from "@otta/design-tokens";

const CompanyPodcastContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
`;
export function CompanyPodcast({
  companyPodcastData,
}: {
  companyPodcastData: CompanyPodcastData;
}) {
  return (
    <CompanyPodcastContainer>
      <Quote quote={companyPodcastData.quote} user={companyPodcastData.user} />
      <Audio audio={companyPodcastData.audio} />
      <Transcript transcript={companyPodcastData.transcript} />
    </CompanyPodcastContainer>
  );
}
