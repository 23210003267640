import styled, { css, down } from "@xstyled/styled-components";

import { Card } from "@otta/design";
import { CompanyMission as CompanyMissionContent } from "@otta/search/components/CompanyMission";
import { OttaCertifiedHeader } from "@otta/search/components/CompanyMission/OttaCertifiedHeader";
import { Company } from "@otta/search/schema";

const Wrapper = styled(Card)`
  padding: 0;

  ${down(
    "tablet",
    css`
      border-radius: 0;
    `
  )}
`;

export const CompanyMission = ({ company }: { company: Company.Company }) => {
  if (!company.mission) {
    return null;
  }

  return (
    <Wrapper>
      {company.ottaCertified && <OttaCertifiedHeader />}
      <Card>
        <CompanyMissionContent company={company} />
      </Card>
    </Wrapper>
  );
};
