import styled, { css, up } from "@xstyled/styled-components";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { differenceInDays, differenceInHours, parseISO } from "date-fns";

import { Text, Tooltip } from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";
import { Company, Job } from "@otta/search/schema";
import { OttaCertified } from "@otta/search/components/Icons/OttaCertified";

const StatusTagsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  ${up(
    "tablet",
    css`
      gap: 1rem;
      flex-direction: row-reverse;
      justify-content: flex-end;
    `
  )}

  @media (max-width: ${pxToRem(903)}) {
    flex-direction: column;
  }
`;
const JobStatusPill = styled(motion.div)<{ $live?: boolean }>`
  height: fit-content;
  width: fit-content;
  gap: 7;
  display: flex;
  border-radius: 0.5rem;
  background: ${({ $live }) =>
    $live ? palette.brand.green : palette.extended.red.shade200};
  padding: 5 sm;
`;

const CertifiedPill = styled(motion.div)<{
  open?: boolean;
}>`
  display: flex;
  border-radius: ${({ open }) => (open ? "0.5rem 0.5rem 0 0 " : "0.5rem")};

  background: ${palette.brand.yellow};
  padding: 5 sm;
  cursor: pointer;
`;
const CertifiedIconWrapper = styled.div`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  margin-right: 10;
`;

const CertifiedWrapper = styled.div`
  max-width: fit-content;
  align-items: center;
`;

const CertifiedInfo = styled(motion.div)`
  width: fit-content;
  padding: 5 sm;

  background: ${palette.extended.yellow.shade100};
  border-radius: 0 0 0.5rem 0.5rem;
`;

const CertifiedDescription = styled(motion.div)`
  padding-bottom: sm;
  font-size: ${pxToRem(13)};
`;

const CertifiedPoints = styled(motion.div)`
  font-size: ${pxToRem(13)};
  display: inline-flex;
  gap: 10;
`;

interface StatusTagsProps {
  job: Pick<Job.Fragment, "live" | "insertedAt" | "lastSeen" | "originalUrl">;
  company: Pick<Company.Fragment, "ottaCertified" | "onlyOnOtta">;
}

export function StatusTags({
  job: { live, insertedAt, lastSeen, originalUrl },
  company: { ottaCertified, onlyOnOtta },
}: StatusTagsProps): React.ReactElement {
  return (
    <StatusTagsWrapper>
      {ottaCertified && <CertifiedTag />}
      <ApplicationStatus
        live={live}
        insertedAt={insertedAt}
        lastSeen={lastSeen}
        originalUrl={originalUrl}
        onlyOnOtta={onlyOnOtta}
      />
    </StatusTagsWrapper>
  );
}

function ApplicationStatus({
  live,
  insertedAt,
  lastSeen,
  originalUrl,
  onlyOnOtta,
}: {
  live: boolean;
  insertedAt: string;
  lastSeen: string | null;
  originalUrl: string | null;
  onlyOnOtta: boolean;
}): React.ReactElement {
  const now = new Date();
  const postedAt = parseISO(insertedAt);
  const lastChecked = lastSeen ? parseISO(lastSeen) : now;
  const daysSincePosted = differenceInDays(now, postedAt);
  const hoursSinceChecked = differenceInHours(now, lastChecked);
  if (!live) {
    return (
      <JobStatusPill $live={false}>
        <Icon icon="circle-close" size={1} />
        <Text bold>Job no longer available</Text>
      </JobStatusPill>
    );
  }

  if (!originalUrl) {
    return (
      <JobStatusPill $live={live}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </JobStatusPill>
    );
  }

  const content = onlyOnOtta
    ? `This job is checked every 24 hours.`
    : `We check jobs are live every few hours. This job was checked ${
        hoursSinceChecked < 1
          ? "less than 1 hour ago."
          : hoursSinceChecked === 1
          ? "1 hour ago."
          : `${hoursSinceChecked} hours ago.`
      }`;

  return (
    <Tooltip placement="bottom" content={content}>
      <JobStatusPill $live={live}>
        {daysSincePosted <= 1 ? (
          <Icon icon="new-to-otta" size={1} />
        ) : (
          <Icon icon="clock" size={1} />
        )}
        <Text bold>
          {daysSincePosted <= 1
            ? "Posted today"
            : daysSincePosted < 28
            ? "Be an early applicant"
            : "Open for applications"}
        </Text>
      </JobStatusPill>
    </Tooltip>
  );
}

function CertifiedTag(): React.ReactElement {
  const [open, setOpen] = useState(false);

  return (
    <CertifiedWrapper onClick={() => setOpen(prevState => !prevState)}>
      <AnimatePresence>
        <CertifiedPill open={open}>
          <CertifiedIconWrapper>
            <OttaCertified />
          </CertifiedIconWrapper>
          <Text bold>Otta Certified</Text>
        </CertifiedPill>
        {open && (
          <CertifiedInfo
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CertifiedDescription>
              Otta Certified companies put candidates first by:
            </CertifiedDescription>
            <CertifiedPoints>
              <Icon icon="salary" size={0.5} /> Adding public salaries to jobs
            </CertifiedPoints>
            <CertifiedPoints>
              <Icon icon="response-rate" size={0.5} /> Responding to
              applications quickly
            </CertifiedPoints>
          </CertifiedInfo>
        )}
      </AnimatePresence>
    </CertifiedWrapper>
  );
}
