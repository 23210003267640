import { useEffect, useMemo, useRef, useState } from "react";
import styled from "@xstyled/styled-components";

import {
  ApplicationTipsTab,
  CompanyTab,
  RoleTab,
  MeetTheTeamTab,
} from "./PreviewSidebar";

import { ApplicationProps, ApplicationView } from "./";

import { palette, pxToRem } from "@otta/design-tokens";
import { Tabs } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Loading } from "@otta/search/components/Loading";

const TAB_HEIGHT = pxToRem(48);

const TabListWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  height: ${TAB_HEIGHT};
  border-top: 1px solid ${palette.brand.grey};
  background-color: ${palette.brand.white};
`;

const JobTabListWrapper = styled(TabListWrapper)`
  top: ${TAB_HEIGHT};
  border-bottom: 1px solid ${palette.brand.grey};
`;

const TabPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: xl;
  padding: lg sm;
  overflow-y: auto;
`;

type Tab = "application" | "job";

export function MobileApplication({
  job,
  jobId,
  analyticsData,
  shouldShowJobDetails,
}: ApplicationProps) {
  const [currentTab, setCurrentTab] = useState<Tab>("application");
  useEffect(() => {
    if (shouldShowJobDetails) {
      setCurrentTab("job");
    }
  }, [shouldShowJobDetails]);
  const scrollOffsets = useRef({ application: 0, job: 0 });

  const onClickTab = (newTab: string) => {
    scrollOffsets.current[currentTab] = window.scrollY;
    setCurrentTab(newTab as Tab);

    const eventName =
      newTab === "job" ? "show-job-details" : "close-job-details";
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: eventName,
      page: "apply-via-otta-application",
      ...analyticsData,
    });
  };

  useEffect(() => {
    window.scrollTo(0, scrollOffsets.current[currentTab]);
  }, [currentTab]);

  const tabs = useMemo(() => {
    const onClickJobTab = (tab: string) => {
      pushAnalyticsEvent({
        eventName: "Candidate Clicked",
        name: tab,
        page: "apply-via-otta-application",
        ...analyticsData,
      });
    };

    return [
      {
        id: "application",
        title: "Application",
        content: (
          <ApplicationView jobId={jobId} analyticsData={analyticsData} />
        ),
      },
      {
        id: "job",
        title: "Job",
        content: (
          <Tabs
            tabs={[
              {
                id: "role",
                title: "Role",
                content: job ? (
                  <TabPanelWrapper>
                    <RoleTab job={job} />
                  </TabPanelWrapper>
                ) : (
                  <Loading />
                ),
              },
              {
                id: "company",
                title: "Company",
                content: job ? (
                  <TabPanelWrapper>
                    <CompanyTab job={job} company={job.company} />
                  </TabPanelWrapper>
                ) : (
                  <Loading />
                ),
              },
              {
                id: "application-tips",
                title: "Application tips",
                content: job ? (
                  <TabPanelWrapper>
                    <ApplicationTipsTab />
                  </TabPanelWrapper>
                ) : (
                  <Loading />
                ),
              },
              ...(job?.team
                ? [
                    {
                      id: "team",
                      title: "Meet the team",
                      content: (
                        <TabPanelWrapper>
                          <MeetTheTeamTab
                            team={job.team}
                            jobTitle={job.title}
                            members={job.teamMembers}
                          />
                        </TabPanelWrapper>
                      ),
                    },
                  ]
                : []),
            ]}
            onChange={onClickJobTab}
          >
            <JobTabListWrapper>
              <Tabs.List />
            </JobTabListWrapper>
            <Tabs.Panels />
          </Tabs>
        ),
      },
    ];
  }, [jobId, job, analyticsData]);

  return (
    <Tabs tabs={tabs} currentTab={currentTab} onChange={onClickTab}>
      <TabListWrapper>
        <Tabs.List />
      </TabListWrapper>
      <Tabs.Panels />
    </Tabs>
  );
}
