import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { EmployeeSurveySignUpForm } from "./EmployeeSurveySignUpPage/EmployeeSurveySignUpForm";
import { LeftContent, RightContent } from "./EmployeeSurveySignUpPage";

import { Heading, Logo, Middle, Spacing, Text, List } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  EmployeeSurveyCompanyDocument,
  CompaniesDocument,
} from "@otta/search/schema";
import { Select } from "@otta/search/components/Input/Select";
import { ImageBubble } from "@otta/search/components/ImageBubble";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";

function CompanySelect({
  setCompanyId,
}: {
  setCompanyId: (x: string | null) => void;
}) {
  const { data } = useQuery(CompaniesDocument);
  const companies = (data?.companies ?? []).map(({ urlSafeName, name }) => ({
    label: name,
    value: urlSafeName,
  }));
  return (
    <Middle maxWidth={300} data-testid="company-select">
      <Spacing>
        <Text as="h3" bold size={0} align="center">
          Which company do you work for?
        </Text>
        <Select
          placeholder="Type or select..."
          options={companies}
          onChange={option => setCompanyId(option?.value ?? null)}
        />
      </Spacing>
    </Middle>
  );
}

function SignupWrapper({
  companyId,
}: {
  companyId: string;
}): React.ReactElement {
  const { data: companyData } = useQuery(EmployeeSurveyCompanyDocument, {
    variables: { id: companyId },
  });

  const company = companyData?.company;

  if (!company) {
    return <Loading />;
  }

  return <EmployeeSurveySignUpForm company={company} />;
}

export default function EmployeeSurveyLandingPage(): React.ReactElement {
  const location = useLocation();
  const [companyId, setCompanyId] = useState<string | null>(null);

  const { data } = useQuery(CompaniesDocument, {
    variables: {
      urlSafeNames: ["Google", "Deliveroo", "Spotify", "Monzo", "Airbnb"],
    },
  });
  const companies = data?.companies ?? [];

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Employee Viewed",
      pathname: location.pathname,
    });
  }, [location.pathname]);

  return (
    <div>
      <Helmet>
        <title>Leave an endorsement</title>
      </Helmet>
      <LeftContent>
        <Middle maxWidth={490}>
          <Spacing size={4}>
            <Middle maxWidth={100}>
              <Logo />
            </Middle>
            <Heading bold size={6} as="h2">
              Leave an endorsement for your company
            </Heading>
            <ImageBubble
              small
              images={companies
                .filter(({ faviconPath }) => !!faviconPath)
                .map(({ faviconPath }) => faviconPath as string)}
            />
            <List type="positive">
              <li>
                Endorsements are displayed on job descriptions to help job
                seekers understand what it&apos;s like to work at your company
              </li>
              <li>
                <TooltipWithIcon content="We believe Glassdoor offers a good space to voice concerns about your company publicly. When doing our research, we heard that job seekers were missing deeper insight on what makes a company great. This is what endorsements are solving.">
                  Unlike Glassdoor, all questions are focused on what makes a
                  company great
                </TooltipWithIcon>
              </li>
              <li>
                Your response will be anonymous and only include the team you
                work in
              </li>
            </List>
          </Spacing>
        </Middle>
      </LeftContent>
      <RightContent>
        {!companyId && <CompanySelect setCompanyId={setCompanyId} />}
        {companyId && <SignupWrapper companyId={companyId} />}
      </RightContent>
    </div>
  );
}
