import { DocumentNode } from "@apollo/client";
import { useState, useRef } from "react";
import styled from "@xstyled/styled-components";
import { FormApi } from "final-form";

import { CompletionStatusTag } from "./CompletionStatusTags";
import { FormCard, CircleButton } from "./FormCard";
import { SectionHeader } from "./FormHeader";
import { LoadingCard } from "./LoadingCard";

import {
  palette,
  modularScale,
  borderRadius,
  pxToRem,
} from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Spacing, Text, Middle, Button, Tipbox } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Plus } from "@otta/search/components/Icons/Plus";

const AddButton = styled(Button)`
  height: ${pxToRem(35)};
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CircleAddButton = styled(CircleButton)<{ hide?: boolean }>`
  -webkit-tap-highlight-color: transparent;
  visibility: ${({ hide }) => (hide ? "hidden" : "visible")};
  &:hover {
    background: ${palette.brand.white};
  }
  svg {
    height: ${modularScale()};
    stroke: ${palette.brand.black};
  }
`;

export const Silhouette = styled.div`
  min-height: ${pxToRem(160)};
  box-shadow: unset;
  background: rgba(255, 255, 255, 0.4);
  border-radius: ${pxToRem(borderRadius)};
  padding: lg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: md 0;
  width: 100%;
  color: ${palette.brand.black};
  cursor: pointer;
  &:hover {
    border-color: ${palette.grayscale.shade400};
    svg {
      transform: scale(1.03);
    }
  }
  transition: default;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10;
`;

const AddMoreButton = styled(Button)`
  height: ${pxToRem(35)};
`;

const PlusIcon = styled(Plus)`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  padding-right: sm;
`;
interface IFormCardProps<
  QueryData,
  QuestionData,
  Item,
  IncomingData,
  OutgoingData
> {
  title: string;
  introText: string;
  query: DocumentNode;
  formatQueryData: (data: QueryData) => Item[];
  formatIncomingData: (item: Item) => IncomingData;
  formatOutgoingData: (
    item: IncomingData
  ) => OutgoingData | Promise<OutgoingData>;
  formatTitle: (item: Item) => React.ReactNode;
  formatDates?: (item: Item) => React.ReactNode;
  onCreate: (values: OutgoingData) => void;
  onUpdate: (id: string) => (values: OutgoingData) => void;
  onDelete: (id: string) => () => void;
  editingComponent: React.ComponentType<{
    form: FormApi<IncomingData>;
    data: Item | null;
    isCreating?: boolean;
    questionData?: QuestionData;
  }>;
  displayComponent: React.ComponentType<{ data: Item }>;
  tipText?: string;
  tipLimit?: number;
  limit?: number;
  recommended: boolean;
  formatCompletionStatus?: (data: QueryData) => string;
  showTooltip?: boolean | undefined;
  tooltipContent?: string | undefined;
}

export function FormCardList<
  QueryData,
  QuestionData,
  IncomingData,
  OutgoingData,
  Item extends { id: string }
>({
  title,
  introText,
  query,
  formatQueryData,
  formatIncomingData,
  formatOutgoingData,
  formatTitle,
  formatDates,
  onCreate,
  onUpdate,
  onDelete,
  editingComponent,
  displayComponent,
  tipText,
  tipLimit,
  limit,
  recommended,
  formatCompletionStatus,
  showTooltip,
  tooltipContent,
}: IFormCardProps<
  QueryData,
  QuestionData,
  Item,
  IncomingData,
  OutgoingData
>): React.ReactElement {
  const [creating, setCreating] = useState(false);
  const { data } = useQuery<QueryData>(query);
  const topOfFormCardRef = useRef<HTMLDivElement>(null);

  if (!data) {
    return (
      <Spacing>
        <HeaderWrapper>
          <SectionHeader>{title}</SectionHeader>
        </HeaderWrapper>
        <LoadingCard />
      </Spacing>
    );
  }

  const items = formatQueryData(data) ?? [];
  const empty = items.length === 0;
  const limitReached = limit ? items.length >= limit : false;
  const buttonText =
    title === "Questions about you"
      ? `Answer ${title.toLowerCase()}`
      : `Add ${title.toLowerCase()}`;

  const handleAddMoreClick = () => {
    setCreating(true);
    // Scroll into view if necessary
    if (
      topOfFormCardRef.current &&
      // Check header is in the viewport
      topOfFormCardRef.current.getBoundingClientRect().y < 0
    ) {
      topOfFormCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleCreatingState = () => setCreating(prevState => !prevState);

  return (
    <Spacing>
      <HeaderWrapper ref={topOfFormCardRef}>
        <TitleWrapper>
          <SectionHeader>{title}</SectionHeader>
          {!!formatCompletionStatus && (
            <CompletionStatusTag
              status={formatCompletionStatus(data)}
              showTooltip={showTooltip}
              tooltipContent={tooltipContent}
            />
          )}
        </TitleWrapper>
        {!limitReached && (
          <CircleAddButton
            data-testid="add-button"
            onClick={() => setCreating(!creating)}
            hide={creating}
          >
            <Icon icon="plus" />
          </CircleAddButton>
        )}
      </HeaderWrapper>
      {tipText && tipLimit && items.length > 0 && items.length < tipLimit && (
        <Tipbox level="neutral">{tipText}</Tipbox>
      )}
      {creating && (
        <FormCard
          data={null}
          introText={introText}
          formatIncomingData={formatIncomingData}
          formatOutgoingData={formatOutgoingData}
          onSubmit={async values => {
            await onCreate(values);
            setCreating(false);
          }}
          editingComponent={editingComponent}
          displayComponent={displayComponent}
          onCancel={() => setCreating(false)}
          required={recommended}
          isCreating
        />
      )}
      {empty && !creating ? (
        <Silhouette onClick={toggleCreatingState}>
          <Spacing>
            <Text align="center" data-testid="empty-text">
              {introText}
            </Text>
            <ButtonWrapper onClick={toggleCreatingState}>
              <AddButton level="secondary" onClick={toggleCreatingState}>
                {buttonText}
              </AddButton>
            </ButtonWrapper>
          </Spacing>
        </Silhouette>
      ) : (
        items.map((item, index) => (
          <FormCard
            key={item.id}
            index={index}
            title={formatTitle(item)}
            dates={formatDates ? formatDates(item) : null}
            introText={introText}
            data={item}
            formatIncomingData={formatIncomingData}
            formatOutgoingData={formatOutgoingData}
            onSubmit={onUpdate(item.id)}
            onDelete={onDelete(item.id)}
            editingComponent={editingComponent}
            displayComponent={displayComponent}
            required={recommended}
          />
        ))
      )}
      {!empty && (
        <Middle>
          <AddMoreButton level="secondary" onClick={handleAddMoreClick}>
            <PlusIcon />
            Add more {title.toLowerCase()}
          </AddMoreButton>
        </Middle>
      )}
    </Spacing>
  );
}
