import { useCallback, useMemo } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import { ParentSize } from "@visx/responsive";
import { LinePath, Area } from "@visx/shape";
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import { curveNatural } from "@visx/curve";
import { Group } from "@visx/group";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { LegendOrdinal } from "@visx/legend";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";

import {
  palette,
  modularScale,
  borderRadius,
  pxToRem,
} from "@otta/design-tokens";
import { Text, Spacing } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { currencyPrefix } from "@otta/search/utils/currencies";
import {
  JobSubFunction,
  Location,
  SalaryTrajectoryDocument,
} from "@otta/search/schema";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";

const ChartWrapper = styled.div`
  height: 500px;
  position: relative;
`;

const ChartContainer = styled.div`
  padding: xxl 0;
  background-color: ${palette.brand.white};
  border-radius: ${pxToRem(borderRadius)};
  text-align: center;

  ${up(
    "tablet",
    css`
      padding: xxl lg;
    `
  )};
`;

const NoDataOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataTextContainer = styled.div`
  max-width: 250px;
  background-color: ${palette.brand.grey};
  padding: lg;
  border-radius: ${pxToRem(borderRadius)};
  ${up(
    "tablet",
    css`
      max-width: 300px;
    `
  )};
`;

const ResponsiveText = styled(Text)`
  font-size: ${modularScale(-1)};

  ${up(
    "tablet",
    css`
      font-size: ${modularScale()};
    `
  )}
`;

const height = 500;

const margin = 50;

const innerHeight = height - 1.5 * margin;

const tooltipBisect = (
  y: number,
  [first, ...values]: { y: number; label: string }[]
) => {
  let min = first;
  let minDistance = Math.pow(y - first.y, 2);

  for (const value of values) {
    const delta = Math.pow(y - value.y, 2);
    if (delta < minDistance) {
      minDistance = delta;
      min = value;
    }
  }

  return min;
};

const formatChartTitle = (
  selectedSubFunction: JobSubFunction,
  comparisonSubFunction: JobSubFunction | null,
  numSalaryDatapoints: number,
  comparisonNumSalaryDatapoints: number,
  loading: boolean
): string => {
  if (loading) {
    return "Loading...";
  } else if (selectedSubFunction && comparisonSubFunction) {
    return `${selectedSubFunction.value}${
      numSalaryDatapoints > 0 ? ` (${numSalaryDatapoints} datapoints)` : ""
    } and ${comparisonSubFunction.value} salaries${
      comparisonNumSalaryDatapoints > 0
        ? ` (${comparisonNumSalaryDatapoints} datapoints)`
        : ""
    }`;
  } else if (selectedSubFunction) {
    return `${selectedSubFunction.value} salaries${
      numSalaryDatapoints > 0 ? ` (${numSalaryDatapoints} datapoints)` : ""
    }`;
  } else {
    return "";
  }
};

function Chart({
  currency,
  subFunction,
  comparisonSubFunction,
  width,
  median,
  comparison,
  p10,
  p25,
  p75,
  p90,
}: {
  currency: string;
  subFunction: string;
  comparisonSubFunction: string | null;
  comparison: { label: string; value: number }[];
  median: { label: string; value: number }[];
  p10: { label: string; value: number }[];
  p25: { label: string; value: number }[];
  p75: { label: string; value: number }[];
  p90: { label: string; value: number }[];
  width: number;
}) {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{ x: number; label: string; value: number }>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const medianData = useMemo(
    () =>
      median.map(m => ({
        x: parseInt(m.label, 10),
        y: Math.round(m.value / 1000),
      })),
    [median]
  );

  const comparisonData = useMemo(
    () =>
      comparison.map(m => ({
        x: parseInt(m.label, 10),
        y: Math.round(m.value / 1000),
      })),
    [comparison]
  );

  const quartileData = useMemo(
    () =>
      medianData.map(d => ({
        x: d.x,
        y0: Math.round(
          (p25.find(p => parseInt(p.label, 10) === d.x)?.value ?? 0) / 1000
        ),
        y1: Math.round(
          (p75.find(p => parseInt(p.label, 10) === d.x)?.value ?? 0) / 1000
        ),
      })),
    [medianData, p25, p75]
  );

  const decileData = useMemo(
    () =>
      medianData.map(d => ({
        x: d.x,
        y0: Math.round(
          (p10.find(p => parseInt(p.label, 10) === d.x)?.value ?? 0) / 1000
        ),
        y1: Math.round(
          (p90.find(p => parseInt(p.label, 10) === d.x)?.value ?? 0) / 1000
        ),
      })),
    [medianData, p10, p90]
  );

  const innerWidth = width - margin * 2;

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [
          Math.min(
            ...medianData.map(d => d.x),
            ...comparisonData.map(d => d.x)
          ),
          Math.max(
            ...medianData.map(d => d.x),
            ...comparisonData.map(d => d.x)
          ),
        ],
        range: [0, innerWidth],
      }),
    [medianData, comparisonData, innerWidth]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: comparisonSubFunction
          ? [
              0,
              Math.max(
                ...medianData.map(d => d.y),
                ...comparisonData.map(d => d.y)
              ),
            ]
          : [0, Math.max(...decileData.map(d => d.y1))],
        range: [innerHeight, 0],
      }),
    [comparisonSubFunction, comparisonData, decileData, medianData]
  );

  const legendScale = useMemo(
    () =>
      scaleOrdinal({
        domain: comparisonSubFunction
          ? [subFunction, comparisonSubFunction]
          : ["Median", "25th - 75th Percentile", "10th - 90th Percentile"],
        range: comparisonSubFunction
          ? [palette.brand.blue, palette.brand.black]
          : [
              palette.brand.black,
              palette.brand.yellow,
              palette.extended.yellow.shade100,
            ],
      }),
    [comparisonSubFunction, subFunction]
  );

  const handleTooltip = useCallback(
    (
      event: React.TouchEvent<SVGPathElement> | React.MouseEvent<SVGPathElement>
    ) => {
      const { x, y } = localPoint(event) || {
        x: margin,
        y: 0,
      };

      const x0 = Math.round(xScale.invert(x - margin));
      const y0 = yScale.invert(y - margin / 2);

      const decile = decileData.find(d => d.x === x0);
      const quartile = quartileData.find(d => d.x === x0);
      const median = medianData.find(d => d.x === x0);

      const yValues = [
        decile && { y: decile.y0, label: "10th Percentile" },
        quartile && { y: quartile.y0, label: "25th Percentile" },
        median && { y: median.y, label: "Median" },
        quartile && { y: quartile.y1, label: "75th Percentile" },
        decile && { y: decile.y1, label: "90th Percentile" },
      ].filter((d): d is { y: number; label: string } => Boolean(d));

      const closest = tooltipBisect(y0, yValues);

      showTooltip({
        tooltipLeft: xScale(x0) + margin,
        tooltipTop: yScale(closest.y),
        tooltipData: { label: closest.label, x: x0, value: closest.y },
      });
    },
    [decileData, medianData, quartileData, showTooltip, xScale, yScale]
  );

  return (
    <>
      <LegendOrdinal
        scale={legendScale}
        direction="row"
        shape={comparisonSubFunction ? "line" : "circle"}
      />
      <svg
        ref={containerRef}
        width={width}
        height={height}
        data-testid="salary-trajectory-chart"
      >
        <Group top={margin / 2} left={margin}>
          <AxisBottom
            top={innerHeight}
            scale={xScale}
            stroke={palette.grayscale.shade400}
            tickStroke={palette.grayscale.shade400}
            hideTicks
          />
          <AxisLeft
            scale={yScale}
            stroke={palette.grayscale.shade400}
            tickStroke={palette.grayscale.shade400}
            hideZero
            tickFormat={v => `${currencyPrefix(currency)}${v.valueOf()}k`}
          />
          {comparisonSubFunction ? (
            <>
              {comparisonData.map(d => (
                <circle
                  key={d.x}
                  cx={xScale(d.x)}
                  cy={yScale(d.y)}
                  r={4}
                  fill={palette.brand.black}
                />
              ))}
              <LinePath
                curve={curveNatural}
                data={comparisonData}
                x={d => xScale(d.x) ?? 0}
                y={d => yScale(d.y) ?? 0}
                stroke={palette.brand.black}
                shapeRendering="geometricPrecision"
              />
            </>
          ) : (
            <>
              <Area
                curve={curveNatural}
                data={decileData}
                x={d => xScale(d.x) ?? 0}
                y0={d => yScale(d.y0) ?? 0}
                y1={d => yScale(d.y1) ?? 0}
                fill={palette.extended.yellow.shade100}
                shapeRendering="geometricPrecision"
              />
              {decileData.map(d => (
                <>
                  <circle
                    key={`${d.x}-${d.y0}`}
                    cx={xScale(d.x)}
                    cy={yScale(d.y0)}
                    r={tooltipData?.x === d.x ? 6 : 0}
                    fill={palette.extended.yellow.shade100}
                    style={{ transition: "all 0.2s ease-in-out" }}
                  />
                  <circle
                    key={`${d.x}-${d.y1}`}
                    cx={xScale(d.x)}
                    cy={yScale(d.y1)}
                    r={tooltipData?.x === d.x ? 6 : 0}
                    fill={palette.extended.yellow.shade100}
                    style={{ transition: "all 0.2s ease-in-out" }}
                  />
                </>
              ))}
              <Area
                curve={curveNatural}
                data={quartileData}
                x={d => xScale(d.x) ?? 0}
                y0={d => yScale(d.y0) ?? 0}
                y1={d => yScale(d.y1) ?? 0}
                fill={palette.brand.yellow}
                shapeRendering="geometricPrecision"
              />
              {quartileData.map(d => (
                <>
                  <circle
                    key={`${d.x}-${d.y0}`}
                    cx={xScale(d.x)}
                    cy={yScale(d.y0)}
                    r={tooltipData?.x === d.x ? 6 : 0}
                    fill={palette.brand.yellow}
                    style={{ transition: "all 0.2s ease-in-out" }}
                  />
                  <circle
                    key={`${d.x}-${d.y1}`}
                    cx={xScale(d.x)}
                    cy={yScale(d.y1)}
                    r={tooltipData?.x === d.x ? 6 : 0}
                    fill={palette.brand.yellow}
                    style={{ transition: "all 0.2s ease-in-out" }}
                  />
                </>
              ))}
            </>
          )}
          {medianData.map(d => (
            <circle
              key={d.x}
              cx={xScale(d.x)}
              cy={yScale(d.y)}
              r={tooltipData?.x === d.x ? 6 : 4}
              fill={
                comparisonSubFunction ? palette.brand.blue : palette.brand.black
              }
              style={{ transition: "all 0.2s ease-in-out" }}
            />
          ))}
          <LinePath
            curve={curveNatural}
            data={medianData}
            x={d => xScale(d.x) ?? 0}
            y={d => yScale(d.y) ?? 0}
            stroke={
              comparisonSubFunction ? palette.brand.blue : palette.brand.black
            }
            shapeRendering="geometricPrecision"
          />

          <Area
            curve={curveNatural}
            data={decileData}
            x={d => xScale(d.x) ?? 0}
            y0={d => yScale(d.y0) ?? 0}
            y1={d => yScale(d.y1) ?? 0}
            fill="transparent"
            shapeRendering="geometricPrecision"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={hideTooltip}
          />
        </Group>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <Text color={palette.brand.black} bold>
            {tooltipData.x} years ({tooltipData.label})
          </Text>
          <Text color={palette.brand.black}>
            {currencyPrefix(currency)}
            {Math.round(tooltipData.value)}k
          </Text>
        </TooltipInPortal>
      )}
    </>
  );
}

interface SalariesChartProps {
  location: Location;
  subFunction: JobSubFunction;
  comparisonSubFunction: JobSubFunction | null;
}

export function SalaryChart({
  location,
  subFunction,
  comparisonSubFunction,
}: SalariesChartProps): React.ReactElement {
  const { data, previousData, loading } = useQuery(SalaryTrajectoryDocument, {
    variables: {
      subFunctionId: subFunction.id,
      location: location,
    },
  });

  const { data: comparisonData, loading: comparisonLoading } = useQuery(
    SalaryTrajectoryDocument,
    {
      variables: {
        subFunctionId: comparisonSubFunction?.id as string,
        location: location,
      },
      skip: !comparisonSubFunction,
    }
  );

  const selectedData = data ?? previousData;

  if (!selectedData) {
    return <Loading />;
  }

  const {
    median: { datapoints: medianDatapoints, currency, numRawDatapoints },
    firstQuartile: { datapoints: firstQuartileDatapoints },
    lastQuartile: { datapoints: lastQuartileDatapoints },
    firstDecile: { datapoints: firstDecileDatapoints },
    lastDecile: { datapoints: lastDecileDatapoints },
  } = selectedData.salaryStatistics;

  const {
    datapoints: comparisonDatapoints = [],
    numRawDatapoints: comparisonNumRawDatapoints = 0,
  } = comparisonData?.salaryStatistics?.median ?? {};

  const chartTitle = formatChartTitle(
    subFunction,
    comparisonSubFunction,
    numRawDatapoints,
    comparisonNumRawDatapoints,
    loading || comparisonLoading
  );

  const noData =
    medianDatapoints.length === 0 && comparisonDatapoints.length === 0;

  return (
    <ChartContainer>
      <Spacing>
        <TooltipWithIcon content="We only use datapoints from the past year. All submissions are verified by us.">
          <Text bold size={1}>
            {chartTitle}
          </Text>
        </TooltipWithIcon>
        <ChartWrapper>
          {!noData ? (
            <ParentSize>
              {({ width }) => (
                <Chart
                  subFunction={subFunction.value}
                  comparisonSubFunction={comparisonSubFunction?.value ?? null}
                  currency={currency}
                  width={width}
                  median={medianDatapoints}
                  comparison={comparisonDatapoints}
                  p10={firstDecileDatapoints}
                  p25={firstQuartileDatapoints}
                  p75={lastQuartileDatapoints}
                  p90={lastDecileDatapoints}
                />
              )}
            </ParentSize>
          ) : (
            <NoDataOverlay>
              <NoDataTextContainer>
                <ResponsiveText>
                  We don&apos;t have enough data for this category yet. Help us
                  by sharing this tool or submitting your salary.
                </ResponsiveText>
              </NoDataTextContainer>
            </NoDataOverlay>
          )}
        </ChartWrapper>
        {!noData && (
          <TooltipWithIcon content="We categorise salaries based on the years of experience required. As a rough guide: 0 years is entry-level; 1-2 years is junior; 3-4 years is mid-level; 5-8 years is senior; 9+ is expert &amp; leadership.">
            <Text>Years of relevant experience</Text>
          </TooltipWithIcon>
        )}
      </Spacing>
    </ChartContainer>
  );
}
