import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import styled from "@xstyled/styled-components";

import {
  VerticalSpacing,
  Text,
  Button as DefaultButton,
  Card,
  Modal,
} from "@otta/design";
import { pxToRem, modularScale } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Loading } from "@otta/search/components/Loading";
import { LogoWrapper } from "@otta/search/pages/Callbacks/shared";
import CompanyLogo from "@otta/search/components/CompanyLogo";
import {
  ApplyModalJobDocument,
  JobDeckStatusDocument,
  LastUnconfirmedJobApplicationDocument,
  UpdateJobApplicationDocument,
  UserTotalJobApplicationClicksDocument,
} from "@otta/search/schema";
import {
  getSalaryStatus,
  jobValueClassification,
} from "@otta/search/utils/analytics/jobProperties";
import { evictDashboardJobsCache } from "@otta/search/pages/Dashboard/mutationUpdaterFn";

const Button = styled(DefaultButton)`
  width: 100%;
`;

interface IDidYouApplyModalProps {
  token?: string;
  jobExternalId: string;
  onClose: () => void;
}

export function DidYouApplyModal({
  token,
  jobExternalId,
  onClose,
}: IDidYouApplyModalProps): React.ReactElement | null {
  const { data: jobData, loading: jobLoading } = useQuery(
    ApplyModalJobDocument,
    {
      variables: { externalId: jobExternalId },
    }
  );

  const { data, loading: clicksLoading } = useQuery(
    UserTotalJobApplicationClicksDocument
  );

  const [updateJobApplication, { loading }] = useMutation(
    UpdateJobApplicationDocument
  );

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Shown",
      pathname: location.pathname,
      name: `Did You Apply Modal`,
    });
  }, []);

  const totalApplyConfirms =
    data?.currentUser?.totalJobApplicationConfirms ?? 0;

  const job = jobData?.publicJob;

  const handleClick = (applied: boolean) => {
    if (job) {
      const analyticsEventData = {
        jobValueClassification: jobValueClassification(job.function?.id),
        jobTitle: job.title,
        companyName: job.company.name,
        jobId: job.id,
        function: job.function?.value,
        subFunction: job.subFunction?.value,
        minYearsExperienceRequired: job.minYearsExperienceRequired,
        maxYearsExperience: job.maxYearsExperienceRequired,
        applyViaOtta: job.acceptsInternalApplications,
        technologiesUsed: (job.technologiesUsed ?? [])
          .map(({ value }) => value)
          .join(", "),
        ottaCertified: job.company.ottaCertified,
        onlyOnOtta: job.company.onlyOnOtta,
        salaryStatus: getSalaryStatus(job),
      };

      pushAnalyticsEvent({
        eventName: `Candidate Confirmed Job Application`,
        totalApplicationConfirmations: totalApplyConfirms + 1,
        applied,
        ...analyticsEventData,
        source: "Did You Apply Modal",
        jobCardTab: location.pathname.includes("/company") ? "company" : "job",
      });

      if (applied) {
        updateJobApplication({
          variables: {
            input: {
              applied: true,
              internal: false,
              confirmed: true,
            },
            jobId: job.id,
          },
          context: { emailToken: token },
          refetchQueries: () => [
            { query: LastUnconfirmedJobApplicationDocument },
            { query: UserTotalJobApplicationClicksDocument },
            {
              query: JobDeckStatusDocument,
              variables: { externalId: job.externalId },
            },
          ],
          update: evictDashboardJobsCache,
        });
      } else {
        updateJobApplication({
          variables: {
            input: {
              applied: false,
              internal: false,
              confirmed: true,
            },
            jobId: job.id,
          },
          context: { emailToken: token },
          refetchQueries: () => [
            { query: LastUnconfirmedJobApplicationDocument },
          ],
          update: evictDashboardJobsCache,
        });
      }
    }
    onClose();
  };

  return (
    <Modal
      open
      onOpenChange={o => {
        if (!o) {
          onClose();
        }
      }}
      style={{ maxWidth: pxToRem(350) }}
      dismissable={false}
    >
      <Card data-testid="modal">
        {jobLoading || !jobData || clicksLoading ? (
          <Loading />
        ) : (
          <VerticalSpacing>
            <Text
              bold
              size={1}
              align="center"
              style={{ marginBottom: modularScale() }}
            >
              Did you apply?
            </Text>
            <LogoWrapper>
              {job && <CompanyLogo company={job?.company} />}
            </LogoWrapper>
            <Text bold size={1} align="center">
              {job?.company.name}
            </Text>
            <Text align="center">{job?.title}</Text>
            <Button
              level="secondary"
              onClick={() => handleClick(true)}
              disabled={loading}
            >
              Yes
            </Button>
            <Button
              level="secondary"
              onClick={() => handleClick(false)}
              disabled={loading}
            >
              No
            </Button>
          </VerticalSpacing>
        )}
      </Card>
    </Modal>
  );
}
