import * as Sentry from "@sentry/browser";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Field, Form as FinalForm } from "react-final-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "@xstyled/styled-components";
import { useCookie } from "react-use";

import { palette, pxToRem } from "@otta/design-tokens";
import { Button, ErrorText, InputField, Spacing } from "@otta/design";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CurrentUserDocument } from "@otta/search/schema";
import {
  composeValidators,
  minLength,
  validEmail,
} from "@otta/search/utils/validators";

const StyledLink = styled(Link)`
  color: ${palette.brand.black};
  line-height: ${pxToRem(24)};
  text-decoration: underline;
`;

export function LoginForm(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, refetch } = useQuery(CurrentUserDocument);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<"INVALID" | boolean>(false);
  const [csrf] = useCookie(import.meta.env.VITE_CSRF_COOKIE);
  const queryParams = queryString.parse(location.search);
  const redirect = queryParams.redirect;

  useEffect(() => {
    if (!data?.currentUser?.id) {
      return;
    }

    if (redirect && typeof redirect === "string") {
      navigate(redirect);
    } else {
      navigate("/");
    }
  }, [data, navigate, redirect]);

  const onSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    setError(false);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_HOST}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(csrf ? { "X-CSRF-Token": csrf } : {}),
          },
          body: JSON.stringify({ email, password }),
          credentials: "include",
        }
      );

      if (response.status === 403) {
        return setError("INVALID");
      } else if (response.status !== 200) {
        throw new Error("Invalid response received from signin api");
      }

      const { data } = await refetch();

      if (!data.currentUser) {
        throw new Error("No user returned after signing in");
      }
      pushAnalyticsEvent({ eventName: "Candidate logged in" });
    } catch (error) {
      Sentry.captureException(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const requiredFieldWithMessage =
    (message: string) =>
    (value: unknown): string | undefined =>
      value ? undefined : message;

  return (
    <FinalForm onSubmit={onSubmit}>
      {({ handleSubmit, errors = {} }) => (
        <form onSubmit={handleSubmit} data-testid="login-form">
          <Spacing size={2.25}>
            <Spacing>
              <Field
                name="email"
                validate={composeValidators(
                  requiredFieldWithMessage("Enter your email"),
                  validEmail,
                  minLength(5)
                )}
              >
                {({ input, meta }) => (
                  <InputField
                    {...input}
                    data-testid="input-email"
                    type="email"
                    label="Email"
                    error={meta.submitFailed && meta.touched && errors.email}
                    autoFocus
                  />
                )}
              </Field>
              <Spacing size={-4}>
                <Field
                  name="password"
                  validate={composeValidators(
                    requiredFieldWithMessage("Enter your password"),
                    minLength(8)
                  )}
                >
                  {({ input, meta }) => (
                    <InputField
                      {...input}
                      type="password"
                      label="Password"
                      data-testid="input-password"
                      error={
                        meta.submitFailed && meta.touched && errors.password
                      }
                    />
                  )}
                </Field>

                <StyledLink to="/forgot-password" style={{ display: "block" }}>
                  Forgot your password?
                </StyledLink>
              </Spacing>
            </Spacing>
            {loading && <Loading />}
            {error && (
              <ErrorText data-testid="login-error">
                {error === "INVALID"
                  ? "Incorrect email or password"
                  : "Something went wrong, try again."}
              </ErrorText>
            )}
            <Button
              level="primary"
              type="submit"
              data-testid="login-button"
              style={{ width: "100%" }}
              disabled={loading}
            >
              Sign in
            </Button>
          </Spacing>
        </form>
      )}
    </FinalForm>
  );
}
