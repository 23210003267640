import styled from "@xstyled/styled-components";

import { Text } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Company } from "@otta/search/schema";
import {
  GlassdoorRating,
  TrustpilotRating,
} from "@otta/search/components/Ratings";
import { Link } from "@otta/search/components/Link";

const Wrapper = styled.div`
  > div,
  > a {
    > div {
      justify-content: start;
    }
  }

  display: flex;
  gap: 16px;
`;

const Score = styled.span`
  color: ${palette.grayscale.shade600};
  font-weight: 400;
`;
interface GlassdoorAndTrustpilotProps {
  company: Pick<
    Company.Fragment,
    "glassdoorRating" | "glassdoorUrl" | "trustpilotRating" | "trustpilotUrl"
  >;
}

export function GlassdoorAndTrustpilot({
  company: { glassdoorRating, glassdoorUrl, trustpilotRating, trustpilotUrl },
}: GlassdoorAndTrustpilotProps): React.ReactElement | null {
  if (!glassdoorRating && !trustpilotRating) {
    return null;
  }

  return (
    <Wrapper>
      {glassdoorRating &&
        (glassdoorUrl ? (
          <Link to={glassdoorUrl} underline={false}>
            <Text bold as="h3" size={0}>
              Glassdoor <Score>({Number(glassdoorRating)})</Score>
            </Text>
            <GlassdoorRating score={Number(glassdoorRating)} />
          </Link>
        ) : (
          <div>
            <Text bold as="h3" size={0}>
              Glassdoor <Score>({Number(glassdoorRating)})</Score>
            </Text>
            <GlassdoorRating score={Number(glassdoorRating)} />
          </div>
        ))}
      {trustpilotRating &&
        (trustpilotUrl ? (
          <Link to={trustpilotUrl} underline={false}>
            <Text bold as="h3" size={0}>
              Trustpilot <Score>({Number(trustpilotRating)})</Score>
            </Text>
            <TrustpilotRating score={Number(trustpilotRating)} />
          </Link>
        ) : (
          <div>
            <Text bold as="h3" size={0}>
              Trustpilot <Score>({Number(trustpilotRating)})</Score>
            </Text>
            <TrustpilotRating score={Number(trustpilotRating)} />
          </div>
        ))}
    </Wrapper>
  );
}
