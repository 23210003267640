import styled, { css, up } from "@xstyled/styled-components";
import { useMemo, useState } from "react";

import { Spacing, Text } from "@otta/design";
import { Link } from "@otta/search/components/Link";
import { Company, Location } from "@otta/search/schema";
import { locationName } from "@otta/search/utils/locations";
import { Select } from "@otta/search/components/Input/Select";
import { groupBy } from "@otta/search/utils/collections";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: lg;
  margin-bottom: xl;

  ${up(
    "tablet",
    css`
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `
  )}
`;

const JobTitle = styled(Text)`
  text-decoration: underline;
`;

const getLocations = (jobs: Company.LiveJobs[]) => {
  return Array.from(
    new Set(
      jobs.flatMap(job =>
        job.locationPreferences
          .filter(
            l => !!locationName(l.location) && l.location !== "REMOTE_EUROPE"
          )
          .map(lp => lp.location)
      )
    )
  );
};

const formatToOptions = (locations: Location[]) => {
  return locations.map(location => {
    const containsRemote = location.includes("REMOTE");
    const label = ` ${containsRemote ? "Remote (within " : ""}${locationName(
      location
    )}${containsRemote ? ")" : ""} `;
    return {
      label: label as string,
      value: location,
    };
  });
};

export const shouldDisplayCompanyJobs = (
  company: CompanyJobsProps["company"]
) => {
  return company.numberLiveJobs > 0;
};

interface CompanyJobsProps {
  company: Pick<Company.Fragment, "numberLiveJobs" | "liveJobs">;
}

export const CompanyJobs = ({ company }: CompanyJobsProps) => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  );

  const jobs = company.liveJobs.filter(job =>
    job.locationPreferences.some(lp => !!locationName(lp.location))
  );

  const availableLocations = useMemo(() => getLocations(jobs), [jobs]);

  if (!shouldDisplayCompanyJobs(company)) return null;

  const options: { label: string; value: Location | null }[] = [
    { label: "All locations", value: null },
    ...formatToOptions(availableLocations),
  ];

  const filteredJobs = selectedLocation
    ? jobs.filter(job =>
        job.locationPreferences.some(lp => lp.location === selectedLocation)
      )
    : jobs;

  const jobsByFunction = groupBy(
    filteredJobs,
    (job: Company.LiveJobs) => job.function?.value
  );

  const handleLocationChange = (
    option: { value: Location | null; label: string } | null
  ) => {
    if (option) {
      setSelectedLocation(option.value);
    }
  };

  return (
    <div data-testid="company-jobs">
      <Header>
        <Text bold size={1}>
          Job{company.numberLiveJobs > 1 ? "s" : ""} ({company.numberLiveJobs})
        </Text>
        <Select
          options={options}
          onChange={handleLocationChange}
          value={options.find(op => op.value === selectedLocation)}
        />
      </Header>

      <Spacing size={2}>
        {Object.entries(jobsByFunction).map(([functionName, functionJobs]) => (
          <Spacing key={functionName}>
            <Text bold>{functionName}</Text>
            <Spacing>
              {functionJobs
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map(({ id, externalId, title, subtitle }) => (
                  <div key={id}>
                    <Link to={`/jobs/${externalId}`}>
                      <JobTitle>
                        {title}
                        {subtitle && ` (${subtitle})`}
                      </JobTitle>
                    </Link>
                  </div>
                ))}
            </Spacing>
          </Spacing>
        ))}
      </Spacing>
    </div>
  );
};
