export const Experiment = {
  OttaCues: "20220720_OTTACUES",
  AllSalaryBenchmarks: "20240708_SEARCH_APP_ALL_SALARY_BENCHMARKS",
  NewNotificationSettings: "20230621_NOTIFICATION_SETTINGS",
  CVUpload: "20230726_CV_UPLOAD",
  EBContent: "20240603_TOGGLE_EB",
  FollowCompany: "20240801_SEARCH_B2B_FOLLOW_COMPANY",
  CompanyPodcast: "20240805_COMPANY_PODCAST",
  NewFont: "20240812_Atkinson",
  OttaWttjRebranding: "20240715_OTTA_WTTJ_REBRANDING",
} as const;
