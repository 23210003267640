import styled, { css, down } from "@xstyled/styled-components";

import { BulletInput } from "./BulletInput";

import { Text } from "@otta/design";
import { Icon } from "@otta/icons";
import { palette } from "@otta/design-tokens";
import { LinkType } from "@otta/search/pages/Profile/sections/Links";

const OuterGrid = styled.div`
  display: grid;
  gap: 6px 10px;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  gap: 6px 10px;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: none;

  ${down(
    "tablet",
    css`
      grid-template-rows: 1fr 1fr auto;
      grid-template-columns: none;
      padding-bottom: md;
    `
  )};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled(Icon)`
  color: ${palette.brand.red};

  :hover {
    cursor: pointer;
    color: ${palette.brand.red};
  }
`;

interface BulletProps {
  id?: string;
  link: LinkType;
  index: number;
  onRemove: React.MouseEventHandler;
  onChangeUrl: React.ChangeEventHandler;
  onChangeType: React.ChangeEventHandler;
  onSetSelection: (i: number) => void;
  charLimit?: number;
  selected: boolean;
  placeholder?: string;
}

export const Bullet = ({
  id,
  link,
  index,
  onChangeUrl,
  onChangeType,
  onRemove,
  onSetSelection,
  charLimit,
  selected,
}: BulletProps): React.ReactElement => {
  return (
    <OuterGrid>
      <Grid>
        <ColumnWrapper>
          <Text>Enter link</Text>
          <BulletInput
            id={id}
            value={link.url}
            rows={1}
            index={index}
            selected={selected}
            onSetSelection={onSetSelection}
            onChange={onChangeUrl}
            placeholder="https://www.github.com/yourname"
            maxLength={charLimit}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <Text>Enter link type</Text>
          <BulletInput
            id={id}
            value={link.linkType}
            rows={1}
            index={index}
            selected={false}
            onSetSelection={onSetSelection}
            onChange={onChangeType}
            placeholder="GitHub"
            maxLength={charLimit}
          />
        </ColumnWrapper>
      </Grid>
      <StyledIcon size={2} icon="delete" onClick={onRemove} />
    </OuterGrid>
  );
};
