import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";

import { modularScale, palette } from "@otta/design-tokens";
import {
  UpdateCompanyEmployeeSurveyCategoryResponsesDocument,
  CompanyEmployeeSurveyCategoryResponseInput,
  CompanyEmployeeSurvey,
  CompanySubQualities,
} from "@otta/search/schema";
import { pulse } from "@otta/search/components/Animations";

export const Tag = styled.span<{ selected: boolean; disable?: boolean }>`
  background-color: ${({ selected }) =>
    selected ? palette.brand.yellow : palette.brand.white};
  color: ${({ disable, selected }) =>
    disable && !selected ? palette.grayscale.shade600 : palette.brand.black};
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  padding: 8px;
  border-radius: 12px;
  margin: 8px 6px 0px 0;
  font-size: ${modularScale(-1)};
  &:hover {
    animation: ${pulse} 0.2s ease-out;
  }
`;

interface ICategoryTagProps {
  surveyExternalId: string;
  survey: CompanyEmployeeSurvey.CompanyEmployeeSurvey;
  category: CompanySubQualities.CompanySubQualities;
}

export function CategoryTag({
  surveyExternalId,
  survey: { responses },
  category,
}: ICategoryTagProps): React.ReactElement {
  const [mutate] = useMutation(
    UpdateCompanyEmployeeSurveyCategoryResponsesDocument
  );

  const isSelected = responses.some(
    response => response.category.id === category.id
  );

  const handleClick = () => {
    let newResponsesInput: CompanyEmployeeSurveyCategoryResponseInput[] =
      responses.map(response => {
        return { categoryId: response.category.id, content: response.content };
      });
    if (isSelected) {
      // remove response
      newResponsesInput = newResponsesInput.filter(
        responseInput => responseInput.categoryId !== category.id
      );
    } else {
      // add response unless reached cap
      if (newResponsesInput.length >= 3) {
        return;
      }
      newResponsesInput.push({ categoryId: category.id });
    }
    mutate({
      variables: {
        surveyExternalId,
        responses: newResponsesInput,
      },
    });
  };

  return (
    <Tag onClick={handleClick} selected={isSelected}>
      {category.name}
    </Tag>
  );
}
