import Binoculars from "./assets/Binoculars.png";
import BatchSkeleton from "./assets/BatchSkeleton.png";
import Heart from "./assets/Heart.png";
import CustomerSuccess from "./assets/CustomerSuccess.png";
import Profile from "./assets/Profile.png";
import Lightbulb from "./assets/Lightbulb.png";
import StackArticles from "./assets/StackArticles.png";
import ShortlistCard from "./assets/ShortlistCard.png";

import { palette } from "@otta/design-tokens";

export interface CardItem {
  id: string;
  title: string;
  subtitle: string;
  image: string;
  infoImage: string;
  buttonText: string;
  backgroundColor: string;
  link: string;
}

export const TakeAnotherLook: CardItem = {
  id: "take-another-look",
  title: "Take another look",
  subtitle: "1 in 4 candidates save a job they skipped before.",
  image: Binoculars,
  infoImage: BatchSkeleton,
  buttonText: "Take another look",
  backgroundColor: palette.brand.white,
  link: "/jobs/theme/take-another-look",
};

export const ReviewSavedJobs: CardItem = {
  id: "saved-jobs",
  title: "Review saved jobs",
  subtitle: "Apply for the jobs you've shown interest in.",
  image: Heart,
  infoImage: ShortlistCard,
  buttonText: "Review saved jobs",
  backgroundColor: palette.brand.blue,
  link: "",
};

export const ReviewProfile: CardItem = {
  id: "review-profile",
  title: "Review your profile",
  subtitle: "Review and update your profile to help you stand out.",
  image: CustomerSuccess,
  infoImage: Profile,
  buttonText: "Review profile",
  backgroundColor: palette.brand.green,
  link: "/profile",
};

export const CreateProfile: CardItem = {
  id: "create-profile",
  title: "Create a profile",
  subtitle: "Apply for jobs faster and get seen by companies hiring.",
  image: CustomerSuccess,
  infoImage: Profile,
  buttonText: "Create profile",
  backgroundColor: palette.brand.green,
  link: "/profile",
};

export const BlogTips: CardItem = {
  id: "blog",
  title: "Tips from our blog",
  subtitle: "Write stronger applications and prepare for interviews.",
  image: Lightbulb,
  infoImage: StackArticles,
  buttonText: "Read blog",
  backgroundColor: palette.brand.pink,
  link: "https://blog.otta.com/tag/applying-to-startups/",
};
