import { useState } from "react";
import styled, { css } from "@xstyled/styled-components";

import { ConversationMessage, EnrichedMessage } from "./ConversationMessage";

import { Overline, Spacing, Text } from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";

const MessageGroupWrapper = styled.div<{ first?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ first }) => (first ? 0 : modularScale())};
`;

const MessageGroupInner = styled.div<{ left?: boolean }>`
  display: flex;
  align-items: flex-end;
  max-width: min(100%, 550px);

  ${({ left }) =>
    left
      ? css`
          align-self: flex-start;
        `
      : css`
          align-self: flex-end;
          margin-left: xl;
        `}
`;

const MessageGroupMessages = styled.div<{ left?: boolean }>`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: ${({ left }) => (left ? "flex-start" : "flex-end")};
`;

const ProfileInformation = styled.div<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-bottom: 10px;
  width: max-content;
  background-color: ${palette.extended.blue.shade200};
  border-radius: ${pxToRem(4)};
  padding: 6;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -5px;
    margin-left: 12px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: ${palette.extended.blue.shade200};
    border-color: ${palette.extended.blue.shade200};
    border-style: solid;
    border-width: 1px 0 0 1px;
    z-index: -1;
  }
`;

const profileImageSize = 35;

const Profile = styled.div`
  position: relative;
  margin-right: 10px;

  &:hover {
    ${ProfileInformation} {
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 100%;

    width: 10px;
    height: 10px;

    margin-left: 5px;
    margin-bottom: 12.5px;

    transform: rotate(45deg);
    background-color: ${palette.brand.white};
  }
`;

const ProfileImage = styled.div<{ src: string }>`
  display: block;
  height: ${pxToRem(profileImageSize)};
  width: ${pxToRem(profileImageSize)};
  border-radius: 50%;

  background-image: url(${({ src }) => src});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const Date = styled.div`
  margin: 0 auto;
  margin-bottom: lg;
`;

interface ConversationMessageGroupProps {
  date?: string;
  messages: EnrichedMessage[];
  first?: boolean;
  sender?: {
    firstName: string;
    lastName: string;
    profileImagePath: Maybe<string>;
    currentTitle: Maybe<string>;
    currentCompany: Maybe<{ name: string }>;
  };
}

export function ConversationMessageGroup({
  date,
  first,
  sender,
  messages,
}: ConversationMessageGroupProps): React.ReactElement {
  const [showRecruiterInfo, setShowRecruiterInfo] = useState(false);

  return (
    <MessageGroupWrapper data-testid="message-group" first={first}>
      {date && (
        <Date data-testid="message-group-date">
          <Overline size={-2}>{date}</Overline>
        </Date>
      )}
      <MessageGroupInner left={!!sender}>
        {sender?.profileImagePath && (
          <Profile
            onTouchStart={() => setShowRecruiterInfo(true)}
            onTouchEnd={() => setShowRecruiterInfo(false)}
          >
            <ProfileImage
              data-cs-mask
              data-testid="message-sender-image"
              src={sender.profileImagePath}
            />
            <ProfileInformation data-cs-mask show={showRecruiterInfo}>
              <Text bold>
                {sender.firstName} {sender.lastName}
              </Text>
              {sender.currentTitle && sender.currentCompany && (
                <Text>
                  {sender.currentTitle} at {sender.currentCompany.name}
                </Text>
              )}
            </ProfileInformation>
          </Profile>
        )}
        <MessageGroupMessages left={!!sender}>
          <Spacing size={-10}>
            {messages.map(({ id, content, read, meta, timestamp }) => (
              <ConversationMessage
                key={id}
                fromCandidate={!sender}
                read={read}
                id={id}
                meta={meta}
                timestamp={timestamp}
                content={content}
              />
            ))}
          </Spacing>
        </MessageGroupMessages>
      </MessageGroupInner>
    </MessageGroupWrapper>
  );
}
