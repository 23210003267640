import { useId, useState } from "react";
import styled, { css } from "@xstyled/styled-components";
import * as RadixTabs from "@radix-ui/react-tabs";
import { motion } from "framer-motion";

import { palette, pxToRem } from "@otta/design-tokens";

const TabsList = styled(RadixTabs.List)`
  display: flex;
  gap: lg;
  margin-bottom: lg;

  white-space: nowrap;
  overflow-x: auto;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: "";
    display: block;
    position: sticky;
    background: linear-gradient(
      -90deg,
      ${palette.brand.white} 0%,
      transparent 100%
    );
    width: ${pxToRem(16)};
    flex-shrink: 0;
    right: 0;
  }
`;

const indicatorStyle = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
`;

const Tab = styled(RadixTabs.Trigger)`
  all: unset;
  position: relative;
  padding: sm 0;
  cursor: pointer;

  /* Trick to prevent the tabs to jump when we set the font-weight to bold, see https://github.com/WTTJ/otta/pull/858 */
  display: inline-block;
  &::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &[data-state="active"] {
    font-weight: bold;
  }

  &::before {
    content: "";
    ${indicatorStyle};
    background-color: ${palette.brand.grey};
    opacity: 0;
    transition: default;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const TabIndicator = styled(motion.span)`
  ${indicatorStyle}
  background-color: ${palette.brand.yellow};
`;

export const Tabs = ({
  tabs,
  onChange,
}: {
  tabs: {
    id: string;
    title: string;
    content: React.ReactNode;
  }[];
  onChange?: (tab: string) => void;
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const layoutId = useId();

  const handleValueChange = (tab: string) => {
    setActiveTab(tab);
    onChange?.(tab);
  };

  if (tabs.length === 0) return null;

  return (
    <RadixTabs.Root value={activeTab} onValueChange={handleValueChange}>
      <TabsList>
        {tabs.map(({ id, title }) => (
          <Tab key={id} value={id} title={title}>
            {title}
            {activeTab === id && <TabIndicator layoutId={layoutId} />}
          </Tab>
        ))}
      </TabsList>

      {tabs.map(({ id, content }) => (
        <RadixTabs.Content key={id} value={id}>
          {content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};
