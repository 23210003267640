import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { TopContent } from "./responsiveComponents/TopContent";
import { DesktopContentProps } from "./tabs/DesktopContent";

import { JobCardType } from ".";

import { Job, UserJobPreferences } from "@otta/search/schema";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

interface JobContentProps {
  job: Job.Fragment;
  user?: UserJobPreferences.CurrentUser;
  type: JobCardType;
  publicJob?: boolean;
  toggleKeyboardShortcuts?: (enable: boolean) => void;
}

export function JobHeader({
  job,
  user,
  type,
  toggleKeyboardShortcuts,
}: JobContentProps): React.ReactElement | null {
  const [otherJobsExpanded, setOtherJobsExpanded] = useState(false);

  const context: DesktopContentProps = useMemo(() => {
    const expandOtherJobs =
      type !== JobCardType.dashboard
        ? () => setOtherJobsExpanded(true)
        : undefined;

    return {
      job,
      user,
      expandOtherJobs,
      otherJobsExpanded,
      toggleKeyboardShortcuts,
    };
  }, [job, user, otherJobsExpanded, type, toggleKeyboardShortcuts]);

  return (
    <EBTrackingProvider job={job} page="job" company={job.company}>
      <TopContent user={user} job={job} />
      <Outlet context={context} />
    </EBTrackingProvider>
  );
}
