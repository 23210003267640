export function OttaCertified(
  props: React.SVGAttributes<SVGSVGElement>
): React.ReactElement {
  return (
    <svg
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 -1L5.199 9.20342C5.84743 8.48724 6.61846 7.8864 7.47991 7.4313C8.45158 6.91471 9.50253 6.58874 10.6234 6.44497L11.3166 4.94205L9.58318 1.28626C8.92115 -0.109968 7.51412 -1 5.96889 -1H0ZM24 -1L18.7521 9.16113C18.1117 8.46354 17.3541 7.87736 16.5097 7.43125C15.151 6.70928 13.6374 6.35962 11.9948 6.35938C11.9637 6.35938 11.9326 6.3595 11.9016 6.35975L14.2241 1.32461C14.8777 -0.0924473 16.2958 -1 17.8563 -1H24ZM12 7.25C10.5108 7.25 9.17257 7.57213 7.98535 8.21638C6.8202 8.84406 5.84498 9.78419 5.16473 10.9355C4.48449 12.0868 4.125 13.4057 4.125 14.75C4.125 16.0944 4.48449 17.4132 5.16473 18.5646C5.84498 19.7159 6.8202 20.656 7.98535 21.2837C9.17279 21.9277 10.511 22.2498 12 22.25C13.4886 22.25 14.8268 21.9279 16.0146 21.2837C17.1798 20.656 18.155 19.7159 18.8353 18.5646C19.5155 17.4132 19.875 16.0944 19.875 14.75C19.875 13.4057 19.5155 12.0868 18.8353 10.9355C18.155 9.78419 17.1798 8.84406 16.0146 8.21638C14.8272 7.57235 13.489 7.25023 12 7.25ZM11.6595 10.2382C11.7935 9.94767 12.2065 9.94767 12.3405 10.2382L13.4991 12.75C13.5537 12.8685 13.666 12.95 13.7955 12.9654L16.5423 13.2911C16.8601 13.3287 16.9877 13.7215 16.7528 13.9388L14.722 15.8168C14.6262 15.9054 14.5833 16.0373 14.6088 16.1652L15.1478 18.8783C15.2102 19.1922 14.8761 19.4349 14.5969 19.2786L12.1832 17.9275C12.0694 17.8638 11.9306 17.8638 11.8168 17.9275L9.40313 19.2786C9.1239 19.4349 8.78979 19.1922 8.85215 18.8783L9.39124 16.1652C9.41666 16.0373 9.37379 15.9054 9.27804 15.8168L7.2472 13.9388C7.01226 13.7215 7.13988 13.3287 7.45765 13.2911L10.2045 12.9654C10.334 12.95 10.4463 12.8685 10.5009 12.75L11.6595 10.2382Z"
        fill="black"
      />
    </svg>
  );
}
