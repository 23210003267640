import styled from "styled-components";

import { CircularImg } from "../../Img";

import { CompanyPodcastData } from "./types";

import { palette, pxToRem } from "@otta/design-tokens";
import { Text } from "@otta/design";

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${palette.brand.yellow};
  border-radius: 0.5rem;
  padding: ${pxToRem(24)};
  gap: ${pxToRem(16)};
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${pxToRem(16)};
  gap: ${pxToRem(8)};
`;

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(4)};
`;

export function Quote({
  quote,
  user,
}: Pick<CompanyPodcastData, "quote" | "user">) {
  return (
    <QuoteContainer>
      <Text size={2} bold>
        &#8220;{quote}&#8221;
      </Text>

      <UserContainer>
        <CircularImg
          src={user.image.src}
          alt={`${user.name} headshot`}
          width={`${pxToRem(56)}`}
        />

        <UserDetailsContainer>
          <Text size={0} bold>
            {user.name}
          </Text>
          <Text size={0}>{user.role}</Text>
        </UserDetailsContainer>
      </UserContainer>
    </QuoteContainer>
  );
}
