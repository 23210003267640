import { useState } from "react";
import styled, { up, css, down } from "@xstyled/styled-components";
import { AnimatePresence, motion } from "framer-motion";

import { Heading, Spacing, Button, Middle, Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { OttaCertified } from "@otta/search/components/Icons/OttaCertified";
import { SalaryCoins } from "@otta/search/components/Icons/SalaryCoins";
import { SolidMessages } from "@otta/search/components/Icons/SolidMessages";
import { Link } from "@otta/search/components/Link";

const Header = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: ${palette.brand.yellow};
  color: ${palette.brand.black};
  -webkit-tap-highlight-color: transparent;
  border: none;
  cursor: pointer;

  ${up(
    "tablet",
    css`
      border-radius: ${pxToRem(16)} ${pxToRem(16)} 0 0;
    `
  )}
`;

const CertifiedIconWrapper = styled.div`
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};
  margin-bottom: sm;
`;

const Container = styled.div`
  padding: 19;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: 0;
  margin-bottom: 19;
  width: ${pxToRem(24)};
  height: ${pxToRem(24)};

  ${down(
    "tablet",
    css`
      margin-right: lg;
      margin-bottom: 0;
    `
  )}

  > svg {
    min-width: 100%;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: xxs;
  margin-left: xs;
`;

const OttaCertifiedInfo = styled(motion.div)`
  display: flex;
  justify-content: center;

  background: linear-gradient(
    ${palette.brand.white} 0.5%,
    ${palette.brand.yellow}
  );

  text-align: center;

  ${down(
    "tablet",
    css`
      text-align: left;
    `
  )}
`;

const KeyPointsWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: sm;
  ${up(
    "tablet",
    css`
      flex-direction: row;
    `
  )};
`;

const KeyPoint = styled.div`
  display: flex;
  flex-direction: row;
  margin: sm 0;

  ${up(
    "tablet",
    css`
      flex-direction: column;
      align-items: center;
      padding: 0 lg;
      margin: 0;
    `
  )}
`;

const content = {
  visible: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.5,
      delayChildren: 0.1,
      height: { ease: "easeInOut" },
    },
  },
  hidden: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.5,
      height: { ease: "easeInOut" },
    },
  },
};
const item = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const OttaCertifiedHeader = () => {
  const [showOttaCertifiedInfo, setShowOttaCertifiedInfo] = useState(false);

  const handleClick = () => {
    setShowOttaCertifiedInfo(!showOttaCertifiedInfo);
    pushAnalyticsEvent({
      eventName: "Candidate Clicked",
      name: "Otta Certified Accordion",
      page: "/companies",
    });
  };
  return (
    <div>
      <Header onClick={() => handleClick()} data-testid="otta-certified-header">
        <CertifiedIconWrapper>
          <OttaCertified />
        </CertifiedIconWrapper>
        <HeadingContainer>
          <Heading size={-1} bold>
            Certified Company
          </Heading>
        </HeadingContainer>
      </Header>
      <AnimatePresence>
        {showOttaCertifiedInfo && (
          <OttaCertifiedInfo
            key="otta-certified-info"
            variants={content}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.div variants={item}>
              <Container>
                <Spacing size={1}>
                  <Text>
                    Otta Certified companies put candidates first in their
                    hiring process
                  </Text>
                  <KeyPointsWrapper>
                    <KeyPoint>
                      <IconWrapper>
                        <SalaryCoins />
                      </IconWrapper>
                      <Text bold>They add public salaries to jobs</Text>
                    </KeyPoint>
                    <KeyPoint>
                      <IconWrapper>
                        <SolidMessages />
                      </IconWrapper>
                      <Text bold>They respond to applications quickly</Text>
                    </KeyPoint>
                  </KeyPointsWrapper>
                  <Middle>
                    <Link to="https://otta.com/certified" underline={false}>
                      <Button level="secondary">Learn more</Button>
                    </Link>
                  </Middle>
                </Spacing>
              </Container>
            </motion.div>
          </OttaCertifiedInfo>
        )}
      </AnimatePresence>
    </div>
  );
};
