import Linkify from "react-linkify";
import styled from "@xstyled/styled-components";

import { AssetGroupSprinkler } from "../AssetSprinkler";

import { Text, Spacing, List } from "@otta/design";
import { CircularImg } from "@otta/search/components/Img";
import { BrandAssetGroup, Company } from "@otta/search/schema";
import { imgProxy } from "@otta/search/utils/image";

const AuthorContainer = styled.div`
  align-items: center;
  display: flex;
  gap: md;
`;

export const shouldDisplayDiversityInitiatives = (
  company: DiversityInitiativesProps["company"]
) => {
  return company.diversityInitiatives.length > 0;
};

interface DiversityInitiativesProps {
  company: Pick<
    Company.Fragment,
    | "name"
    | "brandAssetGroups"
    | "diversityAuthorFullName"
    | "diversityAuthorTitle"
    | "diversityAuthorProfileImagePath"
    | "diversityInitiatives"
  >;
}

export function DiversityInitiatives({
  company,
}: DiversityInitiativesProps): React.ReactElement | null {
  if (!shouldDisplayDiversityInitiatives(company)) {
    return null;
  }

  const authorProfileImagePath = company.diversityAuthorProfileImagePath
    ? company.diversityAuthorProfileImagePath.startsWith("https://")
      ? company.diversityAuthorProfileImagePath
      : imgProxy(company.diversityAuthorProfileImagePath, { width: 120 }, "jpg")
    : null;

  const [authorFullName, authorTitle] =
    company.diversityAuthorFullName && company.diversityAuthorTitle
      ? [company.diversityAuthorFullName, company.diversityAuthorTitle]
      : [];

  return (
    <AssetGroupSprinkler
      group={BrandAssetGroup.CompanyProfileDiversityAndInclusion}
      assets={company.brandAssetGroups}
      data-testid="diversity-initiatives"
      noTitles
    >
      <Spacing>
        <Text bold size={1}>
          Diversity &amp; Inclusion at {company.name}
        </Text>
        {authorFullName && authorTitle && (
          <AuthorContainer>
            {authorProfileImagePath && (
              <CircularImg
                src={authorProfileImagePath}
                alt={`${authorFullName} headshot`}
                width="40px"
              />
            )}

            <Text bold>
              {authorFullName} ({authorTitle})
            </Text>
          </AuthorContainer>
        )}
        <List type="bullet">
          {company.diversityInitiatives.map(({ id, value }) => (
            <li data-testid="diversity-initiative" key={id}>
              <Linkify>{value}</Linkify>
            </li>
          ))}
        </List>
      </Spacing>
    </AssetGroupSprinkler>
  );
}
