import styled, { css } from "@xstyled/styled-components";

import { pxToRem, palette } from "@otta/design-tokens";
import { Icon } from "@otta/icons";
import { Link as DefaultLink } from "@otta/search/components/Link";

const LinkContainer = styled.span<{ align?: string }>`
  display: flex;
  color: ${palette.brand.black};
  justify-content: ${({ align }) =>
    align === "center" ? align : align === "left" ? "flex-start" : "flex-end"};
`;

const Link = styled(DefaultLink)`
  color: ${palette.brand.black};
  text-decoration: underline;
  font-weight: normal;
`;

const ExternalIconWrapper = styled.span<{
  iconSize?: number;
  iconLocation: "left" | "right";
}>`
  ${({ iconSize }) =>
    iconSize
      ? css`
          width: ${pxToRem(iconSize)};
          height: ${pxToRem(iconSize)};
        `
      : css`
          width: 12px;
          height: 12px;
        `}

  ${({ iconLocation }) =>
    iconLocation === "left"
      ? css`
          margin-right: 8px;
        `
      : css`
          margin-left: 8px;
        `}
`;

interface IExternalLink {
  to: string;
  children?: React.ReactNode;
  newTab?: boolean;
  iconLocation?: "left" | "right";
  align?: "left" | "center" | "right";
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  iconSize?: number;
}

export const ExternalLink = ({
  to,
  children,
  newTab = false,
  align = "left",
  iconLocation = "left",
  ...props
}: IExternalLink): React.ReactElement => (
  <LinkContainer align={align}>
    {iconLocation === "left" && (
      <ExternalIconWrapper iconLocation={iconLocation}>
        <Icon icon="external" />
      </ExternalIconWrapper>
    )}
    <Link targetBlank to={to} target={newTab ? "_blank" : ""} {...props}>
      {children}
    </Link>
    {iconLocation === "right" && (
      <ExternalIconWrapper iconLocation={iconLocation} {...props}>
        <Icon icon="external" />
      </ExternalIconWrapper>
    )}
  </LinkContainer>
);
