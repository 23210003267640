import styled from "@xstyled/styled-components";

import { Tooltip } from "@otta/design";
import { palette } from "@otta/design-tokens";
import { Icon as IconFont, OttaIconsId } from "@otta/icons";

const IconBadge = styled.div<{ colour?: keyof typeof palette.extended }>`
  background-color: ${({ colour }) =>
    colour ? palette.extended[colour].shade200 : palette.beige.shade200};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: auto;
  border-radius: 8px;
  margin: 0 auto;
  height: 28px;
`;

const IconWrapper = styled.div`
  color: ${palette.brand.black};
  display: inline-block;
  min-width: 14px;
  width: 14px;
  max-height: 16px;
  margin: auto 6px auto 8px;
  vertical-align: middle;
  > * {
    max-height: 16px;
    display: block;
  }
`;

const BadgeText = styled.span<{ noIcon: boolean; bold: boolean }>`
  display: inline-block;
  color: ${palette.brand.black};
  line-height: 1;
  font-weight: ${({ bold }) => (bold ? 700 : 500)};
  font-size: ${({ bold }) => (bold ? "16px" : "14px")};
  margin-right: 8px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  margin-top: 4px;
`;

interface IBadgeProps {
  badgeText: string;
  icon?: React.ComponentType | OttaIconsId;
  hoverText?: string;
  colour?: keyof typeof palette.extended;
  bold?: boolean;
  className?: string;
}

export function Badge({
  badgeText,
  icon: Icon,
  hoverText,
  colour,
  bold = false,
  className,
}: IBadgeProps): React.ReactElement {
  const icon = (
    <IconBadge colour={colour}>
      {Icon && (
        <IconWrapper>
          {typeof Icon === "function" ? (
            <Icon />
          ) : (
            <IconFont icon={Icon} size={1} />
          )}
        </IconWrapper>
      )}
      <BadgeText bold={bold} noIcon={!Icon}>
        {badgeText}
      </BadgeText>
    </IconBadge>
  );

  return (
    <BadgeWrapper className={className}>
      {hoverText ? <Tooltip content={hoverText}>{icon}</Tooltip> : icon}
    </BadgeWrapper>
  );
}
