import styled from "@xstyled/styled-components";
import { useEffect, useState } from "react";

import { GoogleSignIn } from "./Google";
import { AppleSignIn } from "./Apple";

import { Spacing, Text } from "@otta/design";
import { modularScale, palette } from "@otta/design-tokens";

const Divider = styled.div`
  display: flex;
  align-items: center;

  &::before,
  &::after {
    content: "";
    display: block;
    background-color: ${palette.grayscale.shade400};
    flex: 1;
    height: 2px;
  }
`;

interface ExternalSignInProps {
  action?: "Sign in" | "Sign up";
  successPath?: string;
  errorPath?: string;
  utmSource?: string;
  gclid?: string;
}

export function ExternalSignIn(
  props: ExternalSignInProps
): React.ReactElement | null {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isSafari = /safari/.test(userAgent);
    const isIOS = /iphone|ipod|ipad/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    const isSocial =
      /tiktok|instagram|facebook|fb_iab|fb4a|linkedin|reddit|twitter/.test(
        userAgent
      );

    setIsWebView((isIOS && !isSafari) || (isAndroid && isSocial));
  }, []);

  if (isWebView) {
    return null;
  }

  return (
    <Spacing size={2.25}>
      <Divider>
        <Text
          as="span"
          align="center"
          size={-1}
          color={palette.grayscale.shade600}
          style={{
            textAlign: "center",
            paddingLeft: modularScale(4),
            paddingRight: modularScale(4),
          }}
        >
          OR
        </Text>
      </Divider>
      <Spacing size={-1.5}>
        <GoogleSignIn {...props} />
        <AppleSignIn {...props} />
      </Spacing>
    </Spacing>
  );
}
