import { Field } from "react-final-form";
import styled from "@xstyled/styled-components";
import { FormApi } from "final-form";
import { useState } from "react";

import {
  Label,
  Spacing,
  Paragraph,
  Text,
  SelectField,
  ErrorText,
} from "@otta/design";
import { Icon } from "@otta/icons";
import { pxToRem, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import {
  composeValidators,
  maxLength,
  minLength,
  required,
} from "@otta/search/utils/validators";
import { SmallCheckbox } from "@otta/search/components/Input/SmallCheckbox";
import {
  ContextQuestionResponses,
  ContextQuestionsDocument,
} from "@otta/search/schema";
import { Textarea } from "@otta/search/components/Input/Textarea";
import { TooltipWithIcon } from "@otta/search/components/Tooltip";
import { Loading } from "@otta/search/components/Loading";

const FeaturedTagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${palette.brand.green};
  width: ${pxToRem(175)};
  padding: sm md;
  border-radius: ${pxToRem(8)};
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const Padding = styled.div`
  display: flex;
  align-items: center;
  padding-left: sm;
`;

export function EditingContextQuestion({
  form,
  data: responseData,
  isCreating = false,
}: {
  form: FormApi<{ question: string; answer: string; featuredAnswer: boolean }>;
  data: ContextQuestionResponses.ContextQuestionResponses | null;
  isCreating?: boolean | undefined;
}): React.ReactElement | null {
  const [isFeatured, setIsFeatured] = useState(
    responseData?.featuredAnswer ?? false
  );
  const originallyFeatured = responseData?.featuredAnswer === true;

  const { data, loading } = useQuery(ContextQuestionsDocument);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  const questions = data?.candidateContextQuestions ?? [];

  const allQuestions = questions.map(({ id, question, placeholder }) => ({
    label: question,
    value: id,
    placeholder,
  }));

  const filteredQuestions = questions
    .filter(({ response }) => !response)
    .map(({ id, question, placeholder }) => ({
      label: question,
      value: id,
      placeholder,
    }));

  return (
    <Field
      name="question"
      subscription={{ value: true }}
      render={({ input: { value: currentQuestionId } }) => {
        const currentQuestion = allQuestions.find(
          ({ value }) => value === currentQuestionId
        );

        return (
          <Spacing>
            <Spacing size={-4}>
              <Label htmlFor="question">Question</Label>
              {isCreating ? (
                <Field id="question" name="question" validate={required}>
                  {({ meta }) => (
                    <>
                      <SelectField
                        options={filteredQuestions}
                        onChange={event =>
                          form.change("question", event?.value)
                        }
                        styles={{
                          control: base => ({
                            ...base,
                            borderColor:
                              meta.touched && meta.error
                                ? palette.brand.red
                                : palette.grayscale.shade400,
                          }),
                        }}
                      />
                      {meta.touched && meta.error && (
                        <ErrorText>{meta.error}</ErrorText>
                      )}
                    </>
                  )}
                </Field>
              ) : (
                <SelectField
                  id="question"
                  name="question"
                  options={allQuestions}
                  value={{
                    label: currentQuestion?.label ?? "",
                    value: currentQuestionId,
                  }}
                  onChange={event => form.change("question", event?.value)}
                />
              )}
            </Spacing>
            <Field
              name="answer"
              validate={composeValidators(
                required,
                minLength(40),
                maxLength(200)
              )}
              render={({ input, meta }) => (
                <Textarea
                  {...input}
                  label="Answer"
                  id="answer"
                  placeholder={currentQuestion?.placeholder}
                  error={meta.touched && meta.error}
                />
              )}
            />
            <Field
              name="featuredAnswer"
              initialValue={isFeatured}
              subscription={{ value: true }}
              render={() => (
                <Field
                  name="featuredAnswer"
                  type="checkbox"
                  validate={val => {
                    if (originallyFeatured && !val) {
                      return "Set a featured answer. You can change it by selecting a different answer and setting it as your featured answer.";
                    }
                    return undefined;
                  }}
                >
                  {({ input, meta }) => (
                    <>
                      <RowWrapper>
                        <SmallCheckbox
                          {...input}
                          label="Set as featured answer"
                          checked={isFeatured}
                          onChange={val => {
                            setIsFeatured(prevState => !prevState);
                            input.onChange(val);
                          }}
                          isYellow
                        />
                        <Padding>
                          <TooltipWithIcon content="Your featured answer will show at the top of your profile for hiring managers to read" />
                        </Padding>
                      </RowWrapper>
                      {meta.touched && meta.error && (
                        <ErrorText>{meta.error}</ErrorText>
                      )}
                    </>
                  )}
                </Field>
              )}
            />
          </Spacing>
        );
      }}
    />
  );
}

function FeaturedTag(): React.ReactElement {
  return (
    <FeaturedTagWrapper>
      <Icon icon="new-to-otta" />
      <Text bold>Featured answer</Text>
    </FeaturedTagWrapper>
  );
}

export function DisplayContextQuestion({
  data,
}: {
  data: { value: string | null; featuredAnswer: boolean };
}): React.ReactElement {
  const { value, featuredAnswer } = data;
  return (
    <Spacing>
      {value && <Paragraph>{value}</Paragraph>}
      {featuredAnswer && <FeaturedTag />}
    </Spacing>
  );
}
