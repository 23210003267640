import styled from "@xstyled/styled-components";
import { useMemo } from "react";

import { Badge } from "../Badge";
import { Tabs } from "../Tabs";
import { AssetSprinkler } from "../AssetSprinkler";
import { Asset } from "../AssetSprinkler/Asset";

import { Spacing, Text, theme } from "@otta/design";
import {
  BrandAssetType,
  Company,
  CompanyBrandAssetLink,
  CompanyOffice as Office,
} from "@otta/search/schema";
import { palette, pxToRem } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { pushAnalyticsEvent } from "@otta/analytics";
import { useEBTracking } from "@otta/search/contexts/EBTracking";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";

const CardOfficeMap = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${palette.brand.grey};
  border-radius: ${pxToRem(12)};
  background-color: ${palette.brand.white};
  overflow: hidden;
`;

const MapWrapper = styled.div`
  position: relative;
`;

const MapImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  width: auto;
`;

const MapSource = styled.source(MapImg);

const MapAddress = styled(Text)`
  padding: lg;
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-top: 1px solid ${palette.brand.grey};

  :hover {
    background-color: ${palette.grayscale.shade50};
    transition: default;
  }
`;

const OfficeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: lg;
`;

const StyledAsset = styled(Asset)`
  width: 100%;
`;

export const shouldDisplayCompanyOffice = (company: Company.Company) => {
  return company.offices?.length !== 0 && company.offices?.at(0);
};

export const CompanyOffice = ({ company }: { company: Company.Company }) => {
  const hasEBContent = useHasEBContent();
  const trackingData = useEBTracking();
  const tabs = useMemo(() => {
    if (!company.offices) {
      return [];
    }

    return company.offices
      .filter((office): office is Office => !!office)
      .sort((a, b) => (a.isHq === b.isHq ? 0 : a.isHq ? -1 : 1))
      .map(office => {
        const displayAddress = office.parsedAddress ?? office.address;

        let videoAsset: CompanyBrandAssetLink | undefined;
        let imageAssets: CompanyBrandAssetLink[] = [];
        if (hasEBContent) {
          videoAsset =
            office?.brandAssetLinks?.filter(
              ba => ba.companyBrandAsset.type === BrandAssetType.Vimeo
            )?.[0] ?? undefined;

          imageAssets = office?.brandAssetLinks?.filter(
            ba => ba.companyBrandAsset.type !== BrandAssetType.Vimeo
          );
        }

        return {
          id: displayAddress as string,
          title: office.isHq
            ? (displayAddress as string).concat(" (HQ)")
            : (displayAddress as string),
          content: (
            <Spacing>
              <AssetSprinkler
                assets={imageAssets}
                data-testid="office"
                mobileCarousel
              >
                <OfficeWrapper>
                  {office.isHq &&
                    company.offices &&
                    company.offices.length > 1 && (
                      <Badge
                        key="headquarters"
                        badgeText="Headquarters"
                        icon="headquarters"
                        bold
                      />
                    )}
                  {videoAsset && (
                    <StyledAsset
                      asset={videoAsset}
                      data-testid="office-video-asset"
                    />
                  )}
                  <CardOfficeMap>
                    <Link
                      to={`https://www.google.com/maps/search/${office.address}`}
                      underline={false}
                      onClick={() =>
                        pushAnalyticsEvent({
                          eventName: "Candidate Clicked Office Location",
                          officeName: displayAddress,
                          officeId: office.id,
                          // company.offices can't be null since we're here but TS doesn't know
                          officesCount: (company.offices || []).length,
                          companyId: company.id,
                          loginStatus: trackingData?.loginStatus,
                        })
                      }
                    >
                      <MapWrapper>
                        <picture>
                          <MapSource
                            type="image/webp"
                            media={`(min-width: ${pxToRem(
                              theme.screens.tablet
                            )})`}
                            srcSet={office.mapImageUrls.large}
                          />
                          <MapImg
                            srcSet={office.mapImageUrls.medium}
                            alt={`${displayAddress} Google Maps office image`}
                            loading="lazy"
                          />
                        </picture>
                        <MapAddress>{office.address}</MapAddress>
                      </MapWrapper>
                    </Link>
                  </CardOfficeMap>
                </OfficeWrapper>
              </AssetSprinkler>
            </Spacing>
          ),
        };
      });
  }, [company.id, company.offices, hasEBContent, trackingData?.loginStatus]);

  // Already checked in shouldDisplayCompanyOffice but TS doesn't know
  if (!shouldDisplayCompanyOffice(company) || !company.offices) {
    return null;
  }

  return (
    <Spacing>
      <Text bold size={1}>
        Office
      </Text>
      <Spacing>
        {company.offices.length > 1 ? <Tabs tabs={tabs} /> : tabs[0].content}
      </Spacing>
    </Spacing>
  );
};
