import styled, { css, up } from "@xstyled/styled-components";
import { useMemo } from "react";

import { pxToRem } from "@otta/design-tokens";
import { BrandAssetGroup, BrandAssetType, Company } from "@otta/search/schema";
import { useHasEBContent } from "@otta/search/hooks/useHasEBContent";
import { Asset } from "@otta/search/components/AssetSprinkler/Asset";

const PhotoContainer = styled.div`
  margin-top: lg;
  display: grid;
  justify-content: space-between;
  min-height: ${pxToRem(100)};
  min-width: ${pxToRem(425)};

  grid-template-columns: 1fr 1fr 1fr;
  gap: lg;

  ${up(
    "tablet",
    css`
      margin-top: 0;
      grid-template-columns: ${pxToRem(152)} ${pxToRem(245)};
      gap: lg;
      grid-auto-rows: ${pxToRem(156)};
      grid-auto-columns: ${pxToRem(156)};
    `
  )}
`;

const Photo = styled(Asset)`
  border-radius: ${pxToRem(8)};
  aspect-ratio: 1;
  height: 100%;
  overflow: hidden;

  &:nth-child(1) {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 2;

    ${up(
      "tablet",
      css`
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end: 2;
      `
    )}
  }

  &:nth-child(2) {
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    ${up(
      "tablet",
      css`
        grid-column-start: 1;
        grid-row-start: 2;
        grid-row-end: 3;
      `
    )}
  }

  &:nth-child(3) {
    grid-column-start: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;

    ${up(
      "tablet",
      css`
        aspect-ratio: 0.5;
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 3;
      `
    )};
  }
`;

export const CompanyImagesSection = ({
  company,
}: {
  company: Company.Company;
}) => {
  const hasEBContent = useHasEBContent();
  const images = useMemo(
    () =>
      company.brandAssetGroups
        .find(bag => bag.group.name === BrandAssetGroup.CompanyProfileHeader)
        ?.links.filter(
          bal => bal.companyBrandAsset.type === BrandAssetType.Image
        )
        .slice(0, 3) ?? [],
    [company]
  );

  if (!hasEBContent || images.length < 3) {
    return null;
  }

  return (
    <PhotoContainer data-testid="company-images">
      {images.map(img => (
        <Photo asset={img} key={img.id} />
      ))}
    </PhotoContainer>
  );
};
