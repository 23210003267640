import { useCallback, useEffect, useState } from "react";
import styled from "@xstyled/styled-components";
import { Helmet } from "react-helmet-async";

import { CompaniesList } from "./components/CompaniesList";
import { Preferences } from "./components/Preferences";
import { LoadMoreCompanies } from "./components/LoadMoreCompanies";
import { LocationOptions } from "./components/PreferencesContent";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import { Location, PersonalisedCompaniesDocument } from "@otta/search/schema";

const PageWrapper = styled.section`
  padding: lg;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 690px;
`;

const limit = 16;

export function CompaniesIndex(): React.ReactElement {
  const [companyName, setCompanyName] = useState("");
  const [locationPreferences, setLocationPreferences] = useState<Location[]>(
    []
  );
  const [searchCompanyName, setSearchCompanyName] = useState("");
  const [companySizes, setCompanySizes] = useState<string[]>([]);
  const [sectorTagPreferences, setSectorTagPreferences] = useState<
    {
      id: string;
      preference: boolean;
    }[]
  >([]);
  const [investorPreferences, setInvestorPreferences] = useState<
    {
      id: string;
      preference: boolean | null;
    }[]
  >([]);

  useEffect(() => {
    pushAnalyticsEvent({ eventName: "Viewed Companies List" });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchCompanyName(companyName);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [companyName]);

  const handleChangeSearchBar: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(e => {
      setCompanyName(e.target.value);
    }, []);

  const handleClickCompanySize =
    (id: string): React.ChangeEventHandler<HTMLInputElement> =>
    e => {
      if (!e.target.checked && companySizes.includes(id)) {
        const newSizes = companySizes.filter(sizeId => sizeId !== id);
        setCompanySizes(newSizes);
      } else if (!companySizes.includes(id)) {
        setCompanySizes([...companySizes, id]);
      }
    };

  const handleClickSectorTag = (id: string) => () => {
    const currentPreference = sectorTagPreferences.find(
      ({ id: preferenceId }) => id === preferenceId
    );

    if (currentPreference) {
      setSectorTagPreferences(
        sectorTagPreferences.filter(
          ({ id: preferenceId }) => preferenceId !== id
        )
      );
    } else {
      setSectorTagPreferences([
        ...sectorTagPreferences,
        { id, preference: true },
      ]);
    }
  };

  const handleClickInvestor = (id: string) => () => {
    const currentPreference = investorPreferences.find(
      ({ id: preferenceId }) => id === preferenceId
    );
    if (currentPreference) {
      setInvestorPreferences(
        investorPreferences.filter(
          ({ id: preferenceId }) => preferenceId !== id
        )
      );
    } else {
      setInvestorPreferences([
        ...investorPreferences,
        { id, preference: true },
      ]);
    }
  };

  const handleSelectLocation = ({
    selectedLocations: locations,
  }: LocationOptions) => {
    setLocationPreferences(locations);
  };

  const { data, loading, fetchMore } = useQuery(PersonalisedCompaniesDocument, {
    fetchPolicy: "network-only",
    variables: {
      limit,
      offset: 0,
      companyName: searchCompanyName,
      companySizes,
      sectorTagPreferences,
      investorPreferences,
      locationPreferences,
    },
  });

  const total = data?.personalisedCompanies?.length ?? 0;

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://app.otta.com/companies" />
      </Helmet>
      <PageWrapper>
        <Preferences
          companyName={companyName}
          companySizes={companySizes}
          sectorTagPreferences={sectorTagPreferences}
          handleChangeSearchBar={handleChangeSearchBar}
          handleClickCompanySize={handleClickCompanySize}
          handleClickSectorTag={handleClickSectorTag}
          investorPreferences={investorPreferences}
          handleClickInvestor={handleClickInvestor}
          handleSelectLocation={handleSelectLocation}
        />
        <Content>
          {loading ? (
            <Loading />
          ) : (
            <>
              <CompaniesList
                companySizes={companySizes}
                sectorTagPreferences={sectorTagPreferences}
                investorPreferences={investorPreferences}
                data={data}
              />
              {total >= limit && (
                <LoadMoreCompanies
                  onMore={async () => {
                    await fetchMore({
                      variables: {
                        offset: total,
                      },
                    });
                  }}
                />
              )}
            </>
          )}
        </Content>
      </PageWrapper>
    </>
  );
}
