import { useEffect, useRef } from "react";
import styled, { up, css } from "@xstyled/styled-components";

import { CompanyImage } from "../InboxCard";
import { QuickResponses } from "../QuickResponses";

import { MessageInput } from "./MessageInput";

import { boxShadow, palette } from "@otta/design-tokens";
import { Middle, Spacing, Text } from "@otta/design";

const Layout = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: ${palette.brand.white};
  padding: lg;
  ${up(
    "desktop",
    css`
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    `
  )}
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Body = styled.div`
  flex: 1;
  position: relative;
  background: ${palette.grayscale.shade200};
`;

const BodyContent = styled.div`
  padding: lg;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
`;

const Footer = styled.div`
  background-color: ${palette.brand.white};
  padding: lg;
  box-shadow: ${boxShadow};
`;

const JobLink = styled.a`
  display: flex;
  align-items: center;
  color: ${palette.brand.black};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const JobInformation = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: xl;
`;

const OpenSideMenuButton = styled.div`
  cursor: pointer;
  transform: rotate(-45deg);
  padding: 6;
  border-right: 3px solid ${palette.brand.black};
  border-bottom: 3px solid ${palette.brand.black};

  margin-right: xl;

  ${up(
    "desktop",
    css`
      display: none;
    `
  )};
`;

const DeclinedConversationText = styled(Text)`
  font-weight: bold;
  text-align: center;
  padding-top: xxl;
`;

interface MessagingLayoutProps {
  backTo?: string;
  job: {
    title: string;
    externalId: string;
    subtitle: string | null;
    company: {
      name: string;
      faviconPath: string | null;
      primaryColour: string | null;
    };
  };
  responded: boolean;
  sourced: boolean;
  children: React.ReactNode;
  messagingDisabled?: boolean;
  inputDisabled?: boolean;
  minChars?: number;
  maxChars?: number;
  onMessage: ({
    accepted,
    message,
  }: {
    accepted: boolean;
    message: string;
  }) => void;
  onRequestResponse?: ({
    accepted,
    message,
  }: {
    accepted: boolean;
    message: string;
  }) => void;
  openSideMenu: () => void;
}

export function MessagingLayout({
  job,
  responded,
  sourced,
  messagingDisabled,
  inputDisabled,
  minChars,
  maxChars,
  children,
  openSideMenu,
  onMessage,
  onRequestResponse,
}: React.PropsWithChildren<MessagingLayoutProps>): React.ReactElement {
  const { externalId, title, subtitle, company } = job;

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Layout>
      <Header data-testid="messaging-header">
        <HeaderContent>
          <OpenSideMenuButton
            onClick={e => {
              openSideMenu();
              e.stopPropagation();
            }}
          />
          <CompanyImage
            name={company.name}
            image={company.faviconPath}
            primaryColour={company.primaryColour}
          />
          <JobInformation>
            <Spacing size={-8}>
              <Text bold>{company.name}</Text>
              <Text>
                <JobLink
                  data-cs-mask
                  href={`/jobs/${externalId}`}
                  target="_blank"
                >
                  {title}
                </JobLink>
              </Text>
              {subtitle && <Text size={-1}>{subtitle}</Text>}
            </Spacing>
          </JobInformation>
        </HeaderContent>
      </Header>
      <Body>
        <BodyContent>
          <Spacing size={2}>
            {children}
            {messagingDisabled && (
              <DeclinedConversationText size={1}>
                You have declined this message request
              </DeclinedConversationText>
            )}
          </Spacing>
          {sourced && !responded && onRequestResponse && (
            <Middle maxWidth={1000}>
              <QuickResponses
                onSend={onRequestResponse}
                loading={inputDisabled ?? false}
              />
            </Middle>
          )}
          <div ref={scrollRef} />
        </BodyContent>
      </Body>
      {!messagingDisabled && (
        <Footer>
          <Middle maxWidth={1000}>
            <MessageInput
              onSubmit={
                sourced && !responded && onRequestResponse
                  ? onRequestResponse
                  : onMessage
              }
              disabled={inputDisabled}
              placeholder="Type a message (Press ⏎ for a new line)..."
              minChars={minChars}
              maxChars={maxChars}
            />
          </Middle>
        </Footer>
      )}
    </Layout>
  );
}
