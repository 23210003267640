import styled from "@xstyled/styled-components";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import {
  Button as DefaultButton,
  Card,
  Heading,
  List,
  Middle,
  Spacing,
  Text,
} from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  CurrentUserDocument,
  DeleteCurrentUserDocument,
} from "@otta/search/schema";
import { PageWrapper } from "@otta/search/components/PageWrapper";
import { CircleUser } from "@otta/search/components/Icons/CircleUser";
import { Spinner } from "@otta/shared-components";

const Button = styled(DefaultButton)`
  flex: 1 1 ${pxToRem(168)};
  padding: 9 sm;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: xl;
  row-gap: lg;
`;

const EmailTag = styled.div`
  align-items: center;
  border: 1px solid ${palette.grayscale.shade600};
  border-radius: ${pxToRem(12)};
  color: ${palette.grayscale.shade600};
  display: flex;
  flex-shrink: 1;
  padding: sm;
  gap: sm;
  word-break: break-all;
  width: fit-content;
`;

const ListItem = styled.li`
  margin-bottom: 0 !important;
`;

export function DeleteMyAccount(): React.ReactElement | null {
  const { data } = useQuery(CurrentUserDocument, { ssr: true });

  const [deleteMutation, { loading }] = useMutation(DeleteCurrentUserDocument, {
    onCompleted: () => {
      pushAnalyticsEvent({ eventName: "Candidate Deleted Account" });
      toast.success("Your account has successfully been deleted.");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    },
    onError: () => {
      toast.error(
        "We could not delete your account at this time. Our team has received the deletion request and will delete your account."
      );
      pushAnalyticsEvent({ eventName: "Candidate Deleted Account Failed" });
    },
  });

  const userEmail = data?.currentUser?.email;

  const handleDelete = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this account? This can't be undone."
      )
    ) {
      const emailPrompt = window.prompt("Type your email to confirm");
      if (emailPrompt === userEmail) {
        await deleteMutation();
      } else {
        toast.error("Incorrect email.");
      }
    }
  };

  if (!userEmail) {
    return null;
  }

  return (
    <PageWrapper style={{ marginTop: pxToRem(40) }}>
      <Middle maxWidth={394}>
        <Spacing size={5}>
          <Heading bold size={2} align="center">
            {loading ? "Deleting account" : "Delete account"}
          </Heading>
          {loading && (
            <Text bold align="center">
              Deleting your account may take a few minutes. Please keep this tab
              open whilst we remove your data.
            </Text>
          )}
          {!loading ? (
            <Card style={{ padding: pxToRem(16) }}>
              <Spacing>
                <Text bold align="left">
                  Are you sure you want to delete your account?
                </Text>

                <EmailTag>
                  <CircleUser data-cs-mask width={24} height={24} />
                  <Text bold data-cs-mask>
                    {data.currentUser?.email}
                  </Text>
                </EmailTag>

                <Text align="left">
                  Deleting your account will remove all of your information,
                  including your:
                </Text>

                <List type="bullet" style={{ paddingLeft: pxToRem(8) }}>
                  <ListItem> Saved jobs </ListItem>
                  <ListItem> Applications sent or in progress </ListItem>
                  <ListItem> Messages sent in the inbox </ListItem>
                  <ListItem> Profile </ListItem>
                  <ListItem> Search preferences </ListItem>
                </List>

                <Text align="left">
                  If you delete your account, it&apos;s gone forever. We
                  can&apos;t get it back.
                </Text>

                <ButtonContainer>
                  <Button as={Link} level="secondary" to="/account-settings">
                    Go back
                  </Button>
                  <Button
                    type="button"
                    level="destructive"
                    onClick={handleDelete}
                  >
                    Delete account
                  </Button>
                </ButtonContainer>
              </Spacing>
            </Card>
          ) : (
            <Spinner />
          )}
        </Spacing>
      </Middle>
    </PageWrapper>
  );
}
