import styled from "@xstyled/styled-components";

import { Card, Spacing, Text } from "@otta/design";
import { palette } from "@otta/design-tokens";

const ColoredCard = styled(Card)<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || palette.brand.white};
  padding: 1rem;
`;

const OrderedList = styled.ol`
  margin-left: 1rem;
`;

const UnorderedList = styled.ul`
  margin-left: 1rem;
`;

const BoldedListItem = styled.li`
  font-weight: bold;
`;

export function ApplicationTipsTab() {
  return (
    <>
      <Card>
        <Spacing>
          <Text bold size={1}>
            Consider the hiring manager's mindset
          </Text>
          <Text>
            Your profile will be read by a hiring manager. They often have
            hundreds of candidates to consider, and therefore you need to tell a
            really crisp and concise story. Sometimes you only have 60 seconds
            of their attention to impress them!
          </Text>
          <ColoredCard backgroundColor={"#FCF8D1"}>
            <Spacing>
              <Text bold>A few quick tips:</Text>
              <OrderedList>
                <li>
                  <Text>
                    A hiring manager isn't looking for a complete overview of
                    your work history. Instead they want to know the most
                    relevant and important details
                  </Text>
                </li>
                <li>
                  <Text>
                    Focus your efforts on more recent roles. You should be
                    giving the most detail for roles you've had in the last 1-3
                    years
                  </Text>
                </li>
                <li>
                  <Text>
                    If you find yourself writing more than 6 bullet points for
                    each of your work experiences, you're likely giving too much
                    detail
                  </Text>
                </li>
              </OrderedList>
            </Spacing>
          </ColoredCard>
        </Spacing>
      </Card>
      <Card>
        <Spacing>
          <Text bold size={1}>
            Focus on impact when writing your bullet points
          </Text>
          <Text>Three steps to writing great bullet points:</Text>
          <OrderedList>
            <li>
              <Text>
                Start each bullet point with an <strong>active verb</strong> in
                the past tense. This helps you write concisely
              </Text>
            </li>
            <li>
              <Text>
                Add enough <strong>detail</strong> to show off your skills, and
                give the hiring manager context
              </Text>
            </li>
            <li>
              <Text>
                Include the <strong>impact</strong> of your work. Using numbers
                (e.g. increase of 5%) can help showcase your achievements
              </Text>
            </li>
          </OrderedList>
          <ColoredCard backgroundColor={palette.extended.green.shade100}>
            <Spacing>
              <Text>
                Here is an example of a{" "}
                <Text bold as="span">
                  strong bullet point:
                </Text>
              </Text>
              <UnorderedList>
                <li>
                  <Text>
                    <Text bold as="span">
                      Completed
                    </Text>{" "}
                    a series of A/B tests to optimise the onboarding form.
                    Developed a dashboard with{" "}
                    <Text bold as="span">
                      data sourced from Google Analytics,
                    </Text>{" "}
                    which showed a{" "}
                    <Text bold as="span">
                      17% increase in form completion
                    </Text>
                  </Text>
                </li>
              </UnorderedList>
              <Text>
                In comparison, here is an example of a weaker bullet point:
              </Text>

              <UnorderedList>
                <li>
                  <Text>
                    I was part of a team that worked on the onboarding form with
                    my team to make it easier for customers
                  </Text>
                </li>
              </UnorderedList>
            </Spacing>
          </ColoredCard>
        </Spacing>
      </Card>
      <Card>
        <Spacing>
          <Text bold size={1}>
            Let your personality shine!
          </Text>
          <Text>
            When applying for a job, it can really pay off to spend 30-60
            minutes putting your best foot forward. We give you a number of
            opportunities on your profile to show why you're a strong candidate:
          </Text>
          <ColoredCard backgroundColor={palette.extended.red.shade100}>
            <OrderedList>
              <BoldedListItem>Snippets</BoldedListItem>
              <Text>
                Snippets allow you to show off interesting things you've done,
                and skills you've gained
              </Text>

              <BoldedListItem>Show your personality</BoldedListItem>
              <Text>
                We allow you to answer prompts like "I like to work with people
                who..." - these show the hiring manager about what you're like
                in an informal way
              </Text>

              <BoldedListItem>Interests</BoldedListItem>
              <Text>
                Adding 5-8 interests helps show your personality. Ideally you'll
                be happy talking about any for 5 minutes, don't write things
                just because you think it will make you look good!
              </Text>
            </OrderedList>
          </ColoredCard>
        </Spacing>
      </Card>
    </>
  );
}
