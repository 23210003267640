import { useMutation } from "@apollo/client";
import { useState, useEffect } from "react";
import styled from "@xstyled/styled-components";

import { Spacing, Text, theme } from "@otta/design";
import { pxToRem } from "@otta/design-tokens";
import { Loading } from "@otta/search/components/Loading";
import { CreateCandidateCvPdfDocument } from "@otta/search/schema";
import { handleMutationError } from "@otta/search/utils/error";

const StyledIframe = styled.iframe`
  display: none;
  width: 100%;

  @media (min-width: ${pxToRem(theme.screens.mobile)}) {
    display: initial;
    height: ${pxToRem(800)};
  }
`;

const DownloadPDF = styled.div`
  a {
    font-weight: bold;
    color: black;
  }
  @media (min-width: ${pxToRem(
      theme.screens.tablet
    )}) and (pointer: coarse) and (hover: none) {
    display: none;
  }
`;
export function ProfilePdfPreview({
  jobId,
}: {
  jobId: string;
}): React.ReactElement | null {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const [createCvPdfMutation, { loading: createCvPdfLoading }] = useMutation(
    CreateCandidateCvPdfDocument,
    {
      variables: {
        jobId: jobId,
      },
      onError: handleMutationError,
    }
  );

  useEffect(() => {
    const handleCreateCvPdf = async () => {
      const result = await createCvPdfMutation();
      const url = result?.data?.createCandidateCvPdf?.url;

      if (url && url.includes(".pdf")) {
        setPdfUrl(url);
      }
    };

    handleCreateCvPdf();
  }, [createCvPdfMutation]);

  if (createCvPdfLoading) {
    return <Loading />;
  }
  if (!pdfUrl) {
    return null;
  }

  return (
    <Spacing size={0}>
      <Text size={1} bold>
        Your profile
      </Text>

      <DownloadPDF>
        <a href={pdfUrl} target="_blank" rel="noreferrer">
          Preview your profile
        </a>
        <span> before sending your application</span>
      </DownloadPDF>

      <StyledIframe
        height="800"
        allow-downloads-without-user-activation={false}
        src={`${pdfUrl}#view=FitH`}
      />
    </Spacing>
  );
}
