import styled, { css, up } from "@xstyled/styled-components";

import { borderRadius, boxShadow, palette, pxToRem } from "@otta/design-tokens";
import { slideUp } from "@otta/search/components/Animations";
import { useScrollLock } from "@otta/search/hooks/scrollLock";

const StyledOverlay = styled.div`
  z-index: 20;
  position: fixed;
  background-color: ${palette.brand.white};
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  height: 100%;
  animation: ${slideUp()} 0.4s;
  -webkit-overflow-scrolling: touch;
  ${up(
    "tablet",
    css`
      max-width: 768px;
      top: 48px;
      left: 50%;
      animation: ${slideUp("-50%")} 0.4s;
      transform: translateX(-50%);
      right: unset;
      width: 100%;
      border-radius: ${pxToRem(borderRadius)};
      box-shadow: ${boxShadow};
    `
  )}
  ${up(
    "desktop",
    css`
      max-width: 1024px;
    `
  )}
`;

export function Overlay({
  children,
  className,
}: {
  className?: string;
  children: React.ReactNode;
}): React.ReactElement {
  const ref = useScrollLock<HTMLDivElement>();
  return (
    <StyledOverlay ref={ref} className={className}>
      {children}
    </StyledOverlay>
  );
}

export const Background = styled.div`
  display: none;

  ${up(
    "tablet",
    css`
      display: block;
    `
  )}
  position: fixed;
  background-color: ${palette.grayscale.shade50};
  opacity: 0.6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;
`;
