import styled, { css, up } from "@xstyled/styled-components";
import { AnimatePresence, motion } from "framer-motion";
import store from "store";
import { useState } from "react";

import { Swipe } from "../icons/Swipe";

import { palette } from "@otta/design-tokens";
import { Middle, Spacing, Text as DefaultText } from "@otta/design";

const STORAGE_KEY = "job-deck-drag-guide-seen";

const Container = styled(motion.div)`
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 30vh;
  z-index: 12;

  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const IconWrapper = styled(motion.div)`
  svg {
    fill: ${palette.brand.white};
    width: 100px;
    height: 100px;
  }
`;

const Text = styled(DefaultText)`
  color: ${palette.brand.white};
`;

export function DragGuideOverlay(): React.ReactElement {
  const [show, setShow] = useState(true);
  const seen = store.get(STORAGE_KEY) === "Yes";

  const handleDone = () => {
    store.set(STORAGE_KEY, "Yes");
    setShow(false);
  };

  return (
    <AnimatePresence>
      {!seen && show && (
        <Container
          onClick={handleDone}
          onTouchStart={handleDone}
          transition={{ opacity: { duration: 0.1 } }}
          exit={{
            opacity: 0,
          }}
        >
          <Middle maxWidth={300}>
            <Spacing>
              <IconWrapper
                animate={{ x: [60, -60, -60, 60] }}
                transition={{
                  duration: 2,
                  type: "ease",
                  times: [0, 0.7, 0.8, 1],
                  loop: Infinity,
                }}
              >
                <Swipe />
              </IconWrapper>
              <Text bold>Navigate between jobs by swiping left or right</Text>
            </Spacing>
          </Middle>
        </Container>
      )}
    </AnimatePresence>
  );
}
