import styled from "@xstyled/styled-components";

import { ScrollableContainer } from "../ScrollableContainer";

import { Spacing, Text, Heading, Middle } from "@otta/design";
import { modularScale } from "@otta/design-tokens";
import { SidebarQuizLayoutProps } from "@otta/search/containers/Quiz/SidebarQuiz";

const QuestionWrapper = styled(Middle)`
  width: 100%;
`;

export function SidebarQuizLayout({
  question,
  buttons,
  setNextEnabled,
  nextEnabled,
  handleNext,
  handleSkip,
}: SidebarQuizLayoutProps): React.ReactElement {
  const QuestionComponent = question.component;

  return (
    <>
      <ScrollableContainer style={{ paddingTop: modularScale(5) }}>
        <QuestionWrapper maxWidth={490}>
          <Spacing size={2}>
            <Spacing>
              <Heading as="h3" size={2} bold>
                {question.title}
              </Heading>
              {question.subtitle && <Text as="h4">{question.subtitle}</Text>}
            </Spacing>
            <QuestionComponent
              handleNext={handleNext}
              nextEnabled={nextEnabled}
              handleSkip={handleSkip}
              setNextEnabled={setNextEnabled}
            />
          </Spacing>
        </QuestionWrapper>
      </ScrollableContainer>
      {buttons}
    </>
  );
}
