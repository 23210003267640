import { FieldValidator } from "final-form";
import { Field, FieldInputProps } from "react-final-form";
import styled from "@xstyled/styled-components";

import { Select } from "./Select";

import { palette } from "@otta/design-tokens";

const Error = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${palette.brand.red};
  margin: 8px 0;
  text-align: left;
`;

interface IFinalSelect<M extends boolean> {
  id?: string;
  name: string;
  options: { value: string; label: string }[];
  isMulti?: M;
  validate?: FieldValidator<
    M extends true
      ? { value: string; label: string }[] | undefined
      : { label: string; value: string } | undefined
  >;
}

export function FinalSelect<M extends boolean>({
  id,
  name,
  options,
  isMulti,
  ...props
}: IFinalSelect<M>): React.ReactElement {
  return (
    <Field
      name={name}
      parse={(
        val:
          | undefined
          | { value: string; label: string }[]
          | { value: string; label: string }
      ) =>
        isMulti
          ? (val as { value: string; label: string }[] | undefined)
          : (val as { value: string; label: string } | undefined)?.value
      }
      format={(
        val
      ):
        | { value: string; label: string }[]
        | { value: string; label: string }
        | undefined =>
        isMulti
          ? options.filter(o =>
              val
                ? (val as { value: string; label: string }[]).find(
                    v => v.value === o.value
                  )
                : false
            )
          : options.find(o => o.value === (val as undefined | string))
      }
      render={({ input, meta }) => (
        <>
          <Select
            {...(input as FieldInputProps<
              | { value: string; label: string }[]
              | { value: string; label: string }
              | undefined
            >)}
            inputId={id}
            options={options}
            isMulti={isMulti}
            {...props}
          />
          {meta.touched && meta.error && <Error>{meta.error}</Error>}
        </>
      )}
    />
  );
}
