import { useMemo } from "react";
import { useParams } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { CompanyContent } from "./components/CompanyContent";
import { useCompany } from "./useCompany";

import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { CompanyUserInfoDocument } from "@otta/search/schema";
import { pxToRem } from "@otta/design-tokens";
import { EBTrackingProvider } from "@otta/search/contexts/EBTracking";

const PageContainer = styled.div`
  max-width: ${pxToRem(1164)};
  margin: 0 auto;

  ${up(
    "tablet",
    css`
      padding: 0 14;
    `
  )}
`;

export function CompanyInfo(): React.ReactElement | null {
  const { companyUrlSafeName } = useParams();

  const { data: companyData, loading: companyLoading } = useCompany(
    companyUrlSafeName as string
  );

  const { data: userData, loading: userLoading } = useQuery(
    CompanyUserInfoDocument,
    {
      ssr: true,
    }
  );

  const userVisaRequirements = useMemo(
    () =>
      userData?.currentUser?.visaRequirementCountries.map(l => l.location) ??
      [],
    [userData?.currentUser?.visaRequirementCountries]
  );

  if (userLoading || companyLoading) {
    return <Loading />;
  }

  const company = companyData?.company;

  if (!company) {
    return null;
  }

  return (
    <EBTrackingProvider page="company" company={company}>
      <PageContainer data-testid="company-info">
        <CompanyContent
          company={company}
          userVisaRequirements={userVisaRequirements}
        />
      </PageContainer>
    </EBTrackingProvider>
  );
}
