import { useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";
import styled, { css, down, up } from "@xstyled/styled-components";
import * as Sentry from "@sentry/browser";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ExternalApply } from "./ExternalApply";
import { InternalApply } from "./InternalApply";
import { FooterContent } from "./styles";

import { pxToRem } from "@otta/design-tokens";
import { Button, fadeIn, Spacing, Text } from "@otta/design";
import { useQuery } from "@otta/search/apollo";
import { Loading } from "@otta/search/components/Loading";
import { pushAnalyticsEvent } from "@otta/analytics";
import {
  ApplyContentDocument,
  ConfirmJobLiveDocument,
  ApplyModalJob,
  JobDeckStatusDocument,
  UpdateJobApplicationDocument,
  UserJobStatusDocument,
  UserTotalJobApplicationClicksDocument,
  ThemeId,
  LastUnconfirmedJobApplicationDocument,
  TeamMemberRole,
  BrandAssetType,
} from "@otta/search/schema";
import { jobValueClassification } from "@otta/search/utils/analytics/jobProperties";
import { toSlug } from "@otta/search/pages/Jobs/JobDeck/BatchOptions";
import { usePeopleBreakdownTracking } from "@otta/search/contexts/EBTracking";

const Container = styled.div`
  padding: 0 lg lg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 500ms;
  margin: 0 auto;
  ${up(
    "tablet",
    css`
      max-width: ${pxToRem(490)};
    `
  )}
  ${down(
    "desktop",
    css`
      max-width: ${pxToRem(490)};
    `
  )}
`;

const Content = styled.div`
  padding: lg 0;
`;

interface ApplyProps {
  token?: string;
  type: "JOB_DECK" | "DASHBOARD";
  job: ApplyModalJob.PublicJob;
  onExternalApply?(): void;
  onClose(): void;
  themeId?: ThemeId;
}

export function Apply({
  token,
  type,
  job,
  onExternalApply,
  onClose,
  themeId,
}: ApplyProps): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading: queryLoading } = useQuery(ApplyContentDocument, {
    variables: {
      jobId: job.externalId,
    },
    context: { emailToken: token },
  });
  const { data: totalApplicationsData } = useQuery(
    UserTotalJobApplicationClicksDocument,
    { context: { emailToken: token } }
  );

  const totalApplyClicks =
    totalApplicationsData?.currentUser?.totalJobApplicationClicks ?? 0;

  const [mutation, { data: mutationData, loading: mutationLoading }] =
    useMutation(ConfirmJobLiveDocument, { context: { emailToken: token } });

  const [updateJobApplication, { loading: submitting }] = useMutation(
    UpdateJobApplicationDocument,
    { context: { emailToken: token } }
  );

  useEffect(() => {
    mutation({
      variables: {
        id: job.externalId,
      },
    });
  }, [mutation, job.externalId, token]);

  const isLive = mutationData?.confirmJobLive?.live;
  const peopleBreakdown = usePeopleBreakdownTracking(job.company.ebStatistics);

  const handleApplyClick = useCallback(
    async (internal: boolean) => {
      const teamManager = job.teamMembers?.find(
        teamMember => teamMember.role === TeamMemberRole.Manager
      );

      const teamReports = job.teamMembers?.some(
        teamMember => teamMember.role === TeamMemberRole.Report
      );

      const videos = job.team?.brandAssetLinks
        .flatMap(link => link.companyBrandAsset)
        .filter(asset => asset.type === BrandAssetType.Vimeo)
        .map(asset => asset.category);

      const images = job.team?.brandAssetLinks
        .flatMap(link => link.companyBrandAsset)
        .filter(asset => asset.type === BrandAssetType.Image);

      const employerBrandingAnalyticsData = {
        companyId: job.company.id,
        jobFunction: job.function?.value,
        jobSubFunction: job.subFunction?.value,
        minYearsExperienceRequired: job.minYearsExperienceRequired,
        maxYearsExperience: job.maxYearsExperienceRequired,
        videos: videos,
        teamMission: !!job.team?.mission,
        teamSize: !!job.team?.size,
        yourTeamManager: !!teamManager,
        yourTeamReports: teamReports,
        managerPrompts: !!teamManager?.jobCompanyTeamMemberPrompts?.length,
        peopleBreakdown,
        galleryPhotosCount: images?.length ?? 0,
      };

      const analyticsEventData = {
        jobId: job.id,
        jobTitle: job.title,
        jobValueClassification: jobValueClassification(job.function?.id),
        companyName: job.company.name,
        applyViaOtta: job.acceptsInternalApplications,
        technologiesUsed: (job.technologiesUsed ?? [])
          .map(({ value }) => value)
          .join(", "),
        ottaCertified: job.company.ottaCertified,
        onlyOnOtta: job.company.onlyOnOtta,
        ...employerBrandingAnalyticsData,
      };

      await updateJobApplication({
        variables: {
          jobId: job.id,
          input: {
            clicked: true,
            internal,
          },
        },
        refetchQueries: [
          { query: LastUnconfirmedJobApplicationDocument },
          { query: UserTotalJobApplicationClicksDocument },
          ...(type === "DASHBOARD"
            ? [
                {
                  query: UserJobStatusDocument,
                  variables: { externalId: job.externalId },
                },
              ]
            : [
                {
                  query: JobDeckStatusDocument,
                  variables: { externalId: job.externalId },
                },
              ]),
        ],
      });

      const loginStatus = token
        ? "email-token"
        : data?.currentUser
        ? "logged-in"
        : "public";

      pushAnalyticsEvent({
        eventName: "candidate-applied-job",
        name: "modal-apply-button",
        totalApplicationClicks: totalApplyClicks + 1,
        pathname: location.pathname,
        themeId: themeId ? toSlug(themeId) : undefined,
        internal,
        loginStatus,
        jobCardTab: location.pathname.includes("/company") ? "company" : "job",
        ...analyticsEventData,
      });

      if (totalApplyClicks === 0) {
        pushAnalyticsEvent({ eventName: "Candidate First Time Applied" });
      }

      if (internal) {
        navigate(`/jobs/${job.externalId}/apply`, { state: location.state });
      } else {
        pushAnalyticsEvent({
          eventName: `Candidate Clicked Apply On Company Website`,
          ...analyticsEventData,
        });

        onExternalApply?.();

        if (type === "JOB_DECK") {
          onClose();
        }
      }
    },
    [
      job,
      updateJobApplication,
      type,
      token,
      data?.currentUser,
      totalApplyClicks,
      location.pathname,
      themeId,
      navigate,
      onExternalApply,
      onClose,
    ]
  );

  const userWorkExperiences = data?.currentUser?.workExperiences ?? [];
  const hasWorkExperienceDescriptions =
    userWorkExperiences.filter(
      workExperience => workExperience?.descriptions?.length > 0
    ).length > 0;

  const hasCompletedProfile = !!(
    userWorkExperiences.length > 0 && hasWorkExperienceDescriptions
  );

  const receivedMessageRequest =
    data?.candidateReceivedMessageRequest ?? undefined;

  const applyableViaOtta =
    job.acceptsInternalApplications &&
    !(
      data?.latestJobApplication?.internal === false &&
      data?.latestJobApplication?.applied
    );

  return (
    <Container>
      {queryLoading || mutationLoading ? (
        <Loading />
      ) : isLive ? (
        <Content>
          {applyableViaOtta ? (
            <InternalApply
              job={job}
              onApply={handleApplyClick}
              receivedMessageRequest={receivedMessageRequest}
              submitting={submitting}
            />
          ) : job.originalUrl ? (
            <ExternalApply
              originalUrl={job.originalUrl}
              companyName={job.company.name}
              onApply={() => handleApplyClick(false)}
              submitting={submitting}
              hasCompletedProfile={hasCompletedProfile}
            />
          ) : (
            <ApplyError job={job} />
          )}
        </Content>
      ) : (
        <Content>
          <Spacing>
            <Text align="center" as="h3" bold size={2}>
              {job.company.name} is no longer accepting applications for this
              job
            </Text>
            <Text align="center">
              We check jobs are live every few hours. {job.company.name}{" "}
              recently removed this job.
            </Text>
          </Spacing>
        </Content>
      )}
    </Container>
  );
}

function ApplyError({
  job,
}: {
  job: ApplyModalJob.PublicJob;
}): React.ReactElement {
  useEffect(() => {
    Sentry.captureException(
      `Error: job ${job.id} originalUrl is empty and can't apply via site`
    );
  }, [job.id]);

  return (
    <FooterContent>
      <Spacing>
        <Text bold size={1}>
          Oops, something went wrong!
        </Text>
        <Button as={Link} to="/" level="secondary">
          Go to your dashboard
        </Button>
      </Spacing>
    </FooterContent>
  );
}
