import styled from "@xstyled/styled-components";

import { pxToRem, modularScale } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";

export const PageContainer = styled.div`
  padding: 3xl lg;
`;

export const LogoWrapper = styled.div`
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  margin: 0 auto;
`;

export const UnderlinedLink = styled(Link)`
  text-decoration: underline;
  font-size: ${modularScale(-1)};
  font-weight: bold;
  display: block;
  margin-top: lg;
`;

export * from "./SlideUpContainer";
export * from "./Tick";
