import styled from "@xstyled/styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { Press } from "./components/Press";

import {
  Spacing,
  Logo as OttaLogo,
  Text,
  responsive,
  Button,
  Middle,
  Heading as DefaultHeading,
  Card as DefaultCard,
} from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { pushAnalyticsEvent } from "@otta/analytics";
import { NAV_HEIGHT } from "@otta/search/globalConstants";
import { useUserLocation } from "@otta/search/hooks/useUserLocation";
import { Clipboard } from "@otta/search/components/Icons/Clipboard";
import { Link } from "@otta/search/components/Link";
import { useWelcomeToTheJungle } from "@otta/search/hooks/useWelcomeToTheJungle";

const Background = styled.div`
  background-color: ${palette.brand.yellow};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MiddleSection = styled.div`
  flex-grow: 1;
  padding: 19 lg;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomSection = styled.div`
  padding-bottom: 28;
`;

const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${pxToRem(NAV_HEIGHT)};
`;

const Logo = styled(OttaLogo)`
  height: ${modularScale(2)};
`;

const Card = styled(DefaultCard)`
  position: relative;
  padding-top: 35;
`;

const UnderlinedLink = styled(Link)`
  text-decoration: underline;
`;

const Spacer = styled.div`
  flex: 0 1 ${modularScale(15)};
`;

const MainText = styled(Text)`
  ${responsive`
    font-size: ${responsive.modularScale({ mobile: 0, desktop: 1 })};
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -60px);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: ${palette.brand.white};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    position: relative;
    top: -2px;
  }
`;

const Heading = styled(DefaultHeading)`
  ${responsive`
    font-size: ${responsive.modularScale({ mobile: 3, desktop: 5 })};
  `}
`;

export function StartOnboarding(): React.ReactElement {
  const { country: userLocation } = useUserLocation();
  const location = useLocation();

  const companyName = useWelcomeToTheJungle();

  const [searchParams] = useSearchParams();

  const utmSource =
    searchParams.get("utm_source") || searchParams.get("homepage_utm_source");

  const fromReferralLink = searchParams.has("referrer");

  useEffect(() => {
    pushAnalyticsEvent({
      eventName: "Candidate Signup Page Viewed",
      utmSource,
    });
  }, [utmSource]);

  return (
    <Background>
      <Nav>
        <Logo />
      </Nav>
      <MiddleSection>
        <Middle maxWidth={490}>
          <Spacing size={7}>
            <Heading size={2}>
              {fromReferralLink ? (
                <>Your friend invited you to join {companyName}</>
              ) : (
                <>Job recommendations that actually work</>
              )}
            </Heading>
            <Spacing size={2}>
              <Card>
                <IconWrapper>
                  <Clipboard />
                </IconWrapper>
                <Spacing size={2}>
                  <MainText>
                    {fromReferralLink ? (
                      <>
                        If you sign up to {companyName}, complete your job
                        preferences, and connect your LinkedIn profile during
                        sign up, we&apos;ll give your friend a reward.
                      </>
                    ) : (
                      <>
                        Tell us what you like in our quiz and discover your best
                        matches. With {companyName}, find only relevant roles at
                        the most exciting startups.
                      </>
                    )}
                  </MainText>
                  <Link
                    to={{
                      pathname: "/initial-preferences",
                      search: location.search,
                    }}
                    underline={false}
                  >
                    <Button level="primary">Let&apos;s get started</Button>
                  </Link>
                </Spacing>
              </Card>
              <UnderlinedLink
                to={{ pathname: "/login", search: location.search }}
              >
                I already have an account
              </UnderlinedLink>
            </Spacing>
          </Spacing>
        </Middle>
      </MiddleSection>
      <Spacer />
      <BottomSection>
        <Press userLocation={userLocation} />
      </BottomSection>
    </Background>
  );
}
