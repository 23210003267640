import styled from "@xstyled/styled-components";

import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { Link } from "@otta/search/components/Link";
import { Delete } from "@otta/search/components/Icons/Delete";

const Container = styled.div`
  top: 0;
  left: 0;
  height: 36px;
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: none;
  z-index: 5;
`;

const Icon = styled(Delete)`
  cursor: pointer;
  width: ${modularScale(4)};
  height: ${modularScale(4)};
  padding: sm;
  border-radius: 50%;
  fill: ${palette.brand.black};
  &:hover {
    background-color: ${palette.extended.yellow.shade600};
  }
`;

const RightCornerButton = styled(Link)`
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  top: ${modularScale(-6)};
  right: ${modularScale(-6)};
  background-color: ${palette.brand.yellow};
  border-radius: ${pxToRem(100)};
`;

interface ICloseButtonComponent {
  to: string;
}

export function CloseButton({ to }: ICloseButtonComponent): React.ReactElement {
  return (
    <Container>
      <RightCornerButton to={to} underline={false} data-testid="button">
        <Icon />
      </RightCornerButton>
    </Container>
  );
}
