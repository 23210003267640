import { Spacing, Text } from "@otta/design";
import { Article } from "@otta/search/components/Article";
import { Company } from "@otta/search/schema";

export const shouldDisplayCompanyArticles = (
  company: CompanyArticles["company"]
) => {
  return company.articles.length > 0;
};

interface CompanyArticles {
  company: Pick<Company, "articles">;
}

export function CompanyArticles({
  company,
}: CompanyArticles): React.ReactElement | null {
  if (!shouldDisplayCompanyArticles(company)) {
    return null;
  }

  return (
    <Spacing>
      <Text bold as="h3" size={1}>
        Articles
      </Text>
      <Spacing>
        {company.articles.map(article => (
          <Article key={article.id} {...article} />
        ))}
      </Spacing>
    </Spacing>
  );
}
