import { ReactElement } from "react";
import styled from "@xstyled/styled-components";
import purify from "dompurify";

import { Text } from "@otta/design";
import { pxToRem, palette } from "@otta/design-tokens";
import { CardTitle } from "@otta/search/pages/Profile/components/FormHeader";
import { HeaderWrapper } from "@otta/search/pages/Profile/PublicProfile/components/Section";

type LabelProps = {
  htmlFor?: string;
  id?: string;
  value: string;
  required: boolean;
};

type LabelledProps = React.PropsWithChildren<LabelProps>;

const BoldLabel = styled(CardTitle)`
  margin-top: md;
  font-weight: bold;
`;

/**
 * Wraps an entire field within a wrapper containing a label
 * We leave the actual rendering of the field to someone else
 * but sadly need the section ID etc for the htmlFor attribute
 */
export function Labelled({ children, ...props }: LabelledProps): ReactElement {
  return (
    <>
      <HeaderWrapper style={{ marginBottom: pxToRem(8) }}>
        <Label {...props} />
      </HeaderWrapper>
      {children}
    </>
  );
}

function Label({ htmlFor, id, value, required }: LabelProps): ReactElement {
  return (
    <BoldLabel as={"label"} htmlFor={htmlFor} id={id}>
      <span dangerouslySetInnerHTML={{ __html: purify.sanitize(value) }} />
      {!required && (
        <Text style={{ color: palette.grayscale.shade600 }}> (optional) </Text>
      )}
    </BoldLabel>
  );
}
